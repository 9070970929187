<script setup lang="ts" generic="T = unknown">
import { computed, reactive, onBeforeUnmount } from 'vue'
import { type Field } from 'vue-observables'
import { type IsUnknown } from 'type-fest'
import InputText from 'primevue/inputtext'
import tippy, { type Instance } from 'tippy.js'

const props = defineProps<{
  label?: string
  for?: string
  mark?: 'required' | 'optional'
  useTooltip?: boolean
  field?: Field<T>
}>()

interface FieldBindings<T = unknown> {
  modelValue: T
  'onUpdate:modelValue': (val: T) => void
  invalid: boolean
  onChange: (e: Event) => void
  onInput: (e: Event) => void
}

const slots = defineSlots<
  (IsUnknown<T> extends true
    ? {
        default(): any
      } : {
        default(props: { field: FieldBindings<T> }): any
      }
  ) & {
    details(): any
  }
>()

const fieldBindings = reactive({
  get modelValue() {
    return props.field!.value as T
  },
  set modelValue(val: T) {
    props.field!.value = val
  },
  get 'onUpdate:modelValue'() {
    return (val: T) => {
      props.field!.value = val
    }
  },
  get invalid() {
    return Boolean(props.field?.error)
  },
  get onChange() {
    return (e: Event) => {
      props.field!.value = (e.target as HTMLInputElement).value as T
      props.field!.onChange.call(props.field)
    }
  },
  get onInput() {
    return () => {
      props.field!.onInput.call(props.field)
    }
  }
})

const hasDefault = computed(() => Boolean(slots.default))
const container = ref<HTMLLabelElement | null>(null)
const tippyInstance = ref<Instance | null>(null)

watch(() => props.field?.error, err => {
  if (tippyInstance.value) {
    if (err) {
      tippyInstance.value.setContent(err)
      tippyInstance.value.setProps({ trigger: 'focus' })
      if (document.activeElement === container.value?.querySelector('input')) {
        tippyInstance.value.show()
      }
    } else {
      tippyInstance.value.setProps({ trigger: 'manual' })
      tippyInstance.value.hide()
    }
  } else if (err) {
    const input = container.value?.querySelector('input')
    if (input) {
      tippyInstance.value = tippy(input, {
        trigger: 'focus',
        hideOnClick: false,
        content: err,
        appendTo: document.body,
        onCreate(instance) {
          if (document.activeElement === input) {
            instance.show()
          }
        },
        onMount(instance) {
          if (document.activeElement === input) {
            instance.show()
          }
        }
      })
    }
  }
}, { immediate: true })

onBeforeUnmount(() => {
  if (tippyInstance.value) {
    tippyInstance.value.destroy()
  }
})
</script>

<template>
  <label class="label-wrapper" :for="props.for" ref="container">
    <div v-if="label" class="text-wrapper">
      <span :class="{ required: mark === 'required' }">
        {{ label }}
      </span>
      <slot name="details"></slot>
      <span v-if="mark === 'optional'" class="optional">
        Optional
      </span>
    </div>
    <div class="contents" v-if="hasDefault">
      <slot :field="(fieldBindings as FieldBindings<T>)"></slot>
      <div v-if="!useTooltip && field?.error" class="error">
        {{ field?.error }}
      </div>
    </div>
    <div class="contents" v-else-if="field">
      <InputText v-bind="(fieldBindings as unknown as FieldBindings<string>)" />
      <div v-if="!useTooltip && field?.error" class="error">
        {{ field?.error }}
      </div>
    </div>
  </label>
</template>

<style scoped>
.label-wrapper {
  font-size: 0.95rem;
  display: block;

  > div + * {
    margin-top: 0.35rem;
  }
}
.text-wrapper {
  font-weight: 600;
  display: grid;
  gap: 0.5rem;
  grid-auto-flow: column;
  align-items: first baseline;
}

.optional {
  justify-self: flex-end;
  font-weight: normal;
  text-transform: uppercase;
  font-size: 0.75rem;
  color: var(--surface-400);
  opacity: 1;
  transition: opacity 0.2s;
}

@supports selector(:has(*)) {
  .optional {
    opacity: 0;
  }
  .label-wrapper:has(:focus) .optional {
    opacity: 1;
  }
}
.required::after {
  color: var(--error-color);
  content: '*';
  display: inline-block;
  margin-left: 0.25rem;
}
.error {
  color: var(--error-color);
  font-weight: normal;
}
.contents {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
</style>
