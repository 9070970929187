<script setup lang="ts">
import { toRef, reactive } from 'vue'
import { getDefaults } from 'valibot'

import ChoicePageEditor from './ChoicePageEditor.vue'
import SplitLinkEditor from './SplitLinkEditor.vue'
import ProductDetails from './ProductDetails.vue'
import ProductTextBox from './ProductTextBox.vue'
import Field from '~/components/ui/Field.vue'

import Flex from '~/components/ui/layout/Flex.vue'
import Stack from '~/components/ui/layout/Stack.vue'

import SelectButton from 'primevue/selectbutton'
import Card from '~/components/ui/Card.vue'

import {
  type DestinationOptions,
  SimpleOptionsSchema
} from '~/models/links-v4'

import {
  type Link
} from '~/models/links-v1'

import {
  linkTypes,
  type DestinationContext,
  useDestinationContext
} from './helpers'

const destinationOptions = defineModel<DestinationOptions>({
  default: reactive(getDefaults(SimpleOptionsSchema))
})

const props = withDefaults(defineProps<{
  destinationContext?: DestinationContext
  hideTypeTabs?: boolean
  hideDetails?: boolean
  isBulkMode?: boolean
}>(), {
  isBulkMode: false
})

const link = defineModel<Link>('link')
const monetizationEnabled = defineModel<boolean>('monetizationEnabled', { default: true })

const destination = props.destinationContext ?? useDestinationContext(destinationOptions)
const linkType = toRef(destination, 'linkType')
</script>

<template>
  <Stack>
    <Stack class="destinations" space="gap24">
      <Card padding="gap14">
        <template #header>
          <Flex class="destinations-menu" alignItems="center" v-if="!isBulkMode && !hideTypeTabs">
            <SelectButton
              class="menu"
              v-model="linkType"
              :options="Object.entries(linkTypes)"
              optionValue="0"
              optionLabel="1"
              :allowEmpty="false"
              data-testid="Destinations__destinationsMenu"
            />
          </Flex>
        </template>

        <!-- Default destination -->
        <!-- Simple link -->
        <Stack v-show="linkType === 'SIMPLE'" class="simple">
          <div class="product-url">
            <Field label="Product Url" />
            <ProductTextBox v-model="destination.simple.destination" />
            <!-- <a :href="destinationOptions.default.url" target="_blank" class="overflow">
              <span>{{ destinationOptions.default.url }}</span>
            </a> -->
          </div>
          <ProductDetails
            v-if="!hideDetails"
            v-model="destination.simple.destination.url"
            v-model:link="link"
            v-model:monetizationEnabled="monetizationEnabled"
          />
        </Stack>

        <!-- Choice page -->
        <div v-show="linkType === 'CHOICE_PAGE'">
          <h2 v-if="hideTypeTabs">
            Choice Page
          </h2>
          <ChoicePageEditor v-model="destination.choicePage" />
        </div>
        <!-- END Choice page-->

        <!-- Split link -->
        <div v-show="linkType === 'AB_SPLIT'">
          <h2 v-if="hideTypeTabs">
            A/B Split
          </h2>
          <SplitLinkEditor v-model="destination.abSplit" />
        </div>
        <!-- END Split link -->
      </Card>

      <div>
        <!-- <AdvancedRules v-model="formContext" /> -->
      </div>
    </Stack>

    <!-- END default destination -->
    <!-- Advanced Link Content-->

  </Stack>
</template>

<style scoped>
.destinations-menu {
  justify-content: space-between;
}
.menu :deep(> div) {
  background: var(--surface-200);
}
.menu :deep(> div::before) {
  border-color: transparent;
}
.menu :deep(> div.p-highlight) {
  background: var(--surface-100);
}
/* .product-url {
  display: grid;
  grid-auto-rows: 1fr;
} */
</style>
