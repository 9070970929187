<script setup lang="ts">
import { ALL_RETAILERS } from '~/components/SelectSearchRetailer/SelectRetailerDropdownVM'
import { useClient, dayjs } from '~/utils'
import { computed } from 'vue'
import { calculateReturnByResolutionString } from '~/scripts/basic-reporting-input'
import ChartTemplate from './ChartTemplate.vue'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import { abbrNum } from '~js/pretty-printing.js'
import IconWorld from '@material-symbols/svg-600/outlined/public.svg'
import IconToc from '@material-symbols/svg-600/outlined/toc.svg'
import SelectButton from 'primevue/selectbutton'
import worldMap from '@highcharts/map-collection/custom/world-lowres.topo.json'
import round from 'lodash/round'

export interface WorldReportProps {
  /** Dates in local time */
  startDate: Date
  endDate: Date
  hideBots: boolean
  retailerId: string
  shortCode?: string
  groupId?: string
}

const props = defineProps<WorldReportProps>()

const view = defineModel<'table' | 'chart'>('view', { default: 'chart' })

const startUtcDate = computed(() => dayjs(props.startDate).utc())
const endUtcDate = computed(() => dayjs(props.endDate).utc())
const startUtcDateString = computed(() => startUtcDate.value.format('YYYY-MM-DD'))
const endUtcDateString = computed(() => endUtcDate.value.format('YYYY-MM-DD'))

const retailerId = computed(() => props.retailerId === ALL_RETAILERS ? undefined : props.retailerId)
const dataResolution = computed(() => calculateReturnByResolutionString(props.startDate, props.endDate))

let apiUrl:
  '/v1/reports/clicks-by-country'
  | '/v1/reports/link-clicks-by-country'
  | '/api/v1/reports/group-clicks-by-country' = '/v1/reports/clicks-by-country'

const query: Record<string, any> = {
  start: startUtcDateString,
  end: endUtcDateString
}

if (props.shortCode) {
  apiUrl = '/v1/reports/link-clicks-by-country'
  query.shortcode = props.shortCode
} else if (props.groupId) {
  apiUrl = '/api/v1/reports/group-clicks-by-country'
  query.doPreviousPeriod = 0
  query.retailerId = retailerId
  query.dataresolution = dataResolution
  /** Once again, this field flips from being groupid in other APIs to tsid in this one */
  query.tsid = props.groupId
} else {
  query.dopreviousperiod = 0
  query.retailerid = retailerId
  query.dataResolution = dataResolution
}

const {
  data,
  loading,
  validating
} = useClient(apiUrl, { query })

const regionNames = new Intl.DisplayNames(['en'], { type: 'region' })

const getRegionName = (code: string) => {
  try {
    const region = regionNames.of(code)
    if (region) {
      return region
    }
    return code
  } catch (e) {
    return code
  }
}

const empty = computed(() => !data.value?.length)

const chartOptions = computed<Options>(() => {
  const clicksProp = props.hideBots ? 'clicksMinusBot' : 'clicks'

  if (data.value) {
    const total = data.value.reduce((acc, item) => acc + item.value[clicksProp], 0)
    const options = merge({}, defaultOptions, {
      series: [{
        type: 'map',
        nullInteraction: true,
        nullColor: '#F7F8FB',
        borderColor: '#CBD5E1',
        data: data.value.map(item => ({
          name: item.key,
          value: item.value[clicksProp],
          custom: {
            percent: `${total === 0 ? 0 : round((item.value[clicksProp] / total) * 100, 1)}%`
          }
        })).filter(item => item.value > 0),
        name: ''
      }] satisfies SeriesMapOptions[]
    })
    return options
  }
  return defaultOptions
})
defineEmits<{
  createLink: []
}>()
</script>

<script lang="ts">
import {
  type Options,
  type SeriesMapOptions
} from 'highcharts'
import merge from 'lodash/merge'
import { tooltip } from './common'

// (Highcharts as any).Templating.helpers.log = function() {
//   console.log(arguments[0].ctx)
// }
const colors = ['#0064ac', '#00b9ee']

const defaultOptions: Options = {
  chart: {
    height: 411,
    spacing: [16, 16, 16, 16],
    backgroundColor: 'transparent',
    plotShadow: false,
    map: worldMap
  },
  plotOptions: {
    map: {
      colors
    },
    series: {
      joinBy: ['iso-a2', 'name']
    }
  },
  title: {
    text: undefined
  },
  tooltip: {
    ...tooltip,
    headerFormat: '',
    pointFormat: '{point.properties.name}: <b>{point.value}</b> ({point.options.custom.percent})',
    nullFormat: '{point.properties.name}: <b>0</b>'
  },

  mapView: {
    projection: {
      name: 'LambertConformalConic'
    }
  },

  mapNavigation: {
    enabled: true,
    buttonOptions: {
      verticalAlign: 'top'
    }
  },

  colorAxis: {
    min: 1,
    max: 1000,
    type: 'logarithmic',
    minColor: '#0064ac',
    maxColor: '#00b9ee'
  },

  exporting: { enabled: false },
  credits: {
    enabled: false
  }
}
</script>

<template>
  <section>
    <ChartTemplate
      :validating
      :loading
      :empty
      v-model="chartOptions"
      constructorType="mapChart"
      :data-testid="`WorldReport__chartTemplate`"
      @createLink="$emit('createLink')"
    >
      <template #header>
        <div class="mini-header">
          <SelectButton :options="['chart', 'table']" v-model="view">
            <template #option="{ option }">
              <IconWorld v-if="option === 'chart'" style="position: relative" />
              <IconToc v-else style="position: relative" />
            </template>
          </SelectButton>
        </div>
      </template>
      <template #chart v-if="view === 'table'">
        <div class="table-container">
          <DataTable
            :value="data"
            scrollable
            scrollHeight="100%"
            style="height: 100%"
            sortField="value.clicks"
            :sortOrder="-1"
            :pt:table:data-testid="`WorldReport__table`"
          >
            <Column field="key" header="Attribute" sortable>
              <template #body="{ data: item }">
                {{ getRegionName(item.key) }}
              </template>
            </Column>
            <Column
              field="value.clicks"
              header="Clicks"
              sortable
              style="width: 4rem"
            >
              <template #body="{ data: item }">
                {{ abbrNum(item.value.clicks, 1) }}
              </template>
            </Column>
          </DataTable>
        </div>
      </template>
    </ChartTemplate>
  </section>
</template>

<style scoped>
:deep(svg tspan) {
  stroke-width: 0;
}
.mini-header {
  font-size: 1.25rem;
  padding: 0.5rem;
  color: var(--text-color);
  font-weight: 500;
  text-align: center;
  display: flex;
  gap: 0.5rem;
  justify-content: end;
}
.table-container {
  height: 268px;
  margin: 16px;
}
</style>
