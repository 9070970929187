import { useHead } from '#imports'
import { onScopeDispose, getCurrentScope } from 'vue'

interface ScriptOptions {
  src: string
  key?: string
  async?: boolean
  defer?: boolean
  onload?: (e?: Event) => void
}

let keyedScriptMap: Map<string, {
  loadEvent?: Event | undefined
  listeners: Array<(e?: Event) => void>
}> | undefined
/**
 * Both Nuxt's useHead and useScript failed at this task.
 * useScript failed because it attempted to retrieve scripts
 * via fetch requests, which encounters CORS issues.
 *
 * useHead failed because it could not de-duplicate script
 * requests AND fire the onload handler.
 *
 * So, this allows us to "use" the `<head>` script multiple times, which
 * will only mount _once_, but will always fire the onload handler.
 *
 * @todo Add unit tests.
 */
export const useScript = ({
  src,
  key,
  async,
  defer,
  onload
}: ScriptOptions) => {
  const scope = getCurrentScope()
  if (!scope) {
    return
  }
  if (key) {
    if (!keyedScriptMap) {
      keyedScriptMap = new Map()
    }
    const existing = keyedScriptMap.get(key)
    if (existing) {
      if (existing.loadEvent) {
        /** It already loaded, so fire immediately */
        onload?.(existing.loadEvent)
      } else {
        if (onload) {
          /** Add a listener to the onload event */
          existing.listeners.push(onload)
          onScopeDispose(() => {
            const idx = existing.listeners.indexOf(onload)
            if (idx > -1) {
              existing.listeners.splice(idx, 1)
            }
          })
        }
      }
    } else {
      keyedScriptMap.set(key, {
        listeners: onload ? [onload] : []
      })
    }
  }

  useHead({
    script: [
      {
        src,
        key,
        async,
        defer,
        onload(e: Event) {
          if (key) {
            const existing = keyedScriptMap!.get(key)
            if (existing) {
              existing.loadEvent = e
              existing.listeners.forEach((listener) => listener(e))
              existing.listeners = []
            }
          }
        }
      }
    ]
  })
}
