import {
  type MappedObjectType
} from '~/components/utils'
// import { DestinationOverrideSchema, UrlSchema } from './overrides'
// import { TagSchema } from './tags'
import { vanityRegex } from './links-v1'

import {
  number,
  string,
  object,
  boolean,
  union,
  literal,
  regex,
  optional,
  nullable,
  array,
  variant,
  intersect,
  objectWithRest,
  pipe,
  record,
  omit,
  pick,
  partial,
  toUpperCase,
  type InferOutput,
  isoDateTime,
  url,
  nonEmpty,
  required,
  tupleWithRest
} from 'valibot'

export interface CustomDomainData {
  name: string
  allowedDestinationDomains: string[]
  hasValidSslCert?: boolean
}

export const domainMapping = {
  observe: ['name', 'allowedDestinationDomains'],
  ignore: ['owner', 'usersAllowedAccess']
} as const

export type CustomDomain = MappedObjectType<CustomDomainData, typeof domainMapping>

export interface GroupOptionData {
  id: number
  name: string
  shortName: string
  enabled: 0 | 1
}

export interface GroupOption {
  id: number
  name: string
  /** Not really sure how this differs from "name" */
  shortName: string
  /** Assigned by the FE */
  value: string
  disabled?: boolean
  selected?: boolean
}

/** v4 */
export const UrlSchema = pipe(optional(string(), ''), nonEmpty(), url())

const BaseImageSchema = object({
  url: UrlSchema
})

const ExtendedImageSchema = object({
  ...BaseImageSchema.entries,
  provider: literal('CLOUDINARY'),
  /** publicId */
  providerId: string(),
  /**
   * Cloudinary returns as a number, but other providers might not?
   * In any case, we don't ever use it numerically AFAIK.
   */
  version: optional(string())
})

export const ImageSchema = union([BaseImageSchema, ExtendedImageSchema])

export type Image = InferOutput<typeof ImageSchema>

const ImageCollectionSchema = objectWithRest({
  default: optional(ImageSchema)
}, ImageSchema)

const PrimitiveSchema = optional(union([string(), number(), boolean()]))

const RetailerSchema = object({
  /** @param advertiser "advertiser" seems like an incorrect name */
  name: string(),
  images: optional(ImageCollectionSchema)
})

export const DestinationDetailsSchema = object({
  url: UrlSchema,

  /**
   * @note In most cases, we won't return these extra details, but
   *       we might sometimes for API convenience?
   */
  /** @param productDisplayName1 */
  title: optional(string()),
  /** @param productDisplayName2 Manufacturer or author */
  subtitle: optional(string()),
  images: optional(ImageCollectionSchema),

  /** IMO we should separate these out in the future to do a batch call for retailers */
  retailer: optional(RetailerSchema),

  /** Used in storing / communicating details about this specific url in the front end */
  meta: optional(objectWithRest({
    isSuggestion: optional(boolean()),
    isAutoSuggestion: optional(boolean())
  }, PrimitiveSchema))
})

export type DestinationDetails = InferOutput<typeof DestinationDetailsSchema>

const percentageValue = pipe(string(), regex(/^(100(\.0{0,2})?|[0-9]{1,2}(\.[0-9]{1,2})?)$/))

export const SimpleOptionsSchema = object({
  /* @note - marking these types as optional just allows us to set defaults */
  type: pipe(optional(string(), 'SIMPLE'), literal('SIMPLE')),
  /** Simplify 'titleText' and 'titleTextEncoded' */
  title: optional(nullable(string())),
  destination: DestinationDetailsSchema
})

export type SimpleOptions = InferOutput<typeof SimpleOptionsSchema>

const ABSplitDestinationSchema = object({
  isDefault: optional(boolean()),
  destination: DestinationDetailsSchema,
  percentage: percentageValue
})

export const ABSplitOptionsSchema = object({
  type: pipe(optional(string(), 'AB_SPLIT'), literal('AB_SPLIT')),
  title: optional(nullable(string())),
  /** Must have at least 1 destination to be valid */
  destinations: tupleWithRest(
    [ABSplitDestinationSchema],
    ABSplitDestinationSchema
  )
})

export type ABSplitOptions = InferOutput<typeof ABSplitOptionsSchema>

const ChoicePageDestinationSchema = object({
  isDefault: optional(boolean()),
  destination: DestinationDetailsSchema
})

export const ChoicePageOptionsSchema = object({
  type: pipe(optional(string(), 'CHOICE_PAGE'), literal('CHOICE_PAGE')),
  /** Must have at least 1 destination to be valid */
  destinations: tupleWithRest(
    [ChoicePageDestinationSchema],
    ChoicePageDestinationSchema
  ),

  title: optional(nullable(string()), ''),
  /** Simplify 'flavorText' and 'flavorTextEncoded' */
  callToAction: optional(string(), ''),

  theme: pipe(
    optional(string(), 'LIGHT'),
    toUpperCase(),
    union([literal('LIGHT'), literal('DARK')])
  ),

  hideAffiliateDisclosure: optional(boolean(), false),

  images: optional(
    object({
      hero: ImageSchema,
      product: ImageSchema
    }),
    {
      hero: { url: '' },
      product: { url: '' }
    }
  )
})

export type ChoicePageOptions = InferOutput<typeof ChoicePageOptionsSchema>

export const DestinationOptionsSchema = variant('type', [
  required(SimpleOptionsSchema, ['type']),
  required(ChoicePageOptionsSchema, ['type']),
  required(ABSplitOptionsSchema, ['type'])
])

export type DestinationOptions = InferOutput<typeof DestinationOptionsSchema>

const BaseCondition = object({
  /**
   * `true` if condition if the matching condition will affect routing.
   * `false` if a matching condition means the route does NOT match.
   */
  if: boolean()
})

const DateConditionSchema = object({
  ...BaseCondition.entries,
  type: literal('DATE'),
  value: string(),
  operator: union([literal('<'), literal('>=')])
})

export type DateCondition = InferOutput<typeof DateConditionSchema>

const BrowserConditionSchema = object({
  ...BaseCondition.entries,
  /** @example Chrome */
  type: literal('BROWSER'),
  value: string()
  /** For future? */
// options?: {
//   operator: '=' | '<' | '>' | '<=' | '>='
//   major: number
//   minor: number
// }
})

export type BrowserCondition = InferOutput<typeof BrowserConditionSchema>

const OsConditionSchema = object({
  ...BaseCondition.entries,
  /** @example iOS */
  type: literal('OS'),
  value: string(),
  options: optional(object({
    operator: union([literal('='), literal('<'), literal('>'), literal('<='), literal('>=')]),
    major: number(),
    minor: number()
  }))
})

export type OsCondition = InferOutput<typeof OsConditionSchema>

const DeviceConditionSchema = object({
  ...BaseCondition.entries,
  type: literal('DEVICE'),
  /** @example iPad */
  value: string()
})

export type DeviceCondition = InferOutput<typeof DeviceConditionSchema>

const CountryConditionSchema = object({
  ...BaseCondition.entries,
  type: literal('COUNTRY'),
  value: string(),
  meta: object({
    isoCode: string()
  })
})

export type CountryCondition = InferOutput<typeof CountryConditionSchema>

const RegionConditionSchema = object({
  ...BaseCondition.entries,
  type: literal('REGION'),
  /** Some type of region identifier? */
  value: string()
})

export type RegionCondition = InferOutput<typeof RegionConditionSchema>

const LanguageConditionSchema = object({
  ...BaseCondition.entries,
  type: literal('LANGUAGE'),
  value: string(),
  meta: object({
    isoCode: string()
  })
})

export type LanguageCondition = InferOutput<typeof LanguageConditionSchema>

const ConditionSchema = variant('type', [
  DateConditionSchema,
  BrowserConditionSchema,
  OsConditionSchema,
  DeviceConditionSchema,
  CountryConditionSchema,
  RegionConditionSchema,
  LanguageConditionSchema
])

export type Condition = InferOutput<typeof ConditionSchema>

export const ConditionsSchema = array(ConditionSchema)

export const RuleSchema = object({
  linkOptions: DestinationOptionsSchema,
  conditions: ConditionsSchema,
  meta: optional(record(string(), PrimitiveSchema))
})

export const RulesSchema = array(RuleSchema)

export type Rule = InferOutput<typeof RuleSchema>

export const TrackingMetaSchema = objectWithRest({
  /** @param trackingCode trackingTag */
  utmSource: string(),
  utmMedium: string(),
  utmCampaign: string(),
  utmTerm: string(),
  utmContent: string()
}, string())

export type TrackingMeta = InferOutput<typeof TrackingMetaSchema>

export type ShortLink = Omit<InferOutput<typeof ShortLinkSchema>, 'linkOptions'> & {
  linkOptions: DestinationOptions
}

export const ShortLinkSchema = object({
  /**
   * Currently a number, please return as string in the JSON to make transitioning to GUID seamless
   * i.e. the frontend doesn't care about the type of the ID, just the primitive type, so returning
   * as string supports future migration.
   */
  id: string(),

  /**
   * Selected domain / vanity code are stored here
   */
  alias: optional(object({
    code: pipe(
      string(),
      regex(vanityRegex, 'Enter a valid vanity code. Between 3 and 22 characters: letters and numbers are allowed. No spaces or special characters with the exception of hyphens and underscores.')
    ),
    domain: string()
  })),

  /**
   * The ShortLink url
   * @example "https://geni.us/code" or "https://cust.om/alias"
   */
  url: UrlSchema,

  /** @param tsid The APIs sometimes use tsid and sometimes groupid, and groupId seems most clear */
  groupId: string(),

  /**
   * @note ISO 8601
   * @example 2011-10-05T14:48:00.000Z
   */
  createdUtc: string(),
  updatedUtc: string(),

  /** Reference to Simple / Choice Page / ABSplit object */
  linkOptions: DestinationOptionsSchema,
  rules: RulesSchema,

  trackingMeta: TrackingMetaSchema,

  /** @param isArchivedInt Converted to boolean */
  isArchived: boolean(),

  notes: string(),

  /** Assuming we get this separately. */
  trackingPixelIds: array(string()),

  monetizationEnabled: optional(boolean(), true)
})

const LinksListItemDataSchema = object({
  ...omit(ShortLinkSchema, ['rules']).entries,
  totalClicks: number()
})

export type LinksListItem = InferOutput<typeof LinksListItemDataSchema>

/** @see /v3.5/links GET */
export const GetLinksListResponseSchema = object({
  data: array(LinksListItemDataSchema),
  page: object({
    offset: number(),
    limit: number(),
    total: number()
  })
})
export type GetLinksListResponse = InferOutput<typeof GetLinksListResponseSchema>

export interface PreviewFields {
  previewImage?: Image
  previewTitle: string
  previewSubtitle?: string
  previewUrl?: string
  previewIcon?: Image
}

export type WithPreview<T extends Omit<ShortLink, 'rules'> = Omit<ShortLink, 'rules'>> =
  T extends LinksListItem ? T & PreviewFields : T

export interface GetLinksListResponseWithPreview extends Omit<GetLinksListResponse, 'data'> {
  data: WithPreview[]
}

const LinkTypeUnion = union([literal('SIMPLE'), literal('AB_SPLIT'), literal('CHOICE_PAGE')])

export type LinkType = InferOutput<typeof LinkTypeUnion>

export type GetLinksListRequest = InferOutput<typeof GetLinksListRequestSchema>

/** As strings since they are part of the query string */
export const GetLinksListRequestSchema = object({
  /** e.g. ?ids=1,2,3 */
  id: optional(array(string())),
  /** e.g. ?linkTypes=SIMPLE */
  linkType: optional(array(LinkTypeUnion)),
  /** Options aren't documented currently */
  sortBy: optional(array(string())),
  /** Either neither of `limit` / `offset` are present or both are present */
  limit: optional(number()),
  offset: optional(number()),
  /** 0 for all */
  groupId: optional(array(string())),
  /** @param keyword */
  query: optional(string()),
  archived: optional(boolean()),
  startUtc: optional(pipe(string(), isoDateTime())),
  endUtc: optional(pipe(string(), isoDateTime()))
})

/**
 * This is used for the LinkEditor data model
 * before being validated and sent to the API.
 */
export const LinkSubmitRequestSchema = pick(ShortLinkSchema, [
  'id',
  'groupId',
  'alias',
  'linkOptions',
  'rules',
  'trackingMeta',
  'notes',
  'trackingPixelIds',
  'monetizationEnabled'
])
export type LinkSubmitData = InferOutput<typeof LinkSubmitRequestSchema>

/**
   * @note if the advanced checkbox gets turned off before submitting,
   * then rules should be sent as an empty array, even if some rules
   * were added.
   */
export const LinkAddRequestSchema = pick(ShortLinkSchema, [
  'groupId',
  'alias',
  'linkOptions',
  'rules',
  'trackingMeta',
  'notes',
  'trackingPixelIds',
  'monetizationEnabled'
])

export const LinkUpdateRequestSchema = intersect([
  object({
    id: string()
  }),
  partial(
    pick(ShortLinkSchema, [
      'groupId',
      'alias',
      'linkOptions',
      'rules',
      'trackingMeta',
      'notes',
      'trackingPixelIds',
      'isArchived',
      'monetizationEnabled'
    ])
  )
])

/**
 * @note
 * I've updated our API request schema guide that responses from POST / PUT can be flat.
 * (They do not require being nested under `data`.)
 */
export type LinkAddResponse = InferOutput<typeof ShortLinkSchema>

/** @see /v3.5/links POST */
export type LinkAddRequest = InferOutput<typeof LinkAddRequestSchema>

/** @see /v3.5/links/{id} PUT */
export type LinkUpdateRequest = InferOutput<typeof LinkUpdateRequestSchema>

export type LinkUpdateResponse = InferOutput<typeof ShortLinkSchema>

/** @see /v3.5/links/{id} */
export interface GetLinkResponse {
  data: InferOutput<typeof LinkAddRequestSchema>
}
