<script setup lang="ts">
import { computed } from 'vue'

const props = withDefaults(defineProps<{
  align?: 'start' | 'end'
}>(), {
  align: 'end'
})

const flexDirection = computed(() => props.align === 'start' ? 'row' : 'row-reverse')
</script>

<template>
  <div class="actions">
    <slot />
  </div>
</template>

<style scoped>
.actions {
  display: flex;
  flex-direction: v-bind(flexDirection);
  gap: 1rem;
  flex-wrap: wrap;
  align-items: flex-end;
}
</style>
