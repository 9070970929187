/* global CloudinaryUploadWidgetOptions */
import { useClientPromise } from '~/utils'

export const buttonImageAspectRatio = 2.25
export const croppingMode = 'server' // ??

export const defaultCloudinaryOptions: CloudinaryUploadWidgetOptions = {
  apiKey: '132951636445918',
  sources: ['local', 'url'],
  maxFiles: 1,
  clientAllowedFormats: ['jpg', 'jpeg', 'png', 'webp', 'avif'],
  resourceType: 'image',
  showPoweredBy: false,
  theme: 'minimal',
  cloudName: 'geniuslink',
  uploadPreset: 'aglgurhp',
  maxFileSize: 10000000,
  cropping: true,
  croppingAspectRatio: 1.0, // default
  showSkipCropButton: false,
  // @ts-expect-error The types from NPM are wrong
  async uploadSignature(
    callback,
    params: {
      timestamp: number
      folder: string

      upload_preset: string

      custom_coordinates: string
    }) {
    const [err, data] = await useClientPromise('/api/v1/cloudinary-signature', {
      query: {
        timestamp: params.timestamp,
        uploadPreset: params.upload_preset,
        folder: params.folder,
        // cloudinaryCallback: params.callback,
        customCoordinates: params.custom_coordinates
      }
    })
    if (err) {
      throw err
    }
    callback(data.signature)
  }
}
