/** @todo fix above */

import {
  computed,
  type Computed,
  type Observable,
  type ObservableArray,
  observableArray
} from '~/components/utils'
import { GeniusLinkServiceClient } from '~js/GeniusLinkServiceClient.ts'
import { RetailerProfile } from '~js/Affiliation/RetailerProfile.js'

export const ALL_RETAILERS = 'ALL'

export class SelectRetailerDropdownVM {
  public client: GeniusLinkServiceClient
  public errorTitle: Observable<string>
  public errorMessage: Observable<string>
  public allSupportedRetailers: ObservableArray<RetailerProfile>
  public selectableRetailers: Computed<RetailerProfile[]>

  constructor() {
    const self = this
    self.client = new GeniusLinkServiceClient('')
    self.allSupportedRetailers = observableArray<RetailerProfile>([])
    self.selectableRetailers = computed(() => {
      const retailers = [new RetailerProfile(ALL_RETAILERS, 'All retailers', '', false)]
      return retailers.concat(self.allSupportedRetailers())
    })
    self.getSupportedRetailers = self.getSupportedRetailers.bind(this)

    // Call the function upon creation
    self.getSupportedRetailers()
  }

  public getSupportedRetailers(): void {
    const self = this

    // Clear array just in case we execute this function more than once
    self.allSupportedRetailers([])

    /**
     * @note For the old JSV client, when format is set to JSV, the response needs to be parsed as JSV or this will fail.
     */
    self.client.getFromService('/v3/retailers?enabled=true&supportsAffiliation=true&take=100000', {},
      (data: any) => { // On success
        data.retailers.forEach((r: RetailerProfile) => {
          self.allSupportedRetailers.push(
            new RetailerProfile(r.id, r.displayName, r.logoUrl, r.isSponsoredClick))
        })

        // Insert legacy retailers that we still want to show
        self.allSupportedRetailers.push(new RetailerProfile('GOOGLE_PLAY', 'Google Play', '', false))
        self.allSupportedRetailers.push(new RetailerProfile('MICROSOFT_STORE', 'Microsoft Store', '', false))
        self.allSupportedRetailers.push(new RetailerProfile('MICROSOFT_ONLINE', 'Microsoft Digital Store', '', false))

        // Sort alphabetically by display name
        self.allSupportedRetailers.sort((a, b) => (a.displayName.toLowerCase() > b.displayName.toLowerCase()) ? 1 : -1)
      },
      () => { // On error
        self.errorTitle('Oops! No retailer info loaded.')
        self.errorMessage("We couldn't load available retailers, please refresh the page.")
      })
  }
}
