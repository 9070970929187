<script lang="ts">
const defaultCTAPlaceholder = 'Get it now:'
const defaultTitlePlaceholder = 'This title will be used instead of the default product title'
</script>
<script setup lang="ts">
import { getDefaults } from 'valibot'
import { model } from 'vue-observables'
import { createValidator } from 'vue-observables/adapters/valibot'

import {
  ChoicePageOptionsSchema,
  type ChoicePageOptions,
  DestinationDetailsSchema
} from '~/models/links-v4'
import { ref, computed } from 'vue'
import { useClient } from '~/utils'
import { type Product } from '~/models/products'
import { useSortable } from '@vueuse/integrations/useSortable'
import { type SuccessEventInfo } from './helpers'
import set from 'lodash/set'
import { getKey } from './helpers'
import env from '/env.js'

import InputText from 'primevue/inputtext'
import RadioButton from 'primevue/radiobutton'

import ProductTextBox from './ProductTextBox.vue'
import ImageUploader from './ImageUploader.vue'
import RetailerImage from './RetailerImage.vue'
import ProductRow from './ProductRow.vue'
import UploadWidget from './UploadWidget.vue'

import Button from '~/components/ui/Button.vue'
import Stack from '~/components/ui/layout/Stack.vue'
import Field from '~/components/ui/Field.vue'
import HelpTip from '~/components/ui/HelpTip.vue'
import Checkbox from '~/components/ui/Checkbox.vue'
import Spinner from '~/components/ui/Spinner.vue'
import CloudImage from '~/components/ui/CloudImage.vue'

import IconClose from '@material-symbols/svg-600/outlined/close.svg'
import IconAdd from '@material-symbols/svg-600/outlined/add.svg'
import IconDragHandle from '@material-symbols/svg-600/outlined/drag_handle.svg'
import IconKeyboardArrowDown from '@material-symbols/svg-600/outlined/keyboard_arrow_down.svg'

const { WEBSITE_URL } = env

type Destination = ChoicePageOptions['destinations'][0]
const options = defineModel<ChoicePageOptions>({
  default: {
    ...getDefaults(ChoicePageOptionsSchema),
    destinations: [{
      isDefault: true,
      destination: getDefaults(DestinationDetailsSchema)
    }]
  } satisfies ChoicePageOptions
})

const vm = model<ChoicePageOptions>(options, {
  validator: createValidator(ChoicePageOptionsSchema)
})

const destinations = vm('destinations')
const theme = vm('theme')
const productImage = vm('images.product')
const heroImage = vm('images.hero')

/** Validate at some point? */
const addDestination = () => {
  destinations().push({
    destination: {
      url: ''
    }
  })
}

/** @todo - Run through root Vee-validate */
const deleteItem = (dest: Destination) => {
  const index = destinations().indexOf(dest)
  destinations().splice(index, 1)
}


const ctaPlaceholder = computed(() => {
  return defaultCTAPlaceholder
})
const suggestionOpen = ref(false)

const firstAmazonOrITunesProduct = computed(() => {
  return destinations().find(dest => Boolean(dest.isDefault))
})


const titlePlaceholder = computed(() => {
  if (!firstAmazonOrITunesProduct.value?.destination.title) {
    return defaultTitlePlaceholder
  }
  return firstAmazonOrITunesProduct.value.destination.title
})

const query = computed(() => {
  if (firstAmazonOrITunesProduct.value) {
    return {
      url: firstAmazonOrITunesProduct.value.destination.url
    }
  }
  return null
})

const {
  data: suggestionData,
  loading: suggestionLoading,
  validating: suggestionValidating
} = useClient('/v3/product-suggestions', {
  query
})

const unselectedSuggestions = computed(() => {
  return suggestionData.value?.filter(suggestion => {
    return !destinations().some(dest => {
      return dest.destination.url === suggestion.productUrl
    })
  })
})

/**
 * WIP
 */
const addSuggestion = (product: Product) => {
  const logoUrl = product.retailerImages.Logo ?? product.retailerImages.Banner
  const dest = {
    destination: {
      url: product.productUrl,
      retailer: {
        name: product.retailer,
        images: {
          ...(logoUrl ? { logo: { url: logoUrl } } : {})
        }
      },
      meta: {
        isAutoSuggestion: true
      }
    }
  } satisfies Destination
  destinations().push(dest)
}

const dragContainer = ref<HTMLElement | null>(null)
useSortable(dragContainer, destinations(), {
  handle: '.handle'
})

const updateButtonImage = (dest: Destination, event: SuccessEventInfo) => {
  set(dest, 'images.button.url', event.secure_url)
}

const updateDestination = (dest: Destination) => {
  const dests = destinations()
  if (dest.destination.meta) {
    dest.destination.meta.isAutoSuggestion = false
  }

  const firstDefault = dests.find(dest => {
    return dest.destination.retailer?.name.toUpperCase().includes('AMAZON')
      ?? dest.destination.retailer?.name.toUpperCase().includes('APPLE')
  }) ?? dests[0]

  if (firstDefault) {
    dests.forEach((dest, i) => {
      const isFirst = dest === firstDefault
      if (dest.isDefault && !isFirst) {
        dests[i] = {
          ...dest,
          isDefault: false
        }
      } else if (!dest.isDefault && isFirst) {
        dests[i] = {
          ...dest,
          isDefault: true
        }
      } else {
        dests[i] = { ...dest }
      }
    })
  }
}

const updateRetailer = (index: number, event: string) => {
  vm(`destinations.${index}.destination.retailer.images.logo.url`).value = event
}

</script>

<template>
  <!--
    Note, Choice page destinations and Split destinations are similar, maybe some opportunity
    for abstraction?
  -->
  <section class="destinations">
    <Field label="Button" />
    <Field style="grid-column: span 2" label="Url or product name" />
    <div class="drag-list" ref="dragContainer">
      <div class="drag-item" v-for="(dest, index) in destinations()" :key="getKey(dest)">
        <UploadWidget v-slot="{ disabled, click }" @success="updateButtonImage(dest, $event)" preset="button">
          <Button
            plain
            trim
            @click="click"
            :disabled
          >
            <RetailerImage
              v-if="dest.destination.images?.button?.url"
              :modelValue="dest.destination.images.button.url"
            />
            <RetailerImage
              v-else
              :modelValue="dest.destination.retailer?.images?.logo.url"
              @update:modelValue="updateRetailer(index, $event)"
              :productUrl="dest.destination.url"
            />
          </Button>
        </UploadWidget>
        <div>
          <ProductTextBox
            v-model="destinations()[index].destination"
            @update:modelValue="updateDestination(dest)"
            required
            data-testid="ChoicePage__urlOrProduct"
          />
        </div>
        <div class="btn-column">
          <Button
            plain
            rounded
            class="handle"
          >
            <template #icon>
              <IconDragHandle />
            </template>
          </Button>
          <Button
            v-if="firstAmazonOrITunesProduct ? dest !== firstAmazonOrITunesProduct : index !== 0"
            plain
            rounded
            @click="deleteItem(dest)"
          >
            <template #icon>
              <IconClose />
            </template>
          </Button>
        </div>
      </div>
    </div>

    <div class="suggestions" v-if="suggestionLoading ?? suggestionValidating">
      <div class="suggestion-header">
        <span>Searching stores...
          <Spinner />
        </span>
      </div>
    </div>
    <div class="suggestions" v-else-if="unselectedSuggestions?.length">
      <div class="suggestion-header">
        <span>Product found in
          <strong>{{ unselectedSuggestions.length }} other
            {{ unselectedSuggestions.length === 1 ? 'store' : 'stores' }}
          </strong>
        </span>
        <Button
          variant="secondary"
          outlined
          class="suggestions-btn"
          @click="suggestionOpen = !suggestionOpen"
          data-testid="ChoicePage__seeSuggestionsBtn"
        >
          See suggestions
          <IconKeyboardArrowDown :class="{ 'icon-open': true, open: suggestionOpen }" />
        </Button>
      </div>
      <div :class="{ 'suggestion-content-container': true, open: suggestionOpen }">
        <section class="suggestion-content">
          <template v-for="(product, index) in unselectedSuggestions" :key="index">
            <ProductRow
              :product="product"
              defaultLabel=""
              selectable
              @update:checked="addSuggestion(product)"
              :data-testid="`ChoicePage__suggestionRow-${index}`"
            />
          </template>
        </section>
      </div>
    </div>

    <div style="grid-column: span 2; justify-self: end">
      <Button
        @click="addDestination"
        size="small"
        plain
        data-testid="ChoicePage__addAnotherDestinationBtn"
      >
        <template #icon>
          <IconAdd />
        </template>
        Add another destination
      </Button>
    </div>
  </section>
  <Stack is="section" class="page-options" space="gap24">
    <h3>Page Options</h3>
    <div class="description">
      <strong>Auto-Fill:</strong> We'll fill empty product images and titles using info from the first Amazon or iTunes link you provide above. You can always override them.
    </div>

    <Field label="Title">
      <InputText :placeholder="titlePlaceholder" data-testid="ChoicePage__titleInput" />
    </Field>

    <Field label="Call to action" data-testid="ChoicePage__callToAction">
      <InputText :placeholder="ctaPlaceholder" />
    </Field>

    <div class="layout">
      <div class="images">
        <ImageUploader
          title="Hero image (top)"
          preset="logo"
          v-model="heroImage.value"
          data-testid="ChoicePage__heroImageUploader"
        />
        <ImageUploader
          title="Product image"
          preset="product"
          v-model="productImage.value"
          :defaultImageUrl="firstAmazonOrITunesProduct?.destination?.images?.default?.url"
          data-testid="ChoicePage__productImageUploader"
        />
      </div>

      <div class="theme">
        <div class="theme-options">
          <Field>
            <RadioButton
              v-model="theme.value"
              name="theme"
              value="LIGHT"
              data-testid="ChoicePage__radioLight"
            />
            <span>Light & Clean</span>
          </Field>

          <Field>
            <RadioButton
              v-model="theme.value"
              name="theme"
              value="DARK"
              data-testid="ChoicePage__radioDark"
            />
            <span>Dark Art</span>
          </Field>
        </div>

        <div :class="['theme-preview', theme().toLowerCase()]">
          <div class="page">
            <div class="preview-logo-area">
              <CloudImage :src="heroImage()?.url" :width="25" />
            </div>
            <div class="preview-product-image-area">
              <CloudImage class="preview-product-image" :src="productImage()?.url ?? firstAmazonOrITunesProduct?.destination?.images?.default?.url" :width="80" />
            </div>
            <div class="placeholder title"></div>
            <div class="placeholder title"></div>
            <div class="placeholder cta"></div>
            <div class="placeholder store-button"></div>
            <div class="placeholder store-button"></div>
            <div class="placeholder store-button"></div>
          </div>
        </div>
      </div>
    </div>
    <Checkbox label="Remove affiliate disclosure" data-testid="ChoicePage__removeAffiliateDisclosureCB">
      <HelpTip data-testid="ChoicePage__removeAffiliateDisclosureHelp">
        By default, your page will include a small sentence stating that you may earn commissions from your links, as required by FTC regulations. If you are not using affiliated or other income-generating links, you may remove this disclosure from your Choice Page.
        <a :href="`${WEBSITE_URL}/blog/amazon-compliance-an-ftc-reminder-for-affiliates/`" target="_blank">Learn more here...</a>
      </HelpTip>
    </Checkbox>

  </Stack>
</template>

<style scoped lang="less">
.destinations {
  display: grid;
  grid-template-columns: 85px 1fr max-content;
  row-gap: 0.5rem;
  > * {
    margin-block: 0;
  }
}
.drag-list, .drag-item {
  display: grid;
  grid-template-columns: subgrid;
  grid-column: span 3;
}

label {
  font-weight: bold;
}

.layout {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(16rem, 1fr));
  gap: 2rem;
}

.images {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.theme {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.theme-options {
  display: flex;
  gap: 1rem;
}

.theme-preview {
  border: 1px solid var(--surface-200);
  width: 155px;
  height: 175px;
  overflow: hidden;
  color: #fff;
  position: relative;
  margin: 4px 0 0 0;

  &.dark {
    background: #1d2d2f;
  }

  &.light {
    background: #FFF;
  }

  .bg {
    position: absolute;
    left: 0;
    right: 0;
    z-index: 1;
    opacity: .16;
    display: block;
    background-size: cover;
    width: 100%;
    height: 100%;
    -webkit-filter: blur(2px);
    -moz-filter: blur(2px);
    -o-filter: blur(2px);
    -ms-filter: blur(2px);
    filter: blur(2px);
  }

  .page {
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    z-index: 1;
    margin-left: 10px;
    margin-right: 10px;
  }

  .preview-logo-area {
    max-width: 25px;
    margin: 10px auto 0 auto;
  }

  .preview-logo {
    max-width: 100%;
    height: auto;
    max-height: 20px;
    display: inline-block;
  }

  .preview-product-image-area {
    margin: 7px auto 3px auto;
    max-width: 80px;
    min-height: 50px;
  }

  .preview-product-image {
    margin: 0 auto 0 auto;
    max-width: 100%;
    max-height: 80px;
    display: block;
  }

  .placeholder {
    background: rgba(255,255,255,.20);
    display: block;

    &.title {
      width: 60px;
      height: 3px;
      margin: 1px auto 0 auto;
    }

    &.cta {
      width: 30px;
      height: 7px;
      margin: 8px auto 3px auto;
    }

    &.store-button {
      width: 30px;
      height: 11px;
      margin: 0 1px 0 1px;
      display: inline-block;
    }
  }
}

.suggestions {
  grid-column: span 2;
  padding: 4px 0 0;
  border-radius: 6px;
  font-size: 0.95rem;
  border-top: 1px solid var(--surface-200);
  border-bottom: 1px solid var(--surface-200);
}
.suggestions-btn {
  /** Never do this, but this is a unique button... */
  color: white !important;
  background: #3f404b url('~/assets/images/rainbow-stripes2.svg') right no-repeat !important;
  background-size: auto 100% !important;
  &:hover {
    background: lighten(#3f404b, 10%) url('~/assets/images/rainbow-stripes2.svg') right no-repeat !important;
    background-size: auto 100% !important;
  }
  margin-bottom: 4px;
}
.suggestion-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
  > span {
    height: 2.25rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1ch;
  }
}
.suggestion-content-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 0fr;
  transition: grid-template-rows 0.2s ease-out;
  overflow: hidden;
  margin: 0 -8px;
  min-height: 0;
  > * {
    min-height: 0;
  }
  &.open {
    grid-template-rows: 1fr;
  }
}

.suggestion-content-item {
  padding: 4px 8px;
  background-color: var(--surface-100);
}

.icon-open {
  transition: transform 0.2s ease;
  &.open {
    transform: rotate(-180deg);
  }
}
.handle {
  cursor: ns-resize;
}
</style>
