<script setup lang="ts">
import { type Space, space as spaceTokens } from './tokens'
import { computed } from 'vue'

const props = withDefaults(defineProps<{
  padding?: Space
}>(), {
  padding: 'gap28'
})

const paddingValue = computed(
  () =>
    props.padding in spaceTokens
      ? (spaceTokens as any)[props.padding]
      : props.padding
)
</script>

<template>
  <section class="card">
    <div v-if="$slots.header" class="card-header">
      <slot name="header" />
    </div>
    <slot />
    <div v-if="$slots.footer" class="card-footer">
      <slot name="footer" />
    </div>
  </section>
</template>

<style scoped>
.card {
  background-color: var(--surface-0);
  border: 1px solid var(--translucent-surface-300);
  border-radius: 2px;
  box-shadow: 2px 2px 15px 0 rgba(0, 0, 0, 0.05);
  padding: v-bind(paddingValue);
}
.card-header {
  background-color: var(--surface-200);
  margin-top: calc(v-bind(paddingValue) * -1);
  margin-left: calc(v-bind(paddingValue) * -1);
  margin-right: calc(v-bind(paddingValue) * -1);
  margin-bottom: v-bind(paddingValue);
}
.card-footer {
  background-color: var(--surface-200);
  padding: v-bind(paddingValue);
  margin-top: v-bind(paddingValue);
  margin-left: calc(v-bind(paddingValue) * -1);
  margin-bottom: calc(v-bind(paddingValue) * -1);
  margin-right: calc(v-bind(paddingValue) * -1);
  border-radius: 0 0 2px 2px;
}
</style>
