<script setup lang="ts">
import { ALL_RETAILERS } from '~/components/SelectSearchRetailer/SelectRetailerDropdownVM'
import { useClient, dayjs } from '~/utils'
import { computed, toRefs } from 'vue'
import { calculateReturnByResolutionString } from '~/scripts/basic-reporting-input'
import ChartTemplate from './ChartTemplate.vue'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import { abbrNum } from '~js/pretty-printing.js'
import IconPieChart from '@material-symbols/svg-600/outlined/pie_chart.svg'
import IconToc from '@material-symbols/svg-600/outlined/toc.svg'
import SelectButton from 'primevue/selectbutton'

export interface MiniReportProps {
  /** Dates in local time */
  startDate: Date
  endDate: Date
  retailerId: string
  hideBots: boolean
  name: string
  keyColumn?: string
  reportAttribute: string
  userLinkOrGroup?: 'group' | 'link' | 'user'
  groupId?: number
  shortCode?: string
}

const view = defineModel<'table' | 'chart'>('view', { default: 'chart' })

const props = withDefaults(defineProps<MiniReportProps>(), {
  groupId: 0
})

const startUtcDate = computed(() => dayjs(props.startDate).utc())
const endUtcDate = computed(() => dayjs(props.endDate).utc())
const startUtcDateString = computed(() => startUtcDate.value.format('YYYY-MM-DD'))
const endUtcDateString = computed(() => endUtcDate.value.format('YYYY-MM-DD'))

const retailerId = computed(() => props.retailerId === ALL_RETAILERS ? undefined : props.retailerId)
const resolution = computed(() => calculateReturnByResolutionString(props.startDate, props.endDate))

const refProps = toRefs(props)

const {
  data,
  loading,
  validating
} = useClient('/v1/reports/minireport-topattributes', {
  query: {
    start: startUtcDateString,
    end: endUtcDateString,
    retailerid: retailerId,
    reportattribute: refProps.reportAttribute,
    dopreviousperiod: 1,
    resolution,
    userlinkorgroup: refProps.userLinkOrGroup,
    shortcode: refProps.shortCode,
    groupid: refProps.groupId
  }
})

const otherRecord = computed(() => data.value?.find(item => item.key === 'Other'))
const otherKey = computed(() => otherRecord.value ? 'Other' : '')
const tableData = computed(() => data.value?.filter(item => item !== otherRecord.value))

const empty = computed(() => !data.value?.length)

const chartOptions = computed<Options>(() => {
  const clicksProp = props.hideBots ? 'clicksMinusBot' : 'clicks'

  if (data.value) {
    const options = merge({}, defaultOptions, {
      series: [{
        type: 'pie',
        name: props.name ?? 'Clicks',
        data: data.value.map(item => ({
          name: item.key,
          y: item.value[clicksProp]
        }))
      }] satisfies Options['series'],
      plotOptions: {
        pie: {
          colors: pieColors
        }
      } satisfies Options['plotOptions']
    })
    return options
  }
  return defaultOptions
})
defineEmits<{
  createLink: []
}>()
</script>

<script lang="ts">
import {
  type Options
} from 'highcharts'
import merge from 'lodash/merge'
import { textStyle, tooltip, pieColors } from './common'

// (Highcharts as any).Templating.helpers.log = function() {
//   console.log(arguments[0].ctx)
// }

const defaultOptions: Options = {
  chart: {
    height: 300,
    spacing: [0, 0, 0, 0],
    backgroundColor: 'transparent',
    plotBackgroundColor: undefined,
    plotBorderWidth: undefined,
    plotShadow: false,
    style: textStyle
  },
  title: {
    text: undefined
  },
  tooltip: {
    ...tooltip,
    pointFormat: '<b>{abbrNum point.y} {series.name}</b> ({point.percentage:.1f}%)<br/>'
  },
  plotOptions: {
    pie: {
      colors: pieColors,
      size: 240,
      center: [null, '50%'], // horiz, vert
      allowPointSelect: false,
      cursor: 'pointer',
      borderColor: 'var(--surface-200)',
      borderWidth: 1,
      innerSize: 60,
      dataLabels: {
        color: 'var(--text-color-secondary)',
        formatter: function() {
          if (this.point.name.length > 16) {
            return this.point.name.slice(0, 16) + '...'
          } else {
            return this.point.name
          }
        },
        enabled: true,
        softConnector: false,
        distance: 15,
        style: {
          fontFamily: 'Open Sans',
          fontSize: '0.95rem'
        }
      }
    }
  },
  exporting: { enabled: false },
  credits: {
    enabled: false
  }
}
</script>

<template>
  <ChartTemplate
    :validating
    :loading
    :empty
    v-model="chartOptions"
    :data-testid="`MiniReport__chartTemplate`"
    @createLink="$emit('createLink')"
  >
    <template #header>
      <div class="mini-header" :data-testid="`MiniReport__miniHeader-${name}`">
        <div><slot>{{ name }}</slot></div>
        <SelectButton :options="['chart', 'table']" v-model="view">
          <template #option="{ option }">
            <IconPieChart v-if="option === 'chart'" style="position: relative" />
            <IconToc v-else style="position: relative" />
          </template>
        </SelectButton>
      </div>
    </template>
    <template #chart v-if="view !== 'chart'">
      <div class="table-container">
        <DataTable
          :value="tableData"
          scrollable
          scrollHeight="100%"
          style="height: 100%"
          :pt:root:data-testid="`MiniReport__root`"
          :pt:table:data-testid="`MiniReport__table`"
          sortField="value.clicks"
          :sortOrder="-1"
        >
          <Column
            field="key"
            :header="keyColumn ?? 'Key'"
            sortable
            :footer="otherKey"
            footerClass="column-footer"
          />
          <Column
            field="value.clicks"
            header="Clicks"
            :footer="otherRecord?.value.clicks ? String(otherRecord?.value.clicks) : ''"
            footerStyle="text-align: right"
            bodyStyle="text-align: right"
            sortable
            style="width: 4rem"
          >
            <template #body="{ data: item }">
              {{ abbrNum(item.value.clicks, 1) }}
            </template>
          </Column>
        </DataTable>
      </div>
    </template>
  </ChartTemplate>
</template>

<style scoped lang="scss">
@use '~/assets/styles/utils.scss';

:deep(svg tspan) {
  stroke-width: 0;
}
.mini-header {
  font-size: 1.25rem;
  padding: 1rem;
  color: var(--text-color);
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
}
.table-container {
  height: 268px;
  margin: 16px;
}
:deep(tr:has(.column-footer)) {
  outline: 1px solid var(--surface-200);
  box-shadow: 0 -1px 4px var(--surface-100);
}
</style>
