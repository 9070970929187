<script setup lang="ts">
import { reactive, ref, computed } from 'vue'
import Card from '~/components/ui/Card.vue'
import Stack from '~/components/ui/layout/Stack.vue'
import Grid from '~/components/ui/layout/Grid.vue'
import Button from '~/components/ui/Button.vue'
import PanelActivator from '~/components/ui/PanelActivator.vue'
import Field from '~/components/ui/Field.vue'
import ButtonActions from '~/components/ui/layout/ButtonActions.vue'
import { useClient, useClientPromise } from '~/utils'

import IconAdd from '@material-symbols/svg-600/outlined/add.svg'

import MultiSelect from 'primevue/multiselect'
import Dialog from 'primevue/dialog'
import InputText from 'primevue/inputtext'
import Textarea from 'primevue/textarea'

const props = defineProps<{
  filterGenerics?: boolean
}>()
const pixels = defineModel<string[]>({ default: reactive([]) })

const tempName = ref('')
const tempJs = ref('')

const {
  data,
  loading,
  mutate
} = useClient('/v1/user-trackingpixels')

const sortedData = computed(() => {
  if (!data.value) return undefined
  let returnArr = data.value.slice(0).sort((a, b) => a.name.localeCompare(b.name))
  if (props.filterGenerics) {
    returnArr = returnArr.filter(item => !item.type.includes('generic'))
  }
  return returnArr
})

const resetForm = () => {
  tempName.value = ''
  tempJs.value = ''
}

const pixelSubmitting = ref(false)

const submitPixel = async () => {
  pixelSubmitting.value = true

  await useClientPromise('/v1/user-trackingpixels/update', {
    method: 'POST',
    body: {
      name: tempName.value,
      js: encodeURIComponent(tempJs.value.replace(/'/g, '%27'))
    }
  })

  resetForm()
  pixelSubmitting.value = false
  mutate()
}
</script>

<template>
  <Card padding="gap14">
    <Grid template="minmax(0, 1fr) 150px">
      <MultiSelect
        v-model="pixels"
        :loading
        display="chip"
        :options="sortedData"
        optionLabel="name"
        optionValue="id"
        class="multi"
        placeholder="Select pixels..."
        data-testid="Pixels__selectPixelsMulti"
      />
      <PanelActivator v-slot="{ show, visible, hide }">
        <Button
          variant="secondary"
          @click="show"
          :loading="pixelSubmitting"
          data-testid="Pixels__initialAddPixelBtn"
        >
          <template #icon>
            <IconAdd />
          </template>
          Add Pixel
        </Button>
        <Dialog
          v-model:visible="visible.value"
          :draggable="false"
          header="Add new tracking pixel"
          modal
          :closeOnEscape="false"
          blockScroll
          data-testid="Pixels__addNewPixelDialog"
        >
          <Stack>
            <Field label="Name">
              <InputText v-model="tempName" data-testid="Pixels__nameInput" />
            </Field>

            <Field label="Javascript">
              <Textarea v-model="tempJs" data-testid="Pixels__jsInput" />
            </Field>
          </Stack>

          <template #footer>
            <ButtonActions>
              <Button @click="submitPixel(); hide()" data-testid="Pixels__addPixelBtn">
                Add Pixel
              </Button>

              <Button variant="secondary" @click="resetForm(); hide()" data-testid="Pixels__cancelAddPixelBtn">
                Cancel
              </Button>
            </ButtonActions>
          </template>
        </Dialog>

      </PanelActivator>
    </Grid>
  </Card>
</template>

<style scoped>
.multi {
  display: flex;
}
</style>
