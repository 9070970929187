<script setup lang="ts">
/**
 * Nuxt lazy-loads components starting with "Lazy"
 */
import languages from '~/public/languages.js'
import { ref, watch } from 'vue'
import AutoComplete from 'primevue/autocomplete'

const filteredLanguages = ref<string[]>([])

interface Language {
  name: string | undefined
  code: string | undefined
}

defineProps<{
  modelValue: Language
}>()
const emit = defineEmits<{
  'update:modelValue': [value: Language]
}>()

const selectedLanguage = ref<string>()
const isValid = ref(true)

watch(selectedLanguage, lang => {
  if (lang) {
    const parts = lang.trim().split(':')
    if (parts.length === 2 && parts[0].length > 0 && parts[1].length > 0) {
      isValid.value = true
    } else {
      isValid.value = false
      return
    }
    emit('update:modelValue', { code: parts[0], name: parts[1] })
  }
})

const processItem = (item: string) => {
  const parts = item.split(':')
  return `<span class="language-code">${parts[0]}:</span><span class="language-name">${parts[1]}</span>`
}
</script>

<template>
  <AutoComplete
    :suggestions="filteredLanguages"
    v-model="selectedLanguage"
    dropdown
    placeholder="en:English"
    @complete="event => {
      if (event.query) {
        const query = event.query.trim().toLowerCase()
        filteredLanguages = (languages as string[])
          .filter(name => name.toLowerCase().includes(query))
      } else {
        filteredLanguages = [...(languages as string[])]
      }
    }"
  >
    <template #option="{ option }">
      <div class="foo" v-html="processItem(option)" />
    </template>
  </AutoComplete>
</template>

<style>
.language-code {
  display: inline-block;
  border-radius: 2px;
  padding: 2px 4px;
  background-color: var(--surface-200);
  width: 4.5ch;
  text-align: right;
}
.language-name {
  display: inline-block;
  border-radius: 2px;
  padding-left: 2px;
}
</style>
