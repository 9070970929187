/* eslint-disable @typescript-eslint/no-duplicate-enum-values */
export const enum PricingPlanConstants {
  // ID's
  starterId = 'starter',
  freeThenFiveId = 'freethenfive',
  plusId = 'plusnew',
  powerId = 'powernew',
  twoforonekId = 'twoforonek',
  business2018Id = 'business2018',
  fivefortwoId = 'fivefortwo',
  fivefortwowithtwoforoneId = 'fivefortwowithtwoforone',
  nineforfiveId = 'nineforfive',
  nineforfourptfiveId = 'nineforfourptfive',
  billedId = 'billed',
  corenewId = 'corenew',

  // TODO: we can probaby kill everything below once this is pulled from the db.
  // Display Names
  core = 'Core',
  plus = 'Plus',
  power = 'Power',
  legacy = 'Legacy',
  twoforonek = '$2/1K',
  fivefortwo = '$5/2K',
  fivefortwowithtwoforone = '$5 + $2/1K',
  nineforfive = '$9/5K',
  nineforfourptfive = '$9/4.5K',
  business2018 = 'Business',
  billed = 'Billed',
  starter = 'Core',
  freethenfive = 'Core',

  // cost
  starterCost = 1,
  plusCost = 99,
  powerCost = 499,
  freethenfiveCost = 0,
  twoforonekCost = 2,
  business2018Cost = 249,
  fivefortwoCost = 5,
  fivefortwowithtwoforoneCost = 5,
  nineforfiveCost = 9,
  nineforfourptfiveCost = 9,
  coreCost = 9,
  billedCost = 0,

  // clicks
  plusClicks = 100000,
  powerClicks = 50000,
  freethenfiveClicks = 5000,
  twoforonekClicks = 1000,
  business2018Clicks = 0,
  fivefortwoClicks = 2000,
  fivefortwowithtwoforoneClicks = 2000,
  nineforfiveClicks = 1000,
  nineforfourptfiveClicks = 1000,
  coreClicks = 10000,
  billedClicks = 10000,
  starterClicks = 1000,

  // TODO most of these plans dont' have any "free" clicks.  Bug or need to be re-named?
  // freeclicks
  plusFreeClicks = 100000,
  powerFreeClicks = 500000,
  freethenfiveFreeClicks = 1000,
  twoforonekFreeClicks = 1000,
  business2018FreeClicks = 0,
  fivefortwoFreeClicks = 2000,
  fivefortwowithtwoforoneFreeClicks = 2000,
  nineforfiveFreeClicks = 5000,
  nineforfourptfiveFreeClicks = 4500,
  coreFreeClicks = 10000,
  billedFreeClicks = 1000,
  starterFreeClicks = 1000,

  // Clicks Per Bucket
  plusClicksPerBucket = 10000,
  powerClicksPerBucket = 50000,
  freethenfiveClicksPerBucket = 5000,
  twoforonekClicksPerBucket = 1000,
  business2018ClicksPerBucket = 10000,
  fivefortwoClicksPerBucket = 2000,
  fivefortwowithtwoforoneClicksPerBucket = 1000,
  nineforfiveClicksPerBucket = 1000,
  nineforfourptfiveClicksPerBucket = 1000,
  coreClicksPerBucket = 1000,
  billedClicksPerBucket = 10000,
  starterClicksPerBucket = 1000,

  // bucket cost.
  plusCostPerBucket = 10,
  powerCostPerBucket = 50,
  freethenfiveCostPerBucket = 5,
  twoforonekCostPerBucket = 2,
  business2018CostPerBucket = 10,
  fivefortwoCostPerBucket = 5,
  fivefortwowithtwoforoneCostPerBucket = 2,
  nineforfiveCostPerBucket = 2,
  nineforfourptfiveCostPerBucket = 1,
  coreCostPerBucket = 1,
  billedCostPerBucket = 10,
  starterCostPerBucket = 1,

  // includedFeatures
  baseIncluded = 'Includes only our "Core" features.',
  plusIncluded = '"Core" and "Plus" features, including 100,000 clicks, commission tracking, and all features in our new pricing.',
  powerIncluded = '"Core", "Plus", and "Power" features, including 500,000 clicks, all features in our new pricing, plus dedicated account manager, monthly account reviews, 5hrs consulting, custom domains, additional users, and full API.',

  // ExcludedFeatures
  baseExcluded = 'include advanced reporting, choice pages, retargeting, UTMs, or A/B splits.'
}
