<script setup lang="ts">
import { type OverrideOptions } from '~/models/overrides'
import {
  type Rule,
  type Condition,
  type OsCondition
} from '~/models/links-v4'
import { ref, type Ref, nextTick, computed } from 'vue'

import ProductTextBox from './ProductTextBox.vue'
import Destinations from './Destinations.vue'

import Button from '~/components/ui/Button.vue'
import Card from '~/components/ui/Card.vue'
import Flex from '~/components/ui/layout/Flex.vue'
import PanelActivator from '~/components/ui/PanelActivator.vue'
import FilterPanel from './FilterPanel.vue'

import OverlayPanel from 'primevue/overlaypanel'
import Chip from 'primevue/chip'

import IconCheck from '@material-symbols/svg-600/outlined/check.svg'
import IconCheckCircle from '@material-symbols/svg-600/outlined/check_circle.svg'
import IconBlock from '@material-symbols/svg-600/outlined/block.svg'
import IconStraight from '@material-symbols/svg-600/outlined/straight.svg'
import IconClose from '@material-symbols/svg-600/outlined/close.svg'
import { type CountryInfo } from '~/models/countries'

const props = defineProps<{
  options: OverrideOptions
  countryOptions: CountryInfo[]
  first: boolean
  last: boolean
}>()

defineEmits<{
  moveup: []
  movedown: []
  delete: []
}>()

const rule = defineModel<Rule>({ required: true })
const { isArray } = Array

const displayNames = computed(() => {
  const map: Record<string, string> = {}
  /** value will (should?) be an array of objects */
  for (const value of Object.values(props.options)) {
    if (!isArray(value)) continue
    for (const obj of value) {
      if (typeof obj !== 'object' || obj === null) continue
      if (!obj.name || !obj.displayName) continue
      map[obj.name] = obj.displayName
    }
  }
  return map
})

/**
 * There are two menu states to track here, the `if` conditions
 * and the `if not` conditions. We track them separately to figure
 * out what action to take when each button is clicked.
 */
const open = {
  if: ref(false),
  ifNot: ref(false)
}

const saveCondition = (condition: Condition, done: () => void) => {
  rule.value.conditions.push(condition)
  done()
}

const closePanel = () => {
  open.if.value = false
  open.ifNot.value = false
}

/**
 * This just manages the UX behavior of keeping the panel open
 * if we click on "if not" while "if" is open
 */
const clickTarget = (
  e: MouseEvent,
  ifRef: Ref<boolean>,
  hide: () => void,
  show: (e: MouseEvent) => void
) => {
  const currentVal = ifRef.value
  open.if.value = false
  open.ifNot.value = false

  if (currentVal) {
    hide()
  } else {
    hide()
    nextTick(() => {
      ifRef.value = true
      show(e)
    })
  }
}

const getOperator = (op: Exclude<OsCondition['options'], undefined>['operator']) => {
  switch (op) {
    case '>=':
      return '≥'
    case '<=':
      return '≤'
    default:
      return op
  }
}

const renderCondition = (condition: Condition) => {
  let text = displayNames.value[condition.value] ?? condition.value
  switch (condition.type) {
    case 'DATE':
      return `${condition.operator === '>=' ? 'On or after' : '<'} ${formatter.format(new Date(condition.value))} (UTC)`
    case 'OS': {
      const { options } = condition
      if (options) {
        text += ` ${getOperator(options.operator)} ${options.major}`
        /** Minor version */
        if (options.minor > 0) {
          text += `.${options.minor}`
        }
      }

      return text
    }
    default:
      return text
  }
}
</script>

<script lang="ts">
/** Simple hack to format like yyyy-mm-dd */
const formatter = new Intl.DateTimeFormat('en-CA', {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
  hour: 'numeric',
  minute: 'numeric',
  hour12: false
})
</script>

<template>
  <Card padding="gap14" class="rule">
    <div class="btn-actions">
      <Button
        plain
        trim
        class="delete"
        @click="$emit('delete')"
      >
        <template #icon>
          <IconClose fill="var(--surface-400)" width="1.25rem" height="1.25rem" />
        </template>
      </Button>
      <br />
      <Button
        plain
        trim
        :disabled="first"
        class="move-up"
        @click="$emit('moveup')"
      >
        <template #icon>
          <IconStraight fill="var(--surface-400)" width="1.25rem" height="1.25rem" />
        </template>
      </Button>
      <Button
        plain
        trim
        :disabled="last"
        class="move-down"
        @click="$emit('movedown')"
      >
        <template #icon>
          <IconStraight fill="var(--surface-400)" width="1.25rem" height="1.25rem" />
        </template>
      </Button>
    </div>

    <Flex wrap class="chips">
      <PanelActivator v-slot="{ hide, show, panelRef }">
        <Button variant="secondary" size="small" @click="clickTarget($event, open.if, hide, show)">
          <template #icon>
            <IconCheckCircle />
          </template>
          If...
        </Button>
        <Button variant="secondary" size="small" @click="clickTarget($event, open.ifNot, hide, show)">
          <template #icon>
            <IconBlock />
          </template>
          If not...
        </Button>
        <OverlayPanel
          :ref="panelRef"
          @hide="closePanel"
          :pt:content:data-testid="`AdvancedRuleRow__content`"
        >
          <FilterPanel
            :options
            :countryOptions
            @add="(data: Condition) => saveCondition(data, hide)"
            :ifValue="open.if.value"
          />
        </OverlayPanel>
      </PanelActivator>

      <Chip
        v-for="(condition, index) in rule.conditions"
        :key="JSON.stringify(condition)"
        removable
        @remove="rule.conditions.splice(index, 1)"
        class="rule-chip"
        :class="{ negative: !condition.if }"
        :pt:root:data-testid="`AdvancedRuleRow__chipRoot`"
      >
        <span class="chip-type">{{ condition.type }}</span>
        <IconCheck v-if="condition.if" fill="var(--success-color)" />
        <IconBlock v-else fill="var(--error-color)" />
        {{ renderCondition(condition) }}
      </Chip>
    </Flex>
    <template #footer>
      <label>Send visitor to:</label>
      <Destinations v-model="rule.linkOptions" :hideDetails="true" />
    </template>
  </Card>
</template>

<style scoped>
.chips {
  min-height: 42px;
  padding-right: 3rem;
}
.rule-chip {
  border: 1px solid var(--surface-300);
  background-color: var(--surface-50); /* hsl(from var(--surface-300) h s l / 20%); */
  > span {
    padding: 0.25rem 0.5rem 0.25rem 0.75rem;
    border-radius: 16px 0 0 16px;
    margin-left: -0.75rem;
    margin-top: -0.25rem;
    margin-bottom: -0.25rem;
    margin-right: 0.5rem;
    background-color: var(--surface-200);
  }
  svg {
    margin-right: 0.5rem;
  }
}
.chip-type {
  text-transform: lowercase;
}
.rule {
  position: relative;
}
.btn-actions {
  position: absolute;
  top: 0;
  right: 0.5rem;
  text-align: right;
}
.move-up {
  cursor: n-resize;
}
.move-down {
  cursor: s-resize;
  transform: rotate(180deg);
}
/** Grant a standard touch target for touch devices */
:global(.uses-touch .move-actions button) {
  width: 2.25rem !important;
  height: 2.25rem;
}
</style>
