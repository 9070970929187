import {
  type LinkSubmitData,
  type CustomDomainData,
  type DestinationOptions,
  type SimpleOptions,
  type ABSplitOptions,
  type ChoicePageOptions,
  type DestinationDetails,
  ChoicePageOptionsSchema,
  ABSplitOptionsSchema,
  type PreviewFields
} from '~/models/links-v4'
import { computed, type Ref, ref, reactive, watch, toRefs } from 'vue'
import { type model, type Subscribable } from 'vue-observables'
import { getDefaults } from 'valibot'
import { getDestinationDefault, getPreviewForLinkOptions } from '~/models/transform-utils'

export const linkTypes = {
  SIMPLE: 'Simple',
  CHOICE_PAGE: 'Choice Page',
  AB_SPLIT: 'A/B Split'
} as const satisfies Record<DestinationOptions['type'], string>

export type LinkType = keyof typeof linkTypes

export type EditMode = 'edit' | 'copy'

export const enum Tabs {
  Destinations = 0,
  Tags = 1,
  Pixels = 2
}

export function checkDomainWhitelistForUrl(aDomain: CustomDomainData, aUrl: string) {
  let parser: URL
  try {
    parser = new URL(aUrl)
  } catch (e) {
    return false
  }
  const hostname = parser.hostname

  if (!aDomain.allowedDestinationDomains) {
    return false
  }

  for (let i = 0; i < aDomain.allowedDestinationDomains.length; i++) {
    const aSupportedDomain = aDomain.allowedDestinationDomains[i]

    if (aSupportedDomain === '*') {
      return true
    }

    if (aSupportedDomain.indexOf('.') === 0) {
      // a wild card rule
      if (hostname.endsWith(aSupportedDomain)) {
        return true
      }
    } else {
      if (hostname.indexOf('www.') === 0) {
        if (hostname.substring(4) === aSupportedDomain) {
          return true
        }
      }
      if (hostname === aSupportedDomain) {
        return true
      }
    }
  }
  return false
}

export function filteredDomains(
  urlRef: Ref<string>,
  domainsRef: Subscribable<CustomDomainData[] | undefined>
) {
  return computed(() => {
    const url = urlRef.value

    const match = domainsRef()?.filter(function(aDomain) {
      return !url.trim() || checkDomainWhitelistForUrl(aDomain, url)
    })

    if (!match || match.length === 0) return []

    return match
  })
}

let keyIndex = 0
const keyMap = new WeakMap<Record<string, unknown>, string>()

/** Creates a key from an object */
export function getKey(obj: Record<string, unknown>) {
  let keyStr = keyMap.get(obj)
  if (!keyStr) {
    keyStr = `${keyIndex++}`
    keyMap.set(obj, keyStr)
  }
  return keyStr
}

/** This isn't properly defined in the types */
export interface SuccessEventInfo {
  asset_id: string
  bytes: number
  /** ISO Date */
  created_at: string
  delete_token: string
  etag: string
  folder: string
  format: string
  height: number
  id: string
  original_filename: string
  path: string
  public_id: string
  secure_url: string
  signature: string
  thumbnail_url: string
  url: string
  version_id: string
  width: number
  version: number
}

/**
 * This reactive context object manages not only the data in/out of the
 * link editor, but also a number of temporary computed properties to
 * manage related state, such as the default destination, and preview
 * fields.
 */
export interface DestinationContext extends PreviewFields {
  default: DestinationDetails
  linkType: LinkType
  simple: SimpleOptions
  abSplit: ABSplitOptions
  choicePage: ChoicePageOptions
}

export interface ModelContext {
  vm: ReturnType<typeof model<LinkSubmitData>>
  destination: DestinationContext
}

/**
 * Because the above create proxies, then the defaults and first entries are no longer the same objects,
 * so we'll sync the objects again.
 */

const setModelByType = (
  type: LinkType,
  destinationOptions: Ref<DestinationOptions>,
  context: DestinationContext
) => {
  switch (type) {
    case 'SIMPLE':
      destinationOptions.value = context.simple
      break
    case 'CHOICE_PAGE': {
      destinationOptions.value = context.choicePage
      break
    }
    case 'AB_SPLIT':
      destinationOptions.value = context.abSplit
      break
  }
}

/**
 * This sets up a collection of reactive objects for the destination options,
 * as well as a dynamic getter / setter for the link type and default destination.
 */
export function useDestinationContext(
  destinationOptions: Ref<DestinationOptions>
): DestinationContext {
  const simple = ref<SimpleOptions>(
    destinationOptions.value.type === 'SIMPLE'
      ? destinationOptions.value
      : {
          type: 'SIMPLE',
          destination: { url: '' }
        }
  )
  const choicePage = ref<ChoicePageOptions>(
    destinationOptions.value.type === 'CHOICE_PAGE'
      ? destinationOptions.value
      : {
          ...getDefaults(ChoicePageOptionsSchema),
          destinations: [{
            isDefault: true,
            destination: { url: '' }
          }]
        }
  )
  const abSplit = ref<ABSplitOptions>(
    destinationOptions.value.type === 'AB_SPLIT'
      ? destinationOptions.value
      : {
          ...getDefaults(ABSplitOptionsSchema),
          destinations: [{
            isDefault: true,
            destination: { url: '' },
            percentage: '100'
          }]
        }
  )

  const defaultDestination = computed<DestinationDetails>(
    () => {
      const options = destinationOptions.value
      return getDestinationDefault(options)
    }
  )

  const previewFields = computed(() => toRefs(
    getPreviewForLinkOptions(destinationOptions.value, defaultDestination.value)
  ))

  /** If the default changes, then copy over to other collections */
  watch(defaultDestination, newDefault => {
    const updateSimple = () => {
      simple.value.destination = { ...newDefault }
    }
    const updateChoicePage = () => {
      const defaultIndex = choicePage.value.destinations.findIndex(dest => dest.isDefault) ?? 0
      choicePage.value.destinations[defaultIndex].destination = { ...newDefault }
    }
    const updateABSplit = () => {
      abSplit.value.destinations[0].destination = { ...newDefault }
    }

    /** Based on our current linktype, update the others */
    switch (destinationOptions.value.type) {
      case 'SIMPLE':
        updateChoicePage()
        updateABSplit()
        break
      case 'CHOICE_PAGE':
        updateSimple()
        updateABSplit()
        break
      case 'AB_SPLIT':
        updateSimple()
        updateChoicePage()
        break
    }
  })

  const context: DestinationContext = reactive({
    default: defaultDestination,
    simple,
    choicePage,
    abSplit,
    linkType: computed({
      get: () => destinationOptions.value.type,
      set: (type: DestinationOptions['type']) => {
        setModelByType(type, destinationOptions, context)
      }
    }),
    ...previewFields.value
  })

  /** Initialize models */
  setModelByType(destinationOptions.value.type, destinationOptions, context)

  return context
}
