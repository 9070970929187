/*
 * Make sure this matches the RetailerProfile class we have in Common
 */

export class RetailerProfile {
  public id: string
  public displayName: string
  public logoUrl: string
  public isSponsoredClick: boolean

  constructor(id: string, displayName: string, logoUrl: string, isSponsoredClick: boolean) {
    const self = this
    self.id = id
    self.displayName = displayName
    self.logoUrl = logoUrl
    self.isSponsoredClick = isSponsoredClick
  }
}
