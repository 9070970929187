<script setup lang="ts">
import { computed } from 'vue'
import { getImageUrl } from '~/components/utils'
import ImagePlaceholder from './ImagePlaceholder.vue'

const props = defineProps<{
  src: string | undefined
  width?: number
  height?: number
}>()

const cloudSrc = computed(() => {
  if (props.src && !props.src.includes('image_unavailable')) {
    return getImageUrl(props.src, { width: props.width, height: props.height })
  }
  return ''
})

const imageStyle = computed(() => {
  const style: Record<string, string> = {}
  if (props.width) {
    style.width = `${props.width}px`
  }
  if (props.height) {
    style.height = `${props.height}px`
  }
  return style
})
</script>

<template>
  <img
    v-if="cloudSrc"
    :src="cloudSrc"
    :style="imageStyle"
    class="cloud-image"
  />
  <ImagePlaceholder :width :height v-else />
</template>

<style scoped>
.cloud-image {
  object-fit: contain;
  object-position: center;
}
</style>
