<script setup lang="ts">
import { ref, watch, computed } from 'vue'
import { type ProductDetailsResponse } from '~/models/links-v1'
import { useClientPromise, useClient } from '~/utils'
import Spinner from '~/components/ui/Spinner.vue'
import { getImageUrlRef } from '~/components/utils'
import Stack from '~/components/ui/layout/Stack.vue'
import ProductImage from '~/components/ProductImage/ProductImage.vue'
import Checkbox from '~/components/ui/Checkbox.vue'

import IconGlobe from '@material-symbols/svg-600/outlined/globe.svg'

type Link = ProductDetailsResponse['link']

const url = defineModel<string>({ required: true })
const link = defineModel<Link>('link')
const loadingProduct = ref(false)
const monetizationEnabled = defineModel<boolean>('monetizationEnabled', { default: true })

/** Get the link object if we don't have it yet. */
watch(url, async newUrl => {
  if (!link.value || link.value.productUrl !== newUrl) {
    loadingProduct.value = true
    const [, data] = await useClientPromise('/api/v1/shorturl/preview-product-details', {
      query: { url: newUrl }
    }).finally(() => {
      loadingProduct.value = false
    })
    if (data) {
      link.value = data
    }
  }
}, { immediate: true })

const {
  data,
  loading
} = useClient('/api/v2/inspect-url-for-optimizations', {
  query: { url }
})

const imageUrl = getImageUrlRef(link, { width: 73, height: 73 })

const optimizedMsg = computed(() => {
  const returnData = data.value
  if (!returnData) {
    return ''
  }
  if (returnData.isTranslationEnabled) {
    let storeName = ''
    const firstAffiliateProgram = (returnData.affiliatePrograms[0] as string).toUpperCase()
    if (firstAffiliateProgram.includes('AMAZON')) {
      storeName = 'Amazon'
    } else if (firstAffiliateProgram.includes('APPLE')) { // Need to change this logic if we support more than Amazon and iTunes
      storeName = 'iTunes'
    }
    return 'Automatically localized for international ' + storeName + ' sales.'
  }
})
const earnMsg = computed(() => {
  const returnData = data.value
  if (!returnData) {
    return ''
  }
  if (returnData.isTranslationEnabled) {
    return `Earn money with your 2 saved affiliate programs: ${returnData.affiliatePrograms.join(', ')}`
  }
})
</script>

<template>
  <section class="product-details">
    <Spinner v-if="loadingProduct" />
    <div class="product-info" v-else-if="link">
      <ProductImage
        linkType="SIMPLE"
        :imageSrc="imageUrl"
        :productUrl="link.productUrl"
      />
      <Stack class="description">
        <div class="title">
          <h4 v-html="link.productDisplayName1" :title="link.productDisplayName1">
          </h4>

          <p v-if="link.productDisplayName2">
            {{ link.productDisplayName2 }}
          </p>
        </div>

        <Spinner v-if="loading" />
        <Stack v-else class="details" space="gap4">
          <div>
            <IconGlobe />
            {{ optimizedMsg }}
          </div>
          <Checkbox :label="earnMsg" v-model="monetizationEnabled" />
        </Stack>
      </Stack>

      <!-- <p
        class="linkmanage-reassure"
        :class="{
          'show-programs' : vm.showEnabledProgramsMsg()
            || vm.enabledProgramsLength() < 3
        }"
      >
        <span v-if="vm.optimizedMsg()">
          <span class="localized-icon"></span><span v-html="vm.optimizedMsg()"></span><br />
        </span>

        <span v-if="vm.affiliatedMsg()">
          <span><input
            class="check-disable-affiliation"
            type="checkbox"
            v-model="affiliationEnabled"
          ></span>
          <span v-html="vm.affiliatedMsg()"></span>
          <strong
            class="not-when-showing-programs"
            @click="vm.toggleEnabledProgramsMsg.bind(vm)"
            v-if="vm.enabledProgramsLength() > 2"
          >Show all</strong>
          <span
            v-html="vm.enabledProgramsMsg()"
            :class="{'when-showing-programs' : vm.enabledProgramsLength() > 2}"
          ></span>
          <strong
            class="when-showing-programs"
            @click="vm.toggleEnabledProgramsMsg.bind(vm)"
            v-if="vm.enabledProgramsLength() > 2"
          >Hide</strong>
          <span
            class="disable-affiliation-warning"
            v-if="affiliationEnabled"
          >Disabled for this link</span>
        </span>
      </p> -->

    </div>

    <!-- <ProductSuggestionsEnticement
        v-if="showProductSuggestionsEnticement"
        mode="change"
        :action="vm.advanceMenuSwitch.bind(vm, 'Interstitial')"
        :username="vm.username"
        :bus="vm.bus"
        :url="vm.editUrlFieldValue"
        actionText="Create a Choice Page"
        helpText="Why?"
        helpLink="https://geni.us/suggestions"
      /> -->

  </section>
</template>

<style scoped>
.product-info {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: min-content minmax(0, 1fr);
  gap: 1rem;
  align-items: start;
}
h4, p {
  margin: 0;
}
h4 {
  color: var(--text-color);
  font-weight: 600;
}
</style>
