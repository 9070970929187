import {
  observable,
  observableArray,
  computed,
  type Computed,
  fromJS
} from '~/components/utils'
import $ from 'jquery'
import { watchEffect, watch, isReactive } from 'vue'
import type { Link } from '~/models/links-v1'

/**
 * @deprecated - Still used by some components & classes
 * @todo - Separate data from logic
 */
export class ProductDetails {
  productArtworkThumbnailUrl = observable('')
  productDisplayName1 = observable('')
  productDisplayName2 = observable('')
  optimizedMsg = observable('')
  affiliatedMsg = observable('')
  inspectionIsLoading = observable(false)
  enabledPrograms = observableArray()
  enabledProgramCountries = observableArray<any>()
  showEnabledProgramsMsg = observable(false)
  linkType = observable('')
  displayName = observable('Loading...')
  maxLengthForDisplayNameShort = 40
  maxLengthForDisplayNameShortWOIcon = 50
  advertiserIconCssClass = observable('')
  iconCssClassDarkBkgnd = observable('')
  iconCssClassLightBkgnd = observable('')
  linkTypeSimple = observable('')
  productArtworkThumbnailUrl150 = observable('')
  previewDetailsXhr: JQueryXHR<any> | null = null
  optimizationXhr: JQueryXHR<any> | null = null

  advertiserIconTitle = observable('')
  advertiserId = observable(5)
  georiotLink = observable('')
  summaryLink = observable('')
  applePreference = observable(-1) // so we can dissect the link on the SS and determine if the field should be auto-set

  previewIsLoading = observable(false)
  // note: pulling this for now in favor of grabicon service
  // goggleApiFaviconPath = 'http://www.google.com/s2/favicons?domain=';
  grabiconpath = '//grabicon.cdn.geni.us/?size=56&domain='
  faviconPath = observable('')

  enabledProgramsLength: Computed<number>
  thumbnailUrlFormatted: Computed<string>
  isITunesProduct: Computed<boolean>
  showIcon: Computed<boolean>
  displayNameShort: Computed<string>
  enabledProgramsMsg: Computed<string>
  updateFavicon: Computed<void>
  displayProductArtwork: Computed<boolean>
  // image is either artwork of the favicon.. whatever we want to display
  image = observable('')
  url = observable('')

  constructor(data: string | Link) {
    const self = this

    if (typeof data === 'string') {
      self.url(data)
    } else {
      self.initializeWithLinkObj(data)
      /** Reactively watch if data object changes  */
      if (isReactive(data)) {
        watch(data, () => {
          self.initializeWithLinkObj(data)
        })
      }
    }

    self.enabledProgramsLength = computed(function() {
      return self.enabledPrograms().length
    })

    self.thumbnailUrlFormatted = computed(function() {
      return ''
      // return ImageTransformer.getThumbnail(self.productArtworkThumbnailUrl())
    })

    self.advertiserIconCssClass.subscribe(function(newVal) {
      const iconCssClass = 'uicon ' + newVal

      self.iconCssClassDarkBkgnd(iconCssClass + '-i')
      self.iconCssClassLightBkgnd(iconCssClass)
    })

    self.isITunesProduct = computed(function() {
      return self.advertiserIconCssClass().startsWith('uicon-itunes')
    })

    self.showIcon = computed(function() {
      return self.advertiserIconCssClass() !== ''
    })

    self.displayNameShort = computed(function() {
      const displayName = self.displayName().trim()

      if (!self.showIcon() && displayName.length > self.maxLengthForDisplayNameShort) {
        return displayName.substring(0, self.maxLengthForDisplayNameShort) + '..'
      } else if (!self.showIcon() && displayName.length > self.maxLengthForDisplayNameShortWOIcon) {
        return displayName.substring(0, self.maxLengthForDisplayNameShortWOIcon) + '..'
      } else {
        return displayName
      }
    })

    self.enabledProgramsMsg = computed(function() {
      return self.enabledPrograms().join(', ')
    }) // .extend({ throttle: 100 })

    self.updateFavicon = computed(function() {
      if (self.url() !== '') {
        const path = self.isITunesProduct()
          ? self.grabiconpath + 'https://itunes.apple.com'
          : self.grabiconpath + self.url()
        self.faviconPath(path)
      } else {
        self.faviconPath('')
      }
    }) // .extend({ throttle: 4 })

    self.displayProductArtwork = computed(function() {
      const re = /image_unavailable_[0-9]+\.png$/
      const thumbnail = self.productArtworkThumbnailUrl()
      return !re.test(thumbnail)
    })

    /**
     * Set the image from these values, BUT
     * we can't use a `computed` because external
     * dependencies can manually change the value of `image`
     */
    watchEffect(() => {
      let imageUrl = self.productArtworkThumbnailUrl()
      const displayProductArtwork = self.displayProductArtwork()
      if (!imageUrl || !displayProductArtwork) {
        imageUrl = self.faviconPath()
      }
      self.image(imageUrl)
    })
  }

  toggleEnabledProgramsMsg() {
    if (this.showEnabledProgramsMsg()) {
      this.showEnabledProgramsMsg(false)
    } else {
      this.showEnabledProgramsMsg(true)
    }
  }

  wipe() {
    if (this.optimizationXhr != null) this.optimizationXhr.abort()
    if (this.previewDetailsXhr != null) this.previewDetailsXhr.abort()
    this.enabledPrograms([])
    this.enabledProgramCountries([])
    this.productArtworkThumbnailUrl('')
    this.productDisplayName1('')
    this.productDisplayName2('')
    this.linkType('')
    this.displayName('')
    this.advertiserIconCssClass('')
    this.faviconPath('')
    this.previewIsLoading(false)
    this.advertiserId(5)
    this.url('')

    this.summaryLink('')
    this.georiotLink('')
  }

  initializeWithLinkObj(link: Link, setApplePreferenceBasedOnAppParam: boolean = true) {
    // Set link creator setting if provided
    const setting = link.linkCreatorSetting
    if (
      setting != null && setting.trim() !== ''
      && setting.trim().toLowerCase() !== 'notset'
    ) {
      this.linkType(setting)
    }

    this.productDisplayName1(link.productDisplayName1)
    this.productDisplayName2(link.productDisplayName2)

    this.url(link.productUrl)

    this.productArtworkThumbnailUrl(
      link.productArtworkThumbnailUrl150
      ?? link.productArtworkThumbnailUrl100
      ?? link.productArtworkThumbnailUrl
    )

    this.advertiserIconCssClass(link.advertiserIconCssClass ?? '')
    this.advertiserIconTitle(link.advertiserIconTitle ?? '')
    this.advertiserId(link.advertiserId)

    this.setDisplayNameFromLink(link)

    const defaultApplePreferenceCode = 0

    this.applePreference(
      'applePreference' in link && setApplePreferenceBasedOnAppParam
        ? link.applePreference ?? defaultApplePreferenceCode
        : defaultApplePreferenceCode
    )
  }

  /** @todo - refine types */
  setDisplayNameFromLink(link: any) {
    this.setDisplayName(link.productDisplayName1, link.productDisplayName2)
  }

  setDisplayName(displayName1: string, displayName2: string) {
    this.displayName(
      displayName1
      + (
        displayName2 !== ''
          ? (': ' + displayName2)
          : ''
      ))
  }

  /** @todo - refine types */
  pull(
    url: string,
    setApplePreferenceBasedOnAppParam: boolean,
    managingLinkType: any,
    callback?: (...args: any[]) => any,
    shortCode?: string,
    groupId?: number
  ) {
    this.url(url)
    this.productDisplayName1('')
    this.productDisplayName2('')
    this.optimizedMsg('')
    this.affiliatedMsg('')
    this.inspectionIsLoading(false)
    this.enabledPrograms.removeAll()
    this.enabledProgramCountries.removeAll()
    this.linkType('')
    this.advertiserIconCssClass('')
    this.productArtworkThumbnailUrl('')
    this.previewIsLoading(true)
    this.applePreference(0)
    this.advertiserId(5)
    this.productArtworkThumbnailUrl150('')

    this.linkTypeSimple(managingLinkType)
    $.ajaxSetup({ cache: false })
    const previewDetailsXhr = this.previewDetailsXhr = $.getJSON('/api/v1/shorturl/preview-product-details', {
      url, shortCode, format: 'json'
    })

    void previewDetailsXhr.fail((xhr, errorType, exception) => {
      const pngSuffix = '.png'
      if (url.includes(pngSuffix, url.length - pngSuffix.length)) {
        this.productArtworkThumbnailUrl(url)
        this.productArtworkThumbnailUrl150(url)
        this.productDisplayName1('Image')
        this.advertiserIconCssClass('unk') // hack to display thumbnail
      } else {
        // <!--  no preview -->
        this.productDisplayName1(url)
        this.productArtworkThumbnailUrl('/images/image_unavailable_40.png')
        this.productArtworkThumbnailUrl150('/images/image_unavailable_40.png')
      }

      this.advertiserId(5)
    })

      .done((data) => {
        if (!data.isError && data.link != null) {
          this.initializeWithLinkObj(data.link, setApplePreferenceBasedOnAppParam)
        } else {
          this.advertiserIconCssClass('')
          this.productArtworkThumbnailUrl('/images/image_unavailable_40.png')
          this.productArtworkThumbnailUrl150('/images/image_unavailable_40.png')
          this.advertiserId(5)
        }
      })
      .always(() => {
        this.previewIsLoading(false)

        // Fetch affiliation and optimization info if we are looking at a simple link
        if (managingLinkType === this.linkTypeSimple()) {
          const payload = {
            url,
            format: 'json'
          } as {
            url: any
            format: string
            groupId: number
          }

          // Attaching groupId allows us to retrieve affiliate override programs as well
          // if no groupId, we'll just retrieve the default affiliate programs
          if (groupId != null && groupId > 0) {
            payload.groupId = groupId
          }

          this.inspectionIsLoading(true)
          const optimizationXhr = this.optimizationXhr = $.getJSON('/api/v2/inspect-url-for-optimizations', payload)
          void optimizationXhr
            .done((data) => {
              let storeName = ''
              if (data.isTranslationEnabled) {
                // Kind of dumb check to see if this link is for Amazon or not.
                if (data.affiliatePrograms[0].indexOf('Amazon') > -1) {
                  storeName = 'Amazon'
                } else { // Need to change this logic if we support more than Amazon and iTunes
                  storeName = 'iTunes'
                }
                this.optimizedMsg('Automatically localized for international ' + storeName + ' sales.')
              }

              $.each(data.countries, (index, country) => {
                this.enabledProgramCountries.push(country)
              })

              $.each(data.affiliatePrograms, (index, program) => {
                this.enabledPrograms.push(program)
              })
              if (this.enabledPrograms().length > 0) {
                const numPrograms = this.enabledPrograms().length
                const affiliatedMessage = 'Earn money with your ' + numPrograms + ' saved affiliate programs: '
                this.affiliatedMsg(affiliatedMessage)
              }
            })
            .always(() => {
              if (typeof callback !== 'undefined') {
                callback()
              }
              this.previewIsLoading(false)
              this.inspectionIsLoading(false)
            })
        }
      })
  }
}
