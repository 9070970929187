<script setup lang="ts">
import { computed, type CSSProperties } from 'vue'
import { type Space, space as spaceTokens } from '~/components/ui/tokens'

/** @todo - need more layout tests to validate how this will work */

const props = withDefaults(
  defineProps<{
    space?: Space
    minWidth?: string
    template?: CSSProperties['gridTemplateColumns']
  }>(), {
    space: 'gap10',
    minWidth: '0',
    /** Equal-width columns by default */
    template: () => 'repeat(auto-fit, minmax(var(--min-width), 1fr))'
  }
)

const spaceValue = computed(
  () =>
    props.space in spaceTokens
      ? (spaceTokens as any)[props.space]
      : props.space
)
</script>

<template>
  <div>
    <slot />
  </div>
</template>

<style scoped>
div {
  --min-width: v-bind(minWidth);
  display: grid;
  grid-template-columns: v-bind(template);
  gap: v-bind(spaceValue);
  /** Direct children should be managed by gaps / column layout */
}
div:deep(> *) {
  margin: 0;
}
</style>
