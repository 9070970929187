<script lang="ts">
import { reportsCollection } from './common'
import { type Writable } from 'type-fest'

</script>

<script setup lang="ts">
import MiniReport from './MiniReport.vue'
import Dropdown from 'primevue/dropdown'
import { watchEffect, ref, computed } from 'vue'

export interface ReportProps {
  /** Dates in local time */
  startDate: Date
  endDate: Date
  retailerId: string
  hideBots: boolean
  hideSources?: boolean
  sourcesOnly?: boolean
  groupId?: string
  shortCode?: string
}

const userLinkOrGroup = computed(() => {
  if (props.groupId) {
    return 'group'
  } else if (props.shortCode) {
    return 'link'
  } else {
    return 'user'
  }
})

const view = defineModel<'table' | 'chart'>('view', { default: 'chart' })
const activeReport = defineModel<{
  name: string
  value: string
  keyColumn: string
}>({ default: reportsCollection[0].items[0] })
const props = defineProps<ReportProps>()

const options = ref<any[]>(reportsCollection as Writable<typeof reportsCollection>)

watchEffect(() => {
  if (props.hideSources) {
    options.value = reportsCollection.filter(report => report.name !== 'Sources')
  } else if (props.sourcesOnly || userLinkOrGroup.value === 'link') {
    options.value = reportsCollection.filter(report => report.name === 'Sources')
  } else {
    options.value = reportsCollection as Writable<typeof reportsCollection>
  }
  /** If we're on the groups page, don't show groups as a chart */
  if (userLinkOrGroup.value === 'group') {
    options.value[0].items = options.value[0].items.filter((item: any) => item.value !== 'group')
  }

  activeReport.value = options.value[0].items[0]
})
defineEmits<{
  createLink: []
}>()
</script>

<template>

  <section>
    <MiniReport
      :name="activeReport.name"
      :reportAttribute="activeReport.value"
      :keyColumn="activeReport.keyColumn"
      :view
      :startDate
      :endDate
      :retailerId
      :hideBots
      :shortCode
      :userLinkOrGroup
      :data-testid="`MiniChart__div-${activeReport.name}`"
      @createLink="$emit('createLink')"
    >
      <Dropdown
        v-model="activeReport"
        :options
        optionLabel="name"
        optionGroupLabel="name"
        optionGroupChildren="items"
        filter
        resetFilterOnHide
        :data-testid="`MiniChart__drop-down-${activeReport.name}`"
        :pt:header:data-testid="`MiniChart__header-${(activeReport.name)}`"
        :pt:input:data-testid="`MiniChart__input-${(activeReport.name)}`"
        :pt:list:data-testid="`MiniChart__list-${(activeReport.name)}`"
        :pt:option:data-testid="`MiniChart__option-${(activeReport.name)}`"
        :pt:root:data-testid="`MiniChart__root-${(activeReport.name)}`"
      />
    </MiniReport>
  </section>

</template>
