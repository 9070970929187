import type { Options } from 'highcharts'
/** Style for text */
export const textStyle = {
  fontFamily: 'Open Sans',
  color: 'var(--text-color-secondary)'
}

export const pieColors = [
  '#008BEE', // #008BEE
  '#00B9EE', // #00B9EE
  '#8BDAF1', // #8BDAF1
  '#9CCD8A', // #9CCD8A
  '#6AB94E', // #6AB94E
  '#F6AE2D', // #F6AE2D
  '#F2CD8C', // #F2CD8C
  '#EC8F79', // #EC8F79
  '#E95230', // #E95230
  '#4B5A6E', // #4B5A6E
  '#2B2B33' // #2B2B33
]

// to #ddc095

export const tooltip: Options['tooltip'] = {
  shared: true,
  borderColor: '#aaaaaa',
  borderWidth: 1,
  shadow: false,
  pointFormat: '{abbrNum point.y}',
  style: {
    fontFamily: 'Open Sans'
  }
}

export const reportsCollection = [
  {
    name: '',
    items: [
      {
        name: 'Top Links',
        keyColumn: 'Links',
        value: 'shorturl'
      },
      {
        name: 'Groups',
        keyColumn: 'Group',
        value: 'group'
      },
      {
        name: 'Product Types',
        keyColumn: 'Type',
        value: 'product'
      },
      {
        name: 'Destinations',
        keyColumn: 'Destination',
        value: 'destination'
      }
    ]
  },
  {
    name: 'Tags',
    items: [
      {
        name: 'Tags - Source',
        keyColumn: 'Tag',
        value: 'tag'
      },
      {
        name: 'Tags - Medium',
        keyColumn: 'Tag',
        value: 'umt'
      },
      {
        name: 'Tags - Campaign',
        keyColumn: 'Tag',
        value: 'uct'
      },
      {
        name: 'Tags - Term',
        keyColumn: 'Tag',
        value: 'utt'
      },
      {
        name: 'Tags - Content',
        keyColumn: 'Tag',
        value: 'ucot'
      }
    ]
  },
  {
    name: 'Sources',
    items: [
      {
        name: 'Referrers',
        keyColumn: 'Referrer',
        value: 'referrer'
      },
      {
        name: 'Devices',
        keyColumn: 'Device',
        value: 'device'
      },
      {
        name: 'OS',
        keyColumn: 'OS',
        value: 'os'
      },
      {
        name: 'Browsers',
        keyColumn: 'Browser',
        value: 'browser'
      },
      {
        name: 'Language',
        keyColumn: 'Language',
        value: 'lang'
      }
    ]
  }
] as const
