import { type Ref, computed } from 'vue'
import { type ProductDetailsResponse } from '~/models/links-v1'

type Link = ProductDetailsResponse['link']

/**
 * From Booklinker
 *
 * @todo - Abstract these common utilities to a shared package
 */
interface TransformationOptions {
  width?: number
  height?: number
  /** Scales to double-size (2) by default */
  scale?: number
}

export const getImageUrlRef = (link: Ref<Link | undefined> | string, options: TransformationOptions = {}) => {
  return computed(() => getImageUrl(link, options))
}

export const getImageUrl = (link: Ref<Link | undefined> | string, options: TransformationOptions = {}) => {
  if (!link) {
    return ''
  }
  let url = typeof link === 'string' ? link : link.value?.productArtworkThumbnailUrl
  if (!url || !url.startsWith('http')) {
    return url
  }
  /** Scaling multiplier */
  const { scale = 2 } = options
  /** Remove Amazon / iTunes sizing parameter */
  url = encodeURIComponent(url.replace(/_SL[0-9]+_\./, '').replace(/[0-9]+x[0-9]+bb/, '1000x1000bb'))
  const transforms = new Set<string>()
  if (options?.width) {
    transforms.add('c_fit')
    transforms.add(`w_${options.width * scale}`)
  }
  if (options?.height) {
    transforms.add('c_fit')
    transforms.add(`h_${options.height * scale}`)
  }
  let tKey = Array.from(transforms).join(',')
  if (tKey) {
    tKey += '/'
  }
  return `https://res.cloudinary.com/geniuslink/image/fetch/${tKey}f_auto/${url}`
}
