import {
  observable,
  observableArray,
  computed
} from '~/components/utils'
import $ from 'jquery'
import { ProductDetails } from './product-details'
import { BasicReportingRow } from './basic-reporting-row'

export class ReportingDataTab {
  /** @todo Define types */
  constructor(tabName, clickData, commData, epcData, convData) {
    const self = this
    self.name = observable(tabName)
    self.dataStatus = observable(0)
    self.clicks = observableArray(clickData)
    self.comms = observableArray(commData)
    self.epc = observableArray(epcData)
    self.conv = observableArray(convData)

    self.totalClicks = observable(0)
    self.totalComms = observable(0)

    self.errorTitle = observable('')
    self.errorMessage = observable('')

    self.CLICK_STRING = 'clicks'
    self.COMM_STRING = 'comms'
    self.CONV_STRING = 'conv'

    // use a computed to display the correct one of these.
    self.clicksArrayForPie = observableArray()
    self.commissionArrayForPie = observableArray()
    self.clicksArrayForPieWithoutBots = observableArray()

    self.hidePieChartsBots = observable(true)

    self.clicksPie = computed(function() {
      if (self.hidePieChartsBots()) {
        return self.clicksArrayForPieWithoutBots()
      } else {
        return self.clicksArrayForPie()
      }
    })

    self.dataForFilter = function(columnNum) {
      switch (columnNum) {
        case 0:
          return self.clicks()
        case 1:
          return self.comms()
        case 2:
          return self.epc()
        case 3:
          return self.conv()
      };
      return []
    }

    self.clearAllData = function() {
      self.clicks.removeAll()
      self.comms.removeAll()
      self.epc.removeAll()
      self.conv.removeAll()
      self.clicksArrayForPie([])
      self.commissionArrayForPie([])
    }

    self.updateClickPieData = function(rawClicksArray) {
      console.log('update clicks pie data')
      const pieClicksArray = self.filterPieData(rawClicksArray, self.totalClicks(), self.CLICK_STRING)
      self.clicksArrayForPie(pieClicksArray)
    }

    self.updateCommPieData = function(rawCommsArray) {
      const pieCommArray = self.filterPieData(rawCommsArray, self.totalComms(), self.COMM_STRING)
      self.commissionArrayForPie(pieCommArray)
    }

    // use this instead of "CreateDataRowsFromDataWithOptionForLink" followed by "updateData"
    // TODO used by report builder, should be used by performane report
    self.update = function(results) {
      const clickCommEpcConvArraysData = self.createDataRowsFromDataWithOptionForLink(results, '')
      self.clicks(clickCommEpcConvArraysData[0])
      self.comms(clickCommEpcConvArraysData[1])
      self.epc(clickCommEpcConvArraysData[2])
      self.conv(clickCommEpcConvArraysData[3])
      self.updateClickPieData(results[0])
      self.updateCommPieData(results[1])
    }
    // TODO used by performance report, but should move to using update() above
    self.updateData = function(clickData2, commEpcConvData, pieClicksArray, pieCommArray, pieClicksArrayWithoutBots) {
      self.clicks(clickData2)
      self.comms(commEpcConvData[0])
      self.epc(commEpcConvData[1])
      self.conv(commEpcConvData[2])
      self.clicksArrayForPie(pieClicksArray)
      self.commissionArrayForPie(pieCommArray)
      self.clicksArrayForPieWithoutBots(pieClicksArrayWithoutBots)
    }

    // just used for report builder, not performance report (yet!)
    self.createDataRowsFromDataWithOptionForLink = function(dataArrays, linkBase) {
      const rowsArray = [[], [], [], []]
      let totalClicks = 0
      let totalComms = 0

      $(dataArrays).each(function(index, array) {
        $(array).each(function(index1, item) {
          // just when looping through clicks array, get totals
          if (index === 0) {
            totalClicks += item.value.clicks
            totalComms += item.value.commissionForConsolidated
          }

          if (item.key === 'Other') {
            linkBase = ''
          }
          const linkString = linkBase !== '' ? linkBase + item.key : linkBase

          const detail = new ProductDetails('')
          detail.summaryLink(linkString)

          if (!item.value.previousPeriod) {
            item.value.previousPeriod = {
              clicks: 0
            }
          }
          rowsArray[index].push(
            new BasicReportingRow(item.key, item.value, '', '', detail))
        })
      })

      self.totalClicks(totalClicks)
      self.totalComms(totalComms)

      return rowsArray
    }
  }
}
