<script setup lang="ts">
import { type Space, space as spaceTokens } from '~/components/ui/tokens'
import { computed } from 'vue'

/** @todo - need more layout tests to validate how this will work */

const props = withDefaults(
  defineProps<{
    space?: Space
    minWidth?: string
    alignItems?: 'start' | 'center' | 'end' | 'stretch'
    wrap?: boolean
  }>(), {
    space: 'gap10',
    minWidth: '0',
    alignItems: 'center',
    wrap: false
  }
)

const wrapValue = computed(() => props.wrap ? 'wrap' : 'nowrap')

const spaceValue = computed(
  () =>
    props.space in spaceTokens
      ? (spaceTokens as any)[props.space]
      : props.space
)
</script>

<template>
  <div class="flexbox">
    <slot />
  </div>
</template>

<style scoped>
.flexbox {
  display: flex;
  gap: v-bind(spaceValue);
  align-items: v-bind(alignItems);
  flex-wrap: v-bind(wrapValue);
  :deep(> *) {
    flex: 1;
  }
}
</style>
