import {
  type PreviewFields,
  type DestinationOptions,
  type DestinationDetails,
  type LinksListItem
} from './links-v4'
import { getCurrentScope, reactive } from 'vue'

export function getDestinationDefault(linkOptions: DestinationOptions): DestinationDetails {
  switch (linkOptions.type) {
    case 'SIMPLE':
      return linkOptions.destination
    case 'AB_SPLIT':
      return linkOptions.destinations[0].destination
    case 'CHOICE_PAGE': {
      const dests = linkOptions.destinations
      return (dests.find(dest => {
        return dest.destination.retailer?.name.toUpperCase().includes('AMAZON')
          ?? dest.destination.retailer?.name.toUpperCase().includes('APPLE')
      }) ?? dests[0]).destination
    }
  }
}

export function getPreviewForLinkOptions(
  linkOptions: DestinationOptions,
  defaultDestination?: DestinationDetails
): PreviewFields {
  const destination = defaultDestination ?? getDestinationDefault(linkOptions)
  const hasScope = Boolean(getCurrentScope())
  const isChoicePage = linkOptions.type === 'CHOICE_PAGE'
  let previewIconUrl = isChoicePage
    ? destination.images?.button?.url ?? destination.retailer?.images?.logo.url
    : destination.retailer?.images?.logo.url
  /** These paths returned from the server have no value. */
  if (previewIconUrl?.includes('image_unavailable')) {
    previewIconUrl = undefined
  }
  const previewIcon = previewIconUrl
    ? { url: previewIconUrl }
    : undefined
  const previewTitle = linkOptions.title ?? destination.title ?? 'Unknown title'
  const previewSubtitle = destination.subtitle
  let previewImageUrl = isChoicePage
    ? linkOptions?.images?.product?.url ?? destination.images?.default?.url
    : destination.images?.default?.url
  /** These paths returned from the server have no value. */
  if (previewImageUrl?.includes('image_unavailable')) {
    previewImageUrl = undefined
  }
  const previewImage = previewImageUrl
    ? { url: previewImageUrl }
    : undefined
  const previewUrl = isChoicePage
    ? undefined
    : destination.url

  const preview: PreviewFields = {
    previewIcon,
    previewTitle,
    previewSubtitle,
    previewUrl,
    previewImage
  }

  if (hasScope) {
    /** @todo get any fields not resolved */
    return reactive(preview)
  }

  return preview
}

export function getShortLinkWithPreview<T extends LinksListItem>(link: T): T & PreviewFields {
  return {
    ...link,
    ...getPreviewForLinkOptions(link.linkOptions)
  }
}
