<script setup lang="ts">
import { Feature, Session } from '~/state'
import { computed, reactive } from 'vue'
import { type TrackingMeta } from '~/models/links-v4'
import Field from '~/components/ui/Field.vue'
import EditableDropdown from '~/components/ui/EditableDropdown.vue'
import Card from '~/components/ui/Card.vue'
import Stack from '~/components/ui/layout/Stack.vue'

import { presetGroupName, presetReferrerUrl, presetShorturlCode, presetUsername } from '~/models'

import Tag from 'primevue/tag'

defineProps<{}>()

const tags = defineModel<TrackingMeta>({
  default: reactive({
    utmSource: '',
    utmMedium: '',
    utmCampaign: '',
    utmTerm: '',
    utmContent: ''
  })
})

const hasFeatureUtm = computed(() =>
  Session.HasFeature(Feature.UtmAnalytics)
)
</script>
<template>
  <Card padding="gap14">
    <p>
      Tags let you attach campaign info to your traffic data so you can compare ad performance.
      <span v-if="!hasFeatureUtm">
        <a
          href="/account/plans"
          target="_blank"
        >Upgrade your account</a> to apply these tags as UTM codes for use with Google Analytics.
      </span>
    </p>

    <p v-if="hasFeatureUtm">
      <strong>Tags will also be applied as UTM codes for links going to <a
        target="_blank"
        href="/tools#utm-section"
      >domains you specify.</a></strong>
    </p>

    <Stack class="tag-fields">
      <Field label="Source (tracking tag)">
        <template #details>
          <Tag v-if="tags.utmSource && tags.utmSource.charAt(0) !== '{'" value="Custom" />
        </template>
        <EditableDropdown
          v-model="tags.utmSource"
          placeholder="e.g. 'google', 'newsletter'"
          :options="[
            presetGroupName,
            presetReferrerUrl
          ]"
          data-testId="Tags__source"
        />
      </Field>

      <Field label="Medium">
        <template #details>
          <Tag v-if="tags.utmMedium && tags.utmMedium.charAt(0) !== '{'" value="Custom" />
        </template>
        <EditableDropdown
          v-model="tags.utmMedium"
          placeholder="e.g. 'email', 'banner'"
          :options="[
            presetGroupName,
            presetShorturlCode
          ]"
          data-testId="Tags__medium"
        />
      </Field>

      <Field label="Campaign Name">
        <template #details>
          <Tag v-if="tags.utmCampaign && tags.utmCampaign.charAt(0) !== '{'" value="Custom" />
        </template>
        <EditableDropdown
          v-model="tags.utmCampaign"
          placeholder="e.g. 'spring_sale'"
          :options="[
            presetGroupName,
            presetUsername
          ]"
          data-testId="Tags__campaignName"
        />
      </Field>

      <Field label="Term">
        <template #details>
          <Tag v-if="tags.utmTerm && tags.utmTerm.charAt(0) !== '{'" value="Custom" />
        </template>
        <EditableDropdown
          v-model="tags.utmTerm"
          placeholder="e.g. 'running_shoes'"
          :options="[
            presetShorturlCode
          ]"
          data-testId="Tags__term"
        />
      </Field>

      <Field label="Content">
        <template #details>
          <Tag v-if="tags.utmContent && tags.utmContent.charAt(0) !== '{'" value="Custom" />
        </template>
        <EditableDropdown
          v-model="tags.utmContent"
          placeholder="e.g. '100x100_ad'"
          :options="[
            presetGroupName,
            presetShorturlCode
          ]"
          data-testId="Tags__content"
        />
      </Field>
    </Stack>
  </Card>
</template>

<style scoped>
.tag-fields {
  margin-top: 2rem;
  max-width: 280px;
  margin-left: 1rem;
  margin-right: auto;
}
</style>
