<script setup lang="ts">
/**
 * Renders small retailer image based on Url,
 * which may be empty.
 */
import CloudImage from '~/components/ui/CloudImage.vue'
import ImagePlaceholder from '~/components/ui/ImagePlaceholder.vue'
import { watch } from 'vue'
import { useClientPromise } from '~/utils'
import { getRetailerUrl } from './helpers/product'

const props = defineProps<{
  productUrl?: string | undefined
}>()

const emit = defineEmits<{
  update: []
}>()

const retailImageUrl = defineModel<string>('retailImageUrl', { default: '' })
const loading = defineModel<boolean>('loading', { default: false })

watch(() => props.productUrl, async () => {
  const retailUrl = retailImageUrl.value
  const productUrl = props.productUrl
  if (!retailUrl && productUrl) {
    loading.value = true
    const [, result] = await useClientPromise('/api/v1/default-button-image', {
      query: {
        Url: getRetailerUrl(productUrl)
      }
    })
    loading.value = false
    if (result) {
      emit('update')
      retailImageUrl.value = result.image
    }
  }
}, { immediate: true })
</script>

<template>
  <div class="retailer-image">
    <CloudImage
      v-if="retailImageUrl"
      :src="retailImageUrl"
      :width="85"
      :height="38"
    />
    <ImagePlaceholder v-else />
  </div>
</template>

<style scoped>
/** @todo - wrap rendering above this? */
.retailer-image {
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  width: 85px;
  height: 2.25rem;
  max-width: 100%;
  max-height: 100%;
}
</style>
