<script setup lang="ts">
import { computed } from 'vue'
import type { LinkType } from '~/components/LinkEditor/helpers'

import ALink from '~/components/ui/ALink.vue'
import CloudImage from '~/components/ui/CloudImage.vue'
import ImagePlaceholder from '~/components/ui/ImagePlaceholder.vue'
import RetailerImage from '~/components/LinkEditor/RetailerImage.vue'

import IconAB from '~/assets/images/ab2.svg'

const props = defineProps<{
  imageSrc?: string
  productUrl?: string
  retailImageUrl?: string
  linkType: LinkType
  title?: string
  href?: string
}>()

const Wrapper = computed(() => {
  return props.href ? ALink : 'div'
})
</script>

<template>
  <component
    :is="Wrapper"
    class="image"
    :class="{ 'no-image': !imageSrc }"
    :href
    :title
  >
    <CloudImage
      v-if="imageSrc"
      :src="imageSrc"
      :width="75"
      :height="83"
    />
    <!-- TODO - convert this to an img link instead of embedding -->
    <ImagePlaceholder v-else class="product-placeholder" />
    <div
      class="retailer"
      :class="linkType.toLowerCase()"
    >
      <span v-if="linkType === 'CHOICE_PAGE'">
        Choice
      </span>
      <span v-else-if="linkType === 'AB_SPLIT'">
        <IconAB />
        A/B
      </span>
      <div v-else-if="productUrl" class="retailer-inner">
        <RetailerImage :productUrl :retailImageUrl />
      </div>
    </div>
  </component>
</template>

<style scoped>
.image {
  display: inline-flex;
  flex-direction: column;
  border: 1px solid var(--surface-300);
  border-radius: 6px;
  width: 75px;
  align-items: center;
  background: white;
  text-decoration: none;
  &.no-image {
    background: var(--surface-50);
  }
  > img {
    width: 100%;
    height: 70px;
    object-fit: contain;
    margin: 0 auto;
    border-radius: 6px 6px 0 0;
  }
  &:hover {
    text-decoration: none;
  }
}
.retailer {
  align-self: stretch;
  display: block;
  height: 24px;
  border-top: 1px solid var(--surface-300);
  background: white;
  border-radius: 0 0 6px 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  color: var(--text-color);
  font-size: 0.8571rem;
  font-weight: bold;
}
.choice_page, .ab_split {
  color: white;
  background: #2B2B33;
  position: relative;
}
.choice_page::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-image: url('~/assets/images/rainbow-stripes2.svg');
  background-size: auto 24px;
  background-position: right;
  background-repeat: no-repeat;
  transform: rotate(180deg);
}
.retailer-inner {
  width: 63px;
  height: 27px;
}
.product-placeholder {
  width: 75%;
}
</style>
