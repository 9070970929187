<script setup lang="ts">
import { type PartialProduct } from '~/models/products'
import RetailerImage from './RetailerImage.vue'
import { getImageUrl } from '~/components/utils'
import IconOpenInNew from '@material-symbols/svg-600/outlined/open_in_new.svg'
import Checkbox from '~/components/ui/Checkbox.vue'

defineProps<{
  product: PartialProduct | undefined
  defaultLabel?: string
  selectable?: boolean
}>()

const checked = defineModel<boolean>('checked', { default: false })
</script>

<template>
  <div :class="{ 'product-entry': true, selectable }" v-if="product">
    <div class="select" v-if="selectable">
      <Checkbox v-model="checked" />
    </div>
    <div class="product-image">
      <RetailerImage
        :modelValue="product.retailerImages.Logo ?? product.retailerImages.Banner"
        :productUrl="product.productUrl"
        class="product-image"
      />
    </div>
    <img :src="getImageUrl(product.productImages.Large ?? product.productImages.Medium ?? product.productImages.Small)" class="product-image" />
    <div class="option">
      <div>
        {{ product.title }}
      </div>
      <div><strong v-if="product.displayPrice">{{ product.displayPrice }}</strong></div>
    </div>
    <div class="actions">
      <a
        :href="product.productUrl"
        target="_blank"
        @click.stop
        title="Visit page..."
      >
        <IconOpenInNew />
      </a>
    </div>
  </div>
  <div v-else class="product-entry">
    {{ defaultLabel }}
  </div>
</template>

<style scoped>
.product-entry {
  display: grid;
  grid-template-columns: auto auto 1fr min-content;
  padding: 0.5rem;
  gap: 0.5rem;
  align-items: center;
  cursor: pointer;
  background: var(--surface-0);
  &.selectable {
    grid-template-columns: min-content auto auto 1fr min-content;
  }
}
.product-image {
  width: 85px;
  height: 65px;
  object-fit: contain;
  object-position: center;
}
.option {
  max-width: 64ch;
}
.actions {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  padding-right: 0.5rem;
}
</style>
