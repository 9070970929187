// import ko from '~/components/utils'
import { PricingPlan } from './PricingPlan'
import {
  PricingPlanConstants
} from './Constants'
import { withCommas } from './utils/numericText'

export const GeniuslinkPricingPlans = {
  plans:
    [
      // UGH
      // TODO should "custom" plan be laid out here? at least for messaging, and controling up/downgrade, plan change page display, etc..
      new PricingPlan(PricingPlanConstants.starterId, PricingPlanConstants.starter, PricingPlanConstants.starterCost, PricingPlanConstants.starterClicks, PricingPlanConstants.starterFreeClicks, PricingPlanConstants.starterCostPerBucket, PricingPlanConstants.starterClicksPerBucket, PricingPlanConstants.baseIncluded, PricingPlanConstants.baseExcluded, true, false),
      new PricingPlan(PricingPlanConstants.freeThenFiveId, PricingPlanConstants.freethenfive, PricingPlanConstants.freethenfiveCost, PricingPlanConstants.freethenfiveClicks, PricingPlanConstants.freethenfiveFreeClicks, PricingPlanConstants.freethenfiveCostPerBucket, PricingPlanConstants.freethenfiveClicksPerBucket, PricingPlanConstants.baseIncluded, PricingPlanConstants.baseExcluded, true, false),
      new PricingPlan(PricingPlanConstants.plusId, PricingPlanConstants.plus, PricingPlanConstants.plusCost, PricingPlanConstants.plusClicks, PricingPlanConstants.plusFreeClicks, PricingPlanConstants.plusCostPerBucket, PricingPlanConstants.plusClicksPerBucket, PricingPlanConstants.plusIncluded, '', false, true),
      new PricingPlan(PricingPlanConstants.powerId, PricingPlanConstants.power, PricingPlanConstants.powerCost, PricingPlanConstants.powerClicks, PricingPlanConstants.powerFreeClicks, PricingPlanConstants.powerCostPerBucket, PricingPlanConstants.powerClicksPerBucket, PricingPlanConstants.powerIncluded, '', false, true),
      new PricingPlan(PricingPlanConstants.twoforonekId, PricingPlanConstants.twoforonek, PricingPlanConstants.twoforonekCost, PricingPlanConstants.twoforonekClicks, PricingPlanConstants.twoforonekFreeClicks, PricingPlanConstants.twoforonekCostPerBucket, PricingPlanConstants.twoforonekClicksPerBucket, '', '', true, false),
      new PricingPlan(PricingPlanConstants.corenewId, PricingPlanConstants.core, PricingPlanConstants.coreCost, PricingPlanConstants.coreClicks, PricingPlanConstants.coreFreeClicks, PricingPlanConstants.coreCostPerBucket, PricingPlanConstants.coreClicksPerBucket, PricingPlanConstants.baseIncluded, PricingPlanConstants.baseExcluded, true, false),
      new PricingPlan(PricingPlanConstants.billedId, PricingPlanConstants.billed, PricingPlanConstants.billedCost, PricingPlanConstants.billedClicks, PricingPlanConstants.billedFreeClicks, PricingPlanConstants.billedCostPerBucket, PricingPlanConstants.billedClicksPerBucket, PricingPlanConstants.baseIncluded, PricingPlanConstants.baseExcluded, true, false),
      new PricingPlan(PricingPlanConstants.business2018Id, PricingPlanConstants.business2018, PricingPlanConstants.business2018Cost, PricingPlanConstants.business2018Clicks, PricingPlanConstants.business2018FreeClicks, PricingPlanConstants.business2018CostPerBucket, PricingPlanConstants.business2018ClicksPerBucket, '', '', false, false),
      new PricingPlan(PricingPlanConstants.fivefortwoId, PricingPlanConstants.fivefortwo, PricingPlanConstants.fivefortwoCost, PricingPlanConstants.fivefortwoClicks, PricingPlanConstants.fivefortwoFreeClicks, PricingPlanConstants.fivefortwoCostPerBucket, PricingPlanConstants.fivefortwoClicksPerBucket, '', '', true, false),
      new PricingPlan(PricingPlanConstants.nineforfourptfiveId, PricingPlanConstants.nineforfourptfive, PricingPlanConstants.nineforfourptfiveCost, PricingPlanConstants.nineforfourptfiveClicks, PricingPlanConstants.nineforfourptfiveFreeClicks, PricingPlanConstants.nineforfourptfiveCostPerBucket, PricingPlanConstants.nineforfourptfiveClicksPerBucket, '', '', false, false),
      new PricingPlan(PricingPlanConstants.nineforfiveId, PricingPlanConstants.nineforfive, PricingPlanConstants.nineforfiveCost, PricingPlanConstants.nineforfiveClicks, PricingPlanConstants.nineforfiveFreeClicks, PricingPlanConstants.nineforfiveCostPerBucket, PricingPlanConstants.nineforfiveClicksPerBucket, '', '', false, false),
      new PricingPlan(PricingPlanConstants.fivefortwowithtwoforoneId, PricingPlanConstants.fivefortwowithtwoforone, PricingPlanConstants.fivefortwowithtwoforoneCost, PricingPlanConstants.fivefortwowithtwoforoneClicks, PricingPlanConstants.fivefortwowithtwoforoneClicks, PricingPlanConstants.fivefortwowithtwoforoneCostPerBucket, PricingPlanConstants.fivefortwowithtwoforoneClicksPerBucket, '', '', false, false)

    ],

  // TODO update this when change over currently offered plan!
  getCurrentBasePlan() {
    const plan = GeniuslinkPricingPlans.plans.filter((plan: any) => {
      return plan.id() === PricingPlanConstants.fivefortwowithtwoforoneId
    })[0]
    return plan
  },

  getPlan(planId: string) {
    let plan = GeniuslinkPricingPlans.plans.filter((plan: any) => {
      return plan.id() === planId
    })[0]

    if (plan === undefined) {
      plan = new PricingPlan('unknown', 'Legacy', 0, 0, 0, 0, 0, '', '', true, true)
    }

    return plan
  },

  planIsLegacy(planId: string) {
    const plan = GeniuslinkPricingPlans.getPlan(planId)
    return plan.isLegacy()
  },

  // note: are users on this plan are (dis)/allowed to move themselves to the base plan currently being offered
  promptUpgradeToNewBasePlan(planId: string) {
    const plan = GeniuslinkPricingPlans.getPlan(planId)
    return plan.promptUpgradeToNewBasePlan()
  },

  // note: may want to add constraints here in addition to being able to upgrade
  promptUpgradeToNewBasePlanInAccountClosureDeflection(planId: string) {
    return GeniuslinkPricingPlans.promptUpgradeToNewBasePlan(planId)
  },

  hidePlanChangePageForPlan(planId: string) {
    const plan = GeniuslinkPricingPlans.getPlan(planId)
    return plan.hidePlanChangePage()
  },

  // TODO can we remove this?
  clickPerBucketCost(planId: string) {
    const plan = GeniuslinkPricingPlans.getPlan(planId)
    return '$' + plan.costPerBucket() + '/' + withCommas(plan.clicksPerBucket())
  }
}
