<script setup lang="ts">
import {
  type Options
} from 'highcharts'
import { Chart } from 'highcharts-vue'
import Spinner from '~/components/ui/Spinner.vue'
import Skeleton from 'primevue/skeleton'
import Button from '~/components/ui/Button.vue'

withDefaults(defineProps<{
  minHeight?: string
  loading?: boolean
  validating?: boolean
  empty?: boolean
  constructorType?: string
}>(), {
  minHeight: '383px'
})

defineEmits<{
  createLink: []
}>()

const options = defineModel<Options>({
  default: {}
})
</script>

<template>
  <div class="chart-frame" :style="{ minHeight }">
    <div class="content" :data-testid="`ChartTemplate__divChart`">
      <slot name="header" />
      <Skeleton v-if="loading" style="height: 100%" />
      <template v-else-if="!empty">
        <slot name="chart">
          <Chart
            :options
            :class="{ validating }"
            :constructorType
            :data-testid="`ChartTemplate__chart`"
          />
        </slot>
        <slot />
      </template>
      <div class="empty" v-else>
        <p>No click data to report, yet.</p>
        <p>
          <Button variant="link" @click="$emit('createLink')">
            Create a new link
          </Button> and publish it to generate traffic.
        </p>
      </div>
    </div>
    <Spinner v-if="validating" class="spinner" />
  </div>
</template>

<style scoped>
.validating {
  opacity: 0.5;
}
.chart-frame {
  min-height: v-bind(minHeight);
  background: var(--card-bg);
  border: 1px solid var(--card-border);
  position: relative;
  display: grid;
  border-radius: 8px;
  > * {
    min-width: 0;
  }
}
.content {
  display: flex;
  flex-direction: column;
}
.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.empty {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 1rem;
  padding-bottom: 4rem;
  text-align: center;
  color: var(--text-seondary);
  p {
    margin: 0;
  }
}
</style>
