<script setup lang="ts">
import { ref, computed } from 'vue'
import Dropdown from 'primevue/dropdown'

const props = withDefaults(defineProps<{
  options: Array<{
    value: string
    name: string
    disabled?: boolean
  }>
  newLabel?: string
  required?: boolean
}>(), {
  newLabel: '* New...'
})

const emit = defineEmits<{
  change: [{ value: string, isNew: boolean }]
}>()

const selectedItem = defineModel<string>({ required: true })

/** Dropdown can set to `null` which we want to disallow */
const internalSelected = computed({
  get() {
    return selectedItem.value
  },
  set(value: string | null) {
    selectedItem.value = value ?? ''
  }
})

const dropdown = ref<Dropdown>()

const newValue = computed(() => {
  const data = props.options
  const selected = selectedItem.value

  let name: string
  if (data && selected && data.findIndex(item => item.name === selected) === -1) {
    name = selected
  } else {
    name = ''
  }
  /** Emit change as side effect from computing new */
  emit('change', { value: selected, isNew: Boolean(name) })
  return name
})

const dynamicOptions = computed(() => {
  const value = newValue.value
  if (!value || props.options.findIndex(item => item.value === value) !== -1) {
    return props.options
  }
  return [
    { name: value, value },
    ...props.options
  ]
})

let currentTime = new Date().getTime()
const handleChange = (data: any) => {
  currentTime = new Date().getTime()
}
const handleFocus = () => {
  const newTime = new Date().getTime()
  if ((newTime - currentTime) > 100) {
    dropdown.value!.show()
  }
}
</script>

<template>
  <Dropdown
    ref="dropdown"
    class="editable-dropdown"
    editable
    v-model="internalSelected"
    :options="dynamicOptions"
    optionValue="value"
    optionLabel="name"
    optionDisabled="disabled"
    :showClear="Boolean(internalSelected)"
    :invalid="required && !selectedItem"
    @focus="handleFocus"
    @change="handleChange"
  />
</template>

<style scoped>
.editable-dropdown {
  width: 100%;
  flex: 1;
}
</style>
