<script setup lang="ts">
import {
  type SystemPreset,
  type OverrideOptions
} from '~/models/overrides'
import {
  type Condition,
  type DateCondition,
  type OsCondition
} from '~/models/links-v4'
import { ref, watch } from 'vue'
import { Tippy } from 'tippy.vue'

import Button from '~/components/ui/Button.vue'
import Stack from '~/components/ui/layout/Stack.vue'
import Menu from 'primevue/menu'
import Calendar from 'primevue/calendar'
import SelectButton from 'primevue/selectbutton'
import AutoComplete from 'primevue/autocomplete'
import RadioButton from 'primevue/radiobutton'

import IconArrowBack from '@material-symbols/svg-600/outlined/arrow_back_ios_new.svg'
import IconHelp from '@material-symbols/svg-600/outlined/help.svg'

import { TransitionSlide } from '@morev/vue-transitions'
import { createReusableTemplate } from '@vueuse/core'
import { type CountryInfo } from '~/models/countries'
import LazyLanguageSelector from './LazyLanguageSelector.vue'

const [DefineHeader, UseHeader] = createReusableTemplate<{
  label: string
  backCommand?: () => void
}>()

defineProps<{
  options: OverrideOptions
  countryOptions: CountryInfo[]
  ifValue: boolean
}>()

type PropSlideOffsetValue = InstanceType<typeof TransitionSlide>['offset']

const emit = defineEmits<{
  update: []
  add: [condition: Condition]
}>()

const today = new Date()
const nowUtc = new Date(
  today.getUTCFullYear(),
  today.getUTCMonth(),
  today.getUTCDate(),
  today.getUTCHours(),
  today.getUTCMinutes(),
  today.getUTCSeconds()
)

const minDate = new Date(
  today.getUTCFullYear(),
  today.getUTCMonth(),
  today.getUTCDate(),
  0,
  0,
  0
)

const data = ref({
  date: {
    operator: '<' as DateCondition['operator'],
    day: nowUtc,
    time: nowUtc
  },
  os: {
    operator: '<' as Exclude<OsCondition['options'], undefined>['operator'],
    value: '',
    version: {
      major: 0,
      minor: 0,
      displayName: ''
    }
  },
  region: {
    isCountry: true,
    countryValue: {} as CountryInfo,
    regionValue: {} as SystemPreset
  },
  language: {
    name: undefined as string | undefined,
    code: undefined as string | undefined
  }
})

type Sections =
  'DATE'
  | 'BROWSER'
  | 'OS'
  | 'DEVICE'
  | 'COUNTRY'
  | 'REGION'
  | 'LANGUAGE'

interface NormalizedVersion {
  major: number
  minor: number
  displayName: string
}

const activeSection = ref<Sections>()
const activeVersions = ref<NormalizedVersion[]>()
const filteredVersions = ref<NormalizedVersion[]>([])
const filteredCountries = ref<CountryInfo[]>([])
const filteredRegions = ref<SystemPreset[]>([])

watch(activeSection, () => {
  emit('update')
})

const offset = ref<PropSlideOffsetValue>({
  enter: ['100%', 0],
  leave: ['-100%', 0]
})

function goForward(func: () => void) {
  offset.value = {
    enter: ['100%', 0],
    leave: ['-100%', 0]
  }
  func()
}

function goBack(func: () => void) {
  offset.value = {
    enter: ['-100%', 0],
    leave: ['100%', 0]
  }
  func()
}

const menuItems = [
  { label: 'Date...', command: () => goForward(() => activeSection.value = 'DATE') },
  { label: 'Web browser...', command: () => goForward(() => activeSection.value = 'BROWSER') },
  { label: 'OS...', command: () => goForward(() => activeSection.value = 'OS') },
  { label: 'Device type...', command: () => goForward(() => activeSection.value = 'DEVICE') },
  { label: 'Country...', command: () => goForward(() => activeSection.value = 'COUNTRY') },
  { label: 'Region...', command: () => goForward(() => activeSection.value = 'REGION') },
  { label: 'Language...', command: () => goForward(() => activeSection.value = 'LANGUAGE') }
]

const getDate = () => {
  const day = data.value.date.day
  const time = data.value.date.time

  return new Date(
    day.getFullYear(),
    day.getMonth(),
    day.getDate(),
    time.getHours(),
    time.getMinutes(),
    time.getSeconds()
  ).toISOString()
}
</script>

<template>
  <!-- Header template -->
  <DefineHeader
    v-slot="{
      label,
      backCommand = () => (activeSection as any) = undefined
    }"
  >
    <header>
      <Button
        rounded
        plain
        size="small"
        @click="goBack(backCommand)"
      >
        <template #icon>
          <IconArrowBack fill="var(--primary-color)" />
        </template>
      </Button>
      {{ label }}
    </header>
  </DefineHeader>

  <div class="panels">
    <TransitionSlide
      :offset
      group
      tag="section"
    >
      <section v-if="!activeSection">
        <main>
          <Menu :model="menuItems" />
        </main>
      </section>

      <section v-if="activeSection === 'DATE'">
        <UseHeader label="Date..." />
        <Stack is="main">
          <SelectButton
            :options="[{
              label: 'Before',
              value: '<'
            }, {
              label: 'On / After',
              value: '>='
            }]"
            optionLabel="label"
            optionValue="value"
            v-model="data.date.operator"
            :data-testid="data.date.operator === '<' ? 'FilterPanel__beforeBtn' : 'FilterPanel__onAfterBtn'"
          />
          <label>Date (UTC)</label>
          <Calendar
            showIcon
            iconDisplay="input"
            v-model="data.date.day"
            dateFormat="yy-mm-dd"
            placeholder="e.g. 2022-12-31"
            :minDate
            :pt:header:data-testid="`FilterPanel__dateUTCHeader`"
            :pt:input:data-testid="`FilterPanel__dateUTCInput`"
            :pt:list:data-testid="`FilterPanel__dateUTCList`"
            :pt:root:data-testid="`FilterPanel__dateUTCroot`"
          />
          <label>Time (UTC)</label>
          <Calendar
            showIcon
            timeOnly
            iconDisplay="input"
            v-model="data.date.time"
            placeholder="e.g. 22:30"
            :pt:header:data-testid="`FilterPanel__timeUTCHeader`"
            :pt:input:data-testid="`FilterPanel__timeUTCInput`"
            :pt:list:data-testid="`FilterPanel__timeUTCList`"
            :pt:root:data-testid="`FilterPanel__timeUTCroot`"
          />
          <div class="buttons">
            <Button
              size="small"
              @click="() => $emit('add', {
                type: 'DATE',
                value: getDate(),
                if: ifValue,
                operator: data.date.operator,
              })"
              data-testid = "FilterPanel_dateAddBtn"
            >
              Add
            </Button>
          </div>
        </Stack>
      </section>

      <section v-if="activeSection === 'BROWSER'">
        <UseHeader label="Web browser..." />
        <main>
          <Menu
            :model="options.browserOptions.map(({ displayName: label, name }) => ({
              label,
              command: () =>
                emit('add', {
                  type: 'BROWSER',
                  value: name,
                  if: ifValue
                })
            }))"
          />
        </main>
      </section>

      <template v-if="activeSection === 'OS'">
        <section v-if="!activeVersions">
          <UseHeader label="OS..." />
          <main>
            <Menu
              :model="options.osOptions.map(({ displayName: label, name: value, versions }) => ({
                label: versions ? `${label}...` : label,
                command: () => {
                  if (!versions) {
                    emit('add', {
                      type: 'OS',
                      value,
                      if: ifValue
                    })
                  } else {
                    data.os.value = value
                    data.os.version = {
                      major: 0,
                      minor: 0,
                      displayName: ''
                    }
                    data.os.operator = '>='
                    // data.os.displayName = label
                    goForward(() => activeVersions = filteredVersions = versions.map(({ major, minor, displayName }) => ({
                      major: parseInt(major, 10),
                      minor: minor ? parseInt(minor, 10) : 0,
                      displayName
                    })))
                  }
                }
              }))"
            />
          </main>
        </section>

        <section v-else>
          <UseHeader label="OS..." :backCommand="() => goBack(() => activeVersions = undefined)" />
          <AutoComplete
            :suggestions="filteredVersions"
            v-model="data.os.version"
            optionLabel="displayName"
            forceSelection
            dropdown
            @complete="event => {
              if (event.query) {
                filteredVersions = activeVersions!
                  .map(({ major, minor, displayName }) => ({
                    major,
                    minor: minor ?? 0,
                    displayName
                  }))
                  .filter(({ displayName }) => displayName.toLowerCase().includes(event.query.toLowerCase()))
              } else {
                filteredVersions = [...activeVersions!]
              }
            }"
          />
          <div class="radios">
            <RadioButton
              v-model="data.os.operator"
              name="osOp"
              value=">="
              inputId="option1"
              checked
            />
            <label for="option1">Or higher</label>
            <RadioButton
              v-model="data.os.operator"
              name="osOp"
              value="<"
              inputId="option2"
            />
            <label for="option2">Or lower</label>
            <RadioButton
              v-model="data.os.operator"
              name="osOp"
              value="="
              inputId="option3"
            />
            <label for="option3">Exactly</label>
          </div>

          <div class="buttons">
            <Button
              size="small"
              :disabled="!data.os.version.major"
              @click="() => $emit('add', {
                type: 'OS',
                value: data.os.value,
                if: ifValue,
                options: {
                  operator: data.os.operator,
                  major: data.os.version.major,
                  minor: data.os.version.minor
                }
              })"
            >
              Add
            </Button>
          </div>
        </section>
      </template>

      <template v-if="activeSection === 'DEVICE'">
        <UseHeader label="Device type..." />
        <main>
          <Menu
            :model="options.deviceOptions.map(({ displayName: label, name }) => ({
              label,
              command: () =>
                emit('add', {
                  type: 'DEVICE',
                  value: name,
                  if: ifValue
                })
            }))"
          />
        </main>
      </template>

      <template v-if="activeSection === 'COUNTRY'">
        <UseHeader label="Country..." />
        <main>
          <AutoComplete
            :suggestions="filteredCountries"
            v-model="data.region.countryValue"
            optionLabel="name"
            forceSelection
            dropdown
            @complete="event => {
              if (event.query) {
                filteredCountries = countryOptions
                  .filter(({ name }) => name.toLowerCase().includes(event.query.toLowerCase()))
              } else {
                filteredCountries = [...countryOptions!]
              }
            }"
          />
          <div class="buttons">
            <Button
              size="small"
              :disabled="!data.region.countryValue?.name"
              @click="() => $emit('add', {
                type: 'COUNTRY',
                value: data.region.countryValue!.name,
                if: ifValue,
                meta: {
                  isoCode: data.region.countryValue!.iso2
                }
              })"
            >
              Add
            </Button>
          </div>
        </main>
      </template>

      <template v-if="activeSection === 'REGION'">
        <UseHeader label="Region..." />
        <main>
          <AutoComplete
            :suggestions="filteredRegions"
            v-model="data.region.regionValue"
            optionLabel="displayName"
            forceSelection
            dropdown
            @complete="event => {
              if (event.query) {
                filteredRegions = options.systemPresetOptions
                  .filter(({ name }) => name.toLowerCase().includes(event.query.toLowerCase()))
              } else {
                filteredRegions = [...options.systemPresetOptions]
              }
            }"
          />
          <div class="buttons">
            <Button
              size="small"
              :disabled="!data.region.regionValue?.name"
              @click="() => $emit('add', {
                type: 'REGION',
                value: data.region.regionValue!.name,
                if: ifValue
              })"
            >
              Add
            </Button>
          </div>
        </main>
      </template>

      <template v-if="activeSection === 'LANGUAGE'">
        <UseHeader label="Language..." />

        <main>
          <LazyLanguageSelector v-model="data.language" />
          <div class="buttons">
            <Button
              size="small"
              :disabled="!data.language.name"
              @click="() => $emit('add', {
                type: 'LANGUAGE',
                value: data.language.name!,
                if: ifValue,
                meta: {
                  isoCode: data.language.code!
                }
              })"
            >
              Add
            </Button>
            <Button
              style="justify-self: flex-end"
              variant="secondary"
              v-tooltip
            >
              <template #icon>
                <IconHelp />
              </template>
              Help
            </Button>
            <Tippy trigger="focus click">
              <p>
                Select or search a browser-supported language or use any <a target="_blank" href="https://iso639-3.sil.org/code_tables/639/data">
                  ISO 639</a> 2-digit or 3-digit language code followed by a colon and the language name.
              </p>
              <p>
                (Note: languages not supported by a browser may not be detectable.)
              </p>
            </Tippy>
          </div>
        </main>
      </template>
    </TransitionSlide>
  </div>
</template>

<style scoped>
.panels {
  overflow: hidden;
  width: 230px;
  height: 300px;
  position: relative;
}
section {
  position: relative;
  width: 230px;
  height: 300px;
}
section header {
  color: var(--text-color-secondary);
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  height: 36px;
  border-bottom: 1px solid var(--surface-100);
  background-color: var(--surface-0);
}
.buttons {
  display: grid;
  grid-auto-flow: column;
  direction: rtl;
  justify-items: start;
  > * {
    direction: ltr;
  }
  gap: 1rem;
  padding-top: 10px;
  border-top: 1px solid var(--surface-100);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--surface-0);
}
main {
  padding-top: 1rem;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
}
header + main {
  top: 36px;
}
.radios {
  display: grid;
  grid-template-columns: min-content 1fr;
  gap: 0.5rem;
  padding: 0.5rem;
  padding-top: 1rem;
}
</style>
