<script setup lang="ts">
import { useClient } from '~/utils'
import { reactive } from 'vue'
import {
  type Rule
} from '~/models/links-v4'

import AdvancedRuleRow from './AdvancedRuleRow.vue'
import Button from '~/components/ui/Button.vue'
import IconAdd from '@material-symbols/svg-600/outlined/add.svg'
import Stack from '~/components/ui/layout/Stack.vue'

/** Assign keys to unique objects to track elements for transitions */
let key = 0
const keyMap = new WeakMap<Rule, string>()

const {
  data: options
} = useClient('/api/v1/link/override/settings')

const {
  data: countryOptions
} = useClient('/api/v1/genius/countryOptions')

const rules = defineModel<Rule[]>({ default: reactive([]) })

const addRule = () => {
  rules.value.push({
    linkOptions: {
      type: 'SIMPLE',
      destination: {
        url: ''
      }
    },
    conditions: []
  })
}

const getKey = (rule: Rule) => {
  if (!keyMap.has(rule)) {
    keyMap.set(rule, `${key++}`)
  }
  return keyMap.get(rule)
}
</script>

<template>
  <Stack v-if="options && countryOptions" is="section">
    <h3>Rules</h3>
    <TransitionGroup
      tag="div"
      name="list"
      class="rules-list"
      data-testid="AdvancedRules__transitionGroup"
    >
      <template v-for="(rule, index) in rules" :key="getKey(rule)">
        <AdvancedRuleRow
          :options
          :countryOptions
          v-model="rules[index]"
          :first="index === 0"
          :last="index === rules.length - 1"
          @moveup="() => rules.splice(index - 1, 0, rules.splice(index, 1)[0])"
          @movedown="() => rules.splice(index + 1, 0, rules.splice(index, 1)[0])"
          @delete="() => rules.splice(index, 1)"
        />
      </template>
    </TransitionGroup>
    <Button
      @click="addRule"
      size="small"
      variant="secondary"
      class="add-btn"
      data-testid="AdvancedRules__addARuleBtn"
    >
      <template #icon>
        <IconAdd />
      </template>
      Add a Rule
    </Button>
  </Stack>
</template>

<style>
.list-move, /* apply transition to moving elements */
.list-enter-active,
.list-leave-active {
  transition: all 300ms ease;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateY(-30px);
}

/* ensure leaving items are taken out of layout flow so that moving
   animations can be calculated correctly. */
.list-leave-active {
  position: absolute;
}
</style>

<style scoped>
.add-btn {
  align-self: flex-end;
}
.rules-list {
  display: grid;
  gap: 1rem;
}
</style>
