import { FeaturePreferences } from './FeaturePreferences'
import { type ChoicePageTheme } from '~js/ChoicePageTheme'

export class ChoicePageThemePreferences extends FeaturePreferences {
  public static readonly Light = 'light'
  public static readonly DefaultTheme = ChoicePageThemePreferences.Light
  public static readonly Dark = 'dark'
  public static readonly SupportedThemes = [ChoicePageThemePreferences.Light, ChoicePageThemePreferences.Dark]

  constructor(public default_theme: ChoicePageTheme) {
    super()
  }
}
