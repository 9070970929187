import {
  type Observable,
  type Computed,
  observable,
  computed
} from '~/components/utils'
import {
  PricingPlanConstants
} from './Constants'
import { withCommas } from './utils/numericText'

export interface IPricingPlan {
  id: Observable<string>
  name: Observable<string>
  costPerMonth: Observable<number>
  displayPricing: Computed<string>
  includedFeatures: Observable<string>
  excludedFeatures: Observable<string>
  hasExcludedFeatures: Computed<boolean>
  isLegacy: Computed<boolean>
  freeClicks: Observable<number>
  clicksIncluded: Computed<string>
  costPerBucket: Observable<number>
  clicksPerBucket: Observable<number>
  promptUpgradeToNewBasePlan: Observable<boolean>
  hidePlanChangePage: Observable<boolean>
  clickPerBucketCost: Computed<string>
  fullCostDescription: Observable<string>
}

export class PricingPlan implements IPricingPlan {
  public id: Observable<string>
  public name: Observable<string>
  public costPerMonth: Observable<number>
  public displayPricing: Computed<string>
  public clicks: Observable<number>
  public freeClicks: Observable<number>
  public includedFeatures: Observable<string>
  public excludedFeatures: Observable<string>
  public hasExcludedFeatures: Computed<boolean>
  public isLegacy: Computed<boolean> // defined as: new user cannot currently sign up for this plan
  public clicksIncluded: Computed<string>
  public costPerBucket: Observable<number>
  public clicksPerBucket: Observable<number>
  public promptUpgradeToNewBasePlan: Observable<boolean> // show current instead of latest available plan switch choice, even if legacy
  public hidePlanChangePage: Observable<boolean> // hide link to page to change plan (for one-off or test plans where user should not be given choice to change)
  public clickPerBucketCost: Computed<string>
  public fullCostDescription: Observable<string>

  constructor(
    id: string,
    name: string,
    costPerMonth: number,
    clicks: number,
    freeClicks: number,
    costPerBucket: number,
    clicksPerBucket: number,
    includedFeatures: string,
    excludedFeatures: string,
    promptUpgradeToNewBasePlan: boolean,
    hidePlanChangePage: boolean
  ) {
    this.id = observable(id)
    this.name = observable(name)
    this.costPerMonth = observable(costPerMonth)

    this.clicks = observable(clicks)
    this.freeClicks = observable(freeClicks)
    this.includedFeatures = observable(includedFeatures)
    this.excludedFeatures = observable(excludedFeatures)
    this.costPerBucket = observable(costPerBucket)
    this.clicksPerBucket = observable(clicksPerBucket)
    this.promptUpgradeToNewBasePlan = observable(promptUpgradeToNewBasePlan)
    this.hidePlanChangePage = observable(hidePlanChangePage)
    this.fullCostDescription = observable('')

    this.hasExcludedFeatures = computed((): boolean => {
      return this.excludedFeatures() !== '' && this.excludedFeatures() != null
    })
    this.isLegacy = computed((): boolean => {
      return (this.id() !== PricingPlanConstants.fivefortwoId && this.id() !== PricingPlanConstants.fivefortwowithtwoforoneId && this.id() !== PricingPlanConstants.nineforfiveId && this.id() !== PricingPlanConstants.nineforfourptfiveId && this.id() !== PricingPlanConstants.business2018Id)
    })
    this.clicksIncluded = computed((): string => {
      let message = ''
      const formattedClicks = withCommas(this.freeClicks())
      if (this.id() === PricingPlanConstants.billedId || this.id() === PricingPlanConstants.freeThenFiveId) {
        message = 'Free up to ' + formattedClicks + ' clicks'
      } else if (this.freeClicks() > 0) { // don't display if included clicks is zero.
        message = 'Includes ' + formattedClicks + ' clicks'
      }
      return message
    })

    // TODO want to use GeniuslinkPricingPlan.fullCostDescription instead.. but really i think this code just doesn't belong here
    this.displayPricing = computed((): string => {
      return `/mo. + $${this.costPerBucket()}/${withCommas(this.clicksPerBucket())} clicks`
    })

    this.clickPerBucketCost = computed((): string => {
      return '$' + this.costPerBucket() + '/' + withCommas(this.clicksPerBucket())
    })

    // incorporate fixed cost and volume based cost descriptions
    if (this.costPerMonth() >= 0 && this.costPerMonth() !== this.costPerBucket()) {
      this.fullCostDescription(`$${this.costPerMonth()}/mo. + ${this.clickPerBucketCost()} clicks`)
    } else {
      this.fullCostDescription(`${this.clickPerBucketCost()} clicks`)
    }
  }
}
