<script setup lang="ts">
import InputNumber from 'primevue/inputnumber'
import { reactive } from 'vue'
import Button from '~/components/ui/Button.vue'
// import Big from 'big.js'
import round from 'lodash/round'
import { getDefaults } from 'valibot'
import { model } from 'vue-observables'
import { createValidator } from 'vue-observables/adapters/valibot'

import {
  type ABSplitOptions,
  ABSplitOptionsSchema,
  type DestinationDetails
} from '~/models/links-v4'
import { getKey } from './helpers'
import ProductTextBox from './ProductTextBox.vue'

import IconDelete from '@material-symbols/svg-600/outlined/delete.svg'
import IconAdd from '@material-symbols/svg-600/outlined/add.svg'

export interface NumberSplitDestination {
  destination: DestinationDetails
  percentage: number
}

const options = defineModel<ABSplitOptions>({
  default: reactive({
    ...getDefaults(ABSplitOptionsSchema),
    destinations: [{
      isDefault: true,
      destination: {
        url: ''
      },
      percentage: '100'
    }]
  }) satisfies ABSplitOptions
})
const vm = model<ABSplitOptions>(options, {
  validator: createValidator(ABSplitOptionsSchema)
})

const destinations = vm('destinations')

const addDestination = () => {
  destinations.value.push({ destination: { url: '' }, percentage: '0' })
}

const balancePercentages = (updatedValue: number, dest: ABSplitOptions['destinations'][0], index: number) => {
  if (!updatedValue) {
    updatedValue = 0
  }
  const currentValue = Number(dest.percentage)
  if (updatedValue === currentValue || destinations.value.length === 1) {
    return
  }

  /** Remaining value to split up once we've updated */
  const remaining = 100 - updatedValue
  const needed = updatedValue - currentValue

  /**
   * If there are lines lower than this one, and they have enough
   * of a sum to take from, only take from those, for better UX.
   */
  const canTakeFromLowerDestinations = (destinations.value.reduce((acc, d, i) => {
    if (i > index) {
      acc += Number(d.percentage)
    }
    return acc
  }, 0)) >= needed

  let sum = 0
  let total = 0

  /** Values after this one */
  const otherDestinations = canTakeFromLowerDestinations && index < destinations.value.length - 1
    ? destinations.value.filter((dest, i) => {
      if (i !== index) {
        sum += Number(dest.percentage)
      }
      if (i < index) {
        total += Number(dest.percentage)
      }
      return i > index
    })
    /** Any value other than this one */
    : destinations.value.filter((d) => {
      if (d !== dest) {
        sum += Number(d.percentage)
        return true
      }
      return false
    })
  /** Sum of all values that are not this one */

  const last = otherDestinations.length - 1

  /** Get the total sum of the percentages */
  otherDestinations.forEach((d, i) => {
    if (i === last) {
      /** Sanity check, always end up at remaining value */
      d.percentage = String(round(remaining - total, 2))
      return
    }
    const per = Number(d.percentage)
    const p = sum !== 0 ? per / sum : per
    const newValue = round(remaining * p, 0)
    d.percentage = String(newValue)
    total += newValue
  })
  dest.percentage = String(updatedValue)
}

const deleteItem = (dest: ABSplitOptions['destinations'][0]) => {
  const index = destinations.value.indexOf(dest)
  balancePercentages(0, dest, index)
  destinations.value.splice(index, 1)
}
</script>

<template>
  <section>
    <span>Url</span>
    <span style="grid-column: span 2">Split %</span>
    <template v-for="(dest, index) in destinations.value" :key="getKey(dest)">
      <div>
        <ProductTextBox v-model="dest.destination" />
      </div>
      <div>
        <InputNumber
          :modelValue="Number(dest.percentage)"
          @update:modelValue="balancePercentages($event, dest, index)"
          showButtons
          :maxFractionDigits="2"
          :min="0"
          :max="100"
          :step="1"
          suffix="%"
          plain
          :data-testid="`SplitLink__destinationPercentage-${index}`"
        />
      </div>
      <div class="btn-column">
        <Button
          v-if="index !== 0"
          plain
          rounded
          @click="deleteItem(dest)"
          :data-testid="`SplitLink__deletButton-${index}`"
        >
          <template #icon>
            <IconDelete />
          </template>
        </Button>
      </div>
    </template>
    <div style="grid-column: span 2; justify-self: end">
      <Button
        @click="addDestination"
        size="small"
        plain
        data-testid="SplitLink__addDestinationBtn"
      >
        <template #icon>
          <IconAdd />
        </template>
        Add another destination
      </Button>
    </div>
  </section>

</template>

<style scoped>
section {
  display: grid;
  grid-template-columns: 1fr minmax(min-content, 7rem) min-content;
  gap: 0.5rem;
}
label {
  font-weight: bold;
}
.btn-column {
  width: 32px;
}
</style>
