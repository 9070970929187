<script setup lang="ts">
import Stack from '~/components/ui/layout/Stack.vue'
import Button from '~/components/ui/Button.vue'
import Field from '~/components/ui/Field.vue'
import CloudImage from '~/components/ui/CloudImage.vue'
import { type Image } from '~/models/links-v4'
import { type SuccessEventInfo } from './helpers'
import UploadWidget from './UploadWidget.vue'

withDefaults(defineProps<{
  title: string
  defaultImageUrl?: string
  preset?: 'button' | 'product' | 'logo'
}>(), {
  preset: 'button'
})

const image = defineModel<Image>({ default: { url: '' } })

const onSuccess = (info: SuccessEventInfo) => {
  image.value = {
    url: info.secure_url,
    provider: 'CLOUDINARY',
    providerId: info.public_id,
    version: String(info.version)
  }
}
</script>

<template>
  <div class="feature-image">
    <UploadWidget v-slot="{ click, disabled }" @success="onSuccess" :preset>
      <Button
        :class="['image','link', defaultImageUrl && 'show-default']"
        @click="click"
        :disabled
        variant="secondary"
      >
        <CloudImage v-if="image.url" :src="image.url" :width="100" />
        <CloudImage
          v-else-if="defaultImageUrl"
          :src="defaultImageUrl"
          :width="100"
          class="default"
        />
        <span class="empty" v-else>
          No image selected
        </span>
      </Button>
      <Stack class="action">
        <Field :label="title">
          <Button variant="secondary" :disabled @click="click">
            Change...
          </Button>
        </Field>
        <Button variant="link" @click="image.url = ''" :disabled="!image.url">
          Remove image
        </Button>
      </Stack>
    </UploadWidget>
  </div>
</template>

<style scoped>
.feature-image {
  display: grid;
  grid-template-columns: 100px 1fr;
  gap: 1rem;
}

.image {
  background-color: var(--surface-200);
  color: var(--surface-400);
  width: 100px;
  min-height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  text-decoration: none;
  border-color: var(--surface-200);
  .empty {
    font-size: 0.95rem;
    text-align: center;
    line-height: 1.2;
  }
  &.show-default::after {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    content: 'Default';
    text-align: center;
    font-weight: bold;
    background: var(--translucent-surface-900);
    color: var(--surface-0);
  }
}
</style>
