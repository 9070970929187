<script setup lang="ts">
/**
 * This component allows you to paste a URL or search for a product by name.
 * It returns product details data to the host component.
 */
import { useClient } from '~/utils'
// import { UrlSchema } from '~/models/links'
import Spinner from '~/components/ui/Spinner.vue'
import { ref, computed, watch } from 'vue'
import { type Product, type PartialProduct } from '~/models/products'
import { type DestinationDetails } from '~/models/links-v4'

import IconField from 'primevue/iconfield'
import InputIcon from 'primevue/inputicon'
import InputText from 'primevue/inputtext'
import Menu from 'primevue/menu'
import { type MenuItem } from 'primevue/menuitem'
import { type PartialDeep } from 'type-fest'
import ProductRow from './ProductRow.vue'
import merge from 'lodash/merge'
import { useCloned, refDebounced } from '@vueuse/core'

const props = withDefaults(defineProps<{
  required?: boolean
}>(), {
  required: false
})

const destination = defineModel<DestinationDetails>({ default: { url: '' } })

/**
 * @note Maddeningly, the product APIs provide different capabilities and
 * different data depending on use case. So we have to normalize the data
 * and use different endpoints to cobble together one data set.
 *
 * @example
 * `/v3/product-suggestions` - This provides retailer logos and
 *   detailed product information if keywords are provided, and supposedly can
 *   accept an Amazon URL, except it does not accept any pasted Amazon URL,
 *   only URLs (AFAICT) that were returned in the specific format from this API.
 *   It also says it accepts retailerGuids, but this does not work. So, if you
 *   wanted to paste an Amazon URL and get detailed product info, this will
 *   not help you.
 *
 * `/api/v1/shorturl/preview-product-details` - This WILL accept any Amazon URL,
 *   but does not provide retailer logos or detailed product information.
 *   In addition, the product info is in a wildly different format. Moreover,
 *   in the above product suggestions API, author & manufacturer are separated.
 *   In this API, there is logic on the BE that combines / chooses the product
 *   "display lines". So, after getting product information from suggestions in
 *   the above API, all of that information must be immediately discarded and
 *   metadata retrieved again from this API just to get formatting.
 *
 * `/api/v1/default-button-image` - This provides logos for retailers. Coupled
 *   with the preview-product-details product details API, we sort of have a complete
 *   glimpse of the product.
 */

type ProductSuggestionItem = MenuItem & {
  product?: PartialProduct
}

const { cloned: pendingProductDetails } = useCloned<DestinationDetails | undefined>(destination)

const suggestions = ref<ProductSuggestionItem[]>([])
const errorMessage = ref('')
const inputValue = computed({
  get: () => destination.value.url,
  set: (value) => {
    destination.value.url = value
  }
})
const inputEntryValue = ref('')
const debouncedInputValue = refDebounced(inputValue, 500)
const menu = ref<Menu | null>(null)
const input = ref<InputText | null>(null)

const urlRegex = /^https?:/i

const productSuggestionQuery = computed(() => {
  let query = debouncedInputValue.value
  if (!query) {
    return null
  }
  query = query.trim()
  if (!/^https?:/i.test(query)) {
    return {
      keywords: query
      // This doesn't actually work
      // retailerGuids: 'ed2bed8b86ea48f482db15e213f7fe88' // Amazon
    }
  }
  return null
})

const {
  data: suggestionData,
  loading: suggestionLoading,
  validating: suggestionValidating
} = useClient('/v3/product-suggestions', {
  query: productSuggestionQuery
})

watch(suggestionData, (newValue) => {
  if (newValue) {
    if (newValue.length) {
      suggestions.value = newValue.map((product: PartialProduct) => ({
        label: product.title,
        product,
        command: () => {
          const productImageUrl = product.productImages.Large ?? product.productImages.Medium ?? product.productImages.Small
          pendingProductDetails.value = merge(pendingProductDetails.value, {
            retailer: {
              name: product.retailer,
              images: {
                logo: {
                  url: product.retailerImages.Logo ?? product.retailerImages.Banner
                }
              }
            },
            url: product.productUrl,
            images: {
              ...(productImageUrl ? { default: { url: productImageUrl } } : {})
            }
          })
          inputValue.value = product.productUrl
          suggestions.value = []
          menu.value?.hide()
        }
      }))
    } else {
      suggestions.value = [{
        label: 'No results found',
        disabled: true
      }]
    }
    const el = (input.value as any)?.$el
    /** Internally, PrimeVue uses `currentTarget` to align position */
    menu.value?.show({ currentTarget: el } as Event)
  }
})

const productDetailsQuery = computed(() => {
  let query = debouncedInputValue.value
  if (!query) {
    return null
  }
  query = query.trim()
  if (urlRegex.test(query)) {
    return {
      url: query
    }
  }
  return null
})

const {
  data: detailsData,
  loading: detailsLoading,
  validating: detailsValidating
} = useClient('/api/v1/shorturl/preview-product-details', {
  query: productDetailsQuery
})

const buttonImageLoading = ref(false)
const loading = computed(() =>
  buttonImageLoading.value
  || detailsLoading.value
  || suggestionLoading.value
  || suggestionValidating.value
  || detailsValidating.value
)

watch(
  detailsData,
  async (data) => {
    if (data) {
      const productDetails: PartialDeep<DestinationDetails> = pendingProductDetails.value
        ? {
            ...pendingProductDetails.value,
            retailer: {
              name: undefined,
              ...pendingProductDetails.value.retailer
            }
          }
        : {
            retailer: {
              name: undefined,
              images: {
                logo: {}
              }
            }
          }
      pendingProductDetails.value = undefined
      productDetails.title = data.productDisplayName1
      productDetails.subtitle = data.productDisplayName2
      productDetails.retailer!.name ??= data.advertiser
      productDetails.url ??= inputValue.value
      /**
       * @note Eventually we want to replace the URL with a canonical one, maybe?
       */
      // productDetails.url ??= data.productUrl
      const productImageUrl = data.productArtworkThumbnailUrl150 ?? data.productArtworkThumbnailUrl100
      if (productImageUrl) {
        productDetails.images = {
          default: {
            url: productImageUrl
          }
        }
      }
      suggestions.value = [
        {
          product: {
            title: data.productDisplayName1,
            retailer: data.advertiser,
            productUrl: data.productUrl,
            productImages: {
              ...(data.productArtworkThumbnailUrl150 ? { Large: data.productArtworkThumbnailUrl150 } : {}),
              ...(data.productArtworkThumbnailUrl100 ? { Medium: data.productArtworkThumbnailUrl100 } : {}),
              ...(data.productArtworkThumbnailUrl ? { Small: data.productArtworkThumbnailUrl } : {})
            } as Product['productImages'],
            retailerImages: {}
          }
        }
      ]
      destination.value = productDetails as DestinationDetails
    }
  })

const checkRequired = () => {
  if (props.required && inputValue.value.trim() === '') {
    errorMessage.value = 'This field is required'
  } else {
    errorMessage.value = ''
  }
}
const checkBlur = () => {
  const required = props.required
  let value = inputValue.value.trim()
  const entryValue = inputEntryValue.value
  /**
   * If we're exiting without a URL, and we started with a URL, reset to the URL.
   * This is because the product entry box doubles as a search box.
   */
  if (!urlRegex.test(value) && (!required || !value)) {
    value = inputValue.value = entryValue
    errorMessage.value = ''
    return
  }
  /** There was no entry value, and this is required, so its invalid. */
  if (required && !urlRegex.test(value)) {
    errorMessage.value = 'URL is required'
  } else {
    errorMessage.value = ''
  }
}

const storeEntryValue = () => {
  inputEntryValue.value = inputValue.value.trim()
}
</script>

<template>
  <IconField>
    <InputIcon>
      <Spinner v-if="loading" />
    </InputIcon>
    <InputText
      ref="input"
      v-model="inputValue"
      :invalid="Boolean(errorMessage)"
      @dblclick="($event: Event) => menu?.show($event)"
      placeholder="Paste a URL or find a product by name"
      :title="modelValue?.title ? `${modelValue.title}${modelValue?.subtitle ? ', ' + modelValue.subtitle : ''}` : undefined"
      @change="checkRequired"
      @focus="storeEntryValue"
      @blur="checkBlur"
      data-testid="ProductTextBox__input"
    />
    <Menu
      ref="menu"
      class="product-suggestion-menu"
      :model="suggestions"
      :popup="true"
    >
      <template #item="{ item }: { item: ProductSuggestionItem }">
        <ProductRow :product="item.product" :defaultLabel="(item.label as string)" />
      </template>
    </Menu>
  </IconField>
</template>

<style>
.product-suggestion-menu {
  max-height: 300px;
  overflow: auto;
}
</style>

<style scoped>
.title {
  font-size: 0.9rem;
  padding-left: 0.375rem;
}
</style>
