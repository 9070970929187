<script setup lang="ts">
import { ALL_RETAILERS } from '~/components/SelectSearchRetailer/SelectRetailerDropdownVM'
import { useClient, dayjs } from '~/utils'
import { computed, ref, reactive, watchEffect } from 'vue'
import { calculateReturnByResolutionString } from '~/scripts/basic-reporting-input'
import ChartTemplate from './ChartTemplate.vue'
import InputSwitch from 'primevue/inputswitch'
import { type ClickDataByResolutionResponse } from '~/models/reports'
import IconTrendingDown from '@material-symbols/svg-600/outlined/trending_down.svg'
import IconTrendingUp from '@material-symbols/svg-600/outlined/trending_up.svg'
import round from 'lodash/round'
import Tag from 'primevue/tag'
import { numberWithCommas } from '~/scripts/pretty-printing'

const props = defineProps<{
  /** Dates in local time */
  startDate: Date
  endDate: Date
  retailerId: string
  hideBots: boolean
  shortCode?: string
  groupId?: string
  options?: Options
}>()

const startUtcDate = computed(() => dayjs(props.startDate).utc())
const endUtcDate = computed(() => dayjs(props.endDate).utc())
const startUtcDateString = computed(() => startUtcDate.value.format('YYYY-MM-DD'))
const endUtcDateString = computed(() => endUtcDate.value.format('YYYY-MM-DD'))

const retailerId = computed(() => props.retailerId === ALL_RETAILERS ? undefined : props.retailerId)
const resolution = computed(() => calculateReturnByResolutionString(props.startDate, props.endDate))

const doPreviousPeriod = ref(false)
const doPreviousPeriodBinary = computed(() => doPreviousPeriod.value ? 1 : 0)

let apiUrl:
  '/v1/reports/click-trend-by-resolution'
  | '/v1/reports/link-click-trend-by-resolution'
  | '/v1/reports/group-click-trend-by-resolution' = '/v1/reports/click-trend-by-resolution'

const query: Record<string, any> = {
  start: startUtcDateString,
  end: endUtcDateString,
  retailerid: retailerId,
  resolution,
  dopreviousperiod: doPreviousPeriodBinary
}

if (props.shortCode) {
  apiUrl = '/v1/reports/link-click-trend-by-resolution'
  query.shortcode = props.shortCode
} else if (props.groupId) {
  apiUrl = '/v1/reports/group-click-trend-by-resolution'
  /** Whether this field is called `tsid` or `groupid` is very inconsistent */
  query.groupid = props.groupId
}

const {
  data,
  loading,
  validating
} = useClient(apiUrl, { query })

const empty = computed(() => {
  if (data.value?.clicksByDate && data.value.clicksByDate.length === 0) {
    return true
  }
  return false
})

/**
 * Gets chart data and totals in one pass
 */
function getAggregateData(rawData: ClickDataByResolutionResponse['clicksByDate']) {
  let total = 0
  const clicksProp = props.hideBots ? 'clicksMinusBot' : 'clicks'
  const data: PointOptionsObject[] = []

  /**
   * Start / end dates in milliseconds
   * @note - This assumes the dates are arriving in chronological order!
   */
  const start = new Date(rawData[0].value.date).getTime()
  const end = new Date(rawData[rawData.length - 1].value.date).getTime()

  for (const point of rawData) {
    total += point.value[clicksProp]
    data.push({
      x: point.key.jsDate,
      y: point.value[clicksProp],
      custom: {
        actualDate: (new Date(point.value.date).getTime())
      }
    })
  }
  return {
    total,
    data,
    start,
    end
  }
}

const clicksByDate = reactive({
  total: 0,
  start: 0,
  end: 0,
  data: [] as PointOptionsObject[]
})
watchEffect(() => {
  if (data.value?.clicksByDate.length) {
    merge(clicksByDate, getAggregateData(data.value.clicksByDate))
  }
})

const clicksByDatePP = reactive({
  total: 0,
  start: 0,
  end: 0,
  data: [] as PointOptionsObject[]
})

watchEffect(() => {
  if (data.value?.clicksByDatePP?.length) {
    merge(clicksByDatePP, getAggregateData(data.value.clicksByDatePP))
  }
})

const chartTotal = computed(() => {
  if (!doPreviousPeriod.value) {
    return numberWithCommas(clicksByDate.total)
  }
  return numberWithCommas(clicksByDate.total + clicksByDatePP.total)
})

const netGain = computed(() => {
  if (doPreviousPeriod.value && clicksByDatePP.total !== 0) {
    return round(((clicksByDatePP.total - clicksByDate.total) / clicksByDatePP.total) * 100)
  }
  return 0
})

const chartOptions = computed<Options>(() => {
  let tooltipDateFormat: string
  switch (resolution.value) {
    case 'hour':
      tooltipDateFormat = '%e %b %Y %H:%M'
      break
    case 'day':
      tooltipDateFormat = '%e %b %Y'
      break
    case 'month':
      tooltipDateFormat = '%b %Y'
      break
  }
  if (data.value) {
    let options = merge({}, defaultOptions, {
      tooltip: {
        headerFormat: `{point.x: ${tooltipDateFormat}}<br>`
      },
      legend: {
        labelFormat: doPreviousPeriod.value && clicksByDate.start
          ? `{abbrNum options.custom.total} {name}{#if options.custom.start}<br>{options.custom.start:${tooltipDateFormat}} - {options.custom.end:${tooltipDateFormat}}{/if}`
          : '{abbrNum options.custom.total} {name}'
      },
      series: [
        {
          type: 'area',
          name: doPreviousPeriod.value ? 'Current Clicks' : 'Clicks',
          data: clicksByDate.data,
          custom: {
            start: clicksByDate.start,
            end: clicksByDate.end,
            total: clicksByDate.total
          }
        },
        ...(
          data.value.clicksByDatePP
            ? [{
                type: 'area',
                name: 'Previous Clicks',
                data: clicksByDatePP.data,
                tooltip: {
                  headerFormat: `{point.point.custom.actualDate: ${tooltipDateFormat}}<br>`
                },
                custom: {
                  start: clicksByDatePP.start,
                  end: clicksByDatePP.end,
                  total: clicksByDatePP.total
                }
              }] as const
            : []
        )
      ] satisfies Options['series']
    })
    if (props.options) {
      options = merge(options, props.options)
    }
    return options
  }
  return defaultOptions
})
defineEmits<{
  createLink: []
}>()
</script>

<script lang="ts">
import Highcharts, {
  type Options,
  type PointOptionsObject
} from 'highcharts'
import merge from 'lodash/merge'
import { textStyle, tooltip } from './common'

/** @todo - For format testing, remove later */
(Highcharts as any).Templating.helpers.log = function() {
  console.log(arguments[0].ctx)
}

const defaultOptions: Options = {
  chart: {
    type: 'area',
    backgroundColor: 'transparent',
    borderColor: '#ff0000',
    borderWidth: 0,
    height: '300',
    spacing: [64, 16, 16, 16],
    ignoreHiddenSeries: false,
    style: textStyle
  },
  colors: [
    '#008BEE',
    '#00B9EEAA'
  ],
  title: {
    text: ''
  },
  xAxis: {
    type: 'datetime',
    dateTimeLabelFormats: {
    // don't display hours
      day: '%e %b',
      hour: ' '
    },
    title: {
      text: ''
    },
    gridLineColor: 'var(--surface-200)',
    lineColor: '#bbbbbb',
    lineWidth: 2,
    tickLength: 4,
    labels: { style: textStyle }

    // TODO add these without breaking graph!,
    // Use min/max to remove the padding on the right/left side of the data.
    // Corresponds with number of x-axis categories, or the values.
    // min: 1,
    // max: 10
  },
  yAxis: {
    min: 0,
    title: {
      text: null
    },
    gridLineColor: 'var(--surface-200)',
    labels: { style: textStyle }
  },
  credits: {
    enabled: false
  },
  exporting: { enabled: false },
  tooltip,
  legend: {
    borderColor: 'transparent',
    borderRadius: 1,
    align: 'right',
    verticalAlign: 'top',
    x: -10,
    y: 0,
    floating: true,
    labelFormat: '{abbrNum options.custom.total} {name}',
    itemStyle: textStyle,
    itemHoverStyle: {
      color: 'var(--text-color)'
    }
  },
  plotOptions: {
    area: {
      fillOpacity: 0.10,
      marker: {
        enabled: false,
        symbol: 'circle',
        radius: 2,
        states: {
          hover: {
            enabled: true
          }
        }
      }
    }
  },
  loading: {
    labelStyle: {
      backgroundColor: 'transparent'
    }
  }
}
</script>

<template>
  <ChartTemplate
    :validating
    :loading
    :empty
    v-model="chartOptions"
    @createLink="$emit('createLink')"
    minHeight="300px"
  >
    <div class="chart-menu">
      <div class="chart-total">
        <h2>Clicks</h2>
        <div>
          <strong>{{ chartTotal }}</strong> total this period
        </div>
      </div>
      <template v-if="doPreviousPeriod && netGain !== 0">
        <Tag v-if="netGain > 0" severity="warning" class="gain">
          Clicks&nbsp;<IconTrendingDown />&nbsp;<strong>{{ netGain }}%</strong>&nbsp;this period
        </Tag>
        <Tag v-else severity="success">
          Clicks&nbsp;<IconTrendingUp />&nbsp;<strong>{{ netGain * -1 }}%</strong>&nbsp;this period
        </Tag>
      </template>
      <label>
        <InputSwitch v-model="doPreviousPeriod" class="do-previous" />
        Overlay previous
      </label>
    </div>
  </ChartTemplate>
</template>

<style scoped>
.chart-menu {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  label {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
}
.chart-total {
  color: var(--text-color);
  display: inline-flex;
  align-items: baseline;
  gap: 1rem;
  border-radius: 0.25rem;
  font-size: 0.95rem;
  > * { margin: 0; }
  strong {
    font-size: 1.5rem;
  }
}
.gain {
  display: inline-flex;
  align-items: center;
  gap: 0.5ch;
}
</style>
