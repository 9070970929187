import {
  type MappedObjectType
} from '~/components/utils'
import { DestinationOverrideSchema, UrlSchema } from './overrides'
import { TagSchema } from './tags'

import {
  number,
  string,
  picklist,
  object,
  transform,
  boolean,
  pipe,
  union,
  literal,
  regex,
  type InferOutput
} from 'valibot'

/** Product details */
export interface Link {
  id: number
  shortUrlCode: string
  hasOverride: boolean
  productUrl: string
  skipAffiliateRedirect: boolean
  createdUtc: string
  updatedUtc: string
  createdUserTime: string
  updatedUserTime: string
  tsid: number
  jsonMetadata: string
  jsonItemMetadata: string
  totalClicks: number
  advertiser: string
  advertiserId: number
  advertiserIconCssClass?: string
  advertiserIcon?: string
  advertiserIconTitle?: string
  retailerId: string
  percentChangePeriodOverPeriod: number
  previousPeriodTotalClicks: number
  isArchived: number
  isArchivedInt: number
  fetchMetadata: boolean
  productDisplayName1: string
  productDisplayName2: string
  productDisplayName3: string
  productArtworkThumbnailUrl: string
  productArtworkThumbnailUrl100: string
  productArtworkThumbnailUrl150?: string
  isGenius: boolean
  applePreference?: number
  linkCreatorSetting: string
}

export interface ProductDetailsResponse {
  isError: boolean
  errorMessage: string
  link: Link
}

export interface CustomDomainData {
  name: string
  allowedDestinationDomains: string[]
  hasValidSslCert?: boolean
}

export const domainMapping = {
  observe: ['name', 'allowedDestinationDomains'],
  ignore: ['owner', 'usersAllowedAccess']
} as const

export type CustomDomain = MappedObjectType<CustomDomainData, typeof domainMapping>

export interface GroupOptionData {
  id: number
  name: string
  shortName: string
  enabled: 0 | 1
}

export interface GroupOption {
  id: number
  name: string
  /** Not really sure how this differs from "name" */
  shortName: string
  /** Assigned by the FE */
  value: string
  disabled?: boolean
  selected?: boolean
}

export const LinkAddOrUpdateRequestSchema = object({
  /** Group Id */
  tsid: number(),
  linkCreatorSetting: picklist(['Simple', 'Split', 'Interstitial', 'Advanced']),
  /** Will validate as DestinationOverride[] before transforming to string */
  overrides: pipe(
    DestinationOverrideSchema,
    transform(obj => JSON.stringify(obj))
  ),
  /**
   * Should this not be an id in case its changed?
   * Instead, it's a copy of the short link URL.
   */
  domain: string(),
  note: string(),
  trackingpixels: string(),
  applepreference: number(),
  affiliationdisabled: boolean()
})

export type LinkAddOrUpdateRequest = InferOutput<typeof LinkAddOrUpdateRequestSchema>

/** Match an empty string or, if not empty, meeting this criteria */
export const vanityRegex = /^$|[a-zA-Z0-9-_]{3,22}$/
// const tagRegex = /^[A-Za-z0-9-_.]{0,45}$/

// const trackingTagSchema = z.string().regex(, {
//   message: 'Enter a valid tracking tag. Up to 45 characters: letters, numbers, hyphens, periods, and underscores are allowed.'
// })

export const LinkAddRequestSchema = object({
  ...LinkAddOrUpdateRequestSchema.entries,
  ...object({
    url: UrlSchema,
    /** tracking tag */
    trackingCode: TagSchema,
    skipAffiliateRedirect: union([literal(0), literal(1)]),
    vanityCode: pipe(
      string(),
      regex(vanityRegex, 'Enter a valid vanity code. Between 3 and 22 characters: letters and numbers are allowed. No spaces or special characters with the exception of hyphens and underscores.')
    ),
    placeholderCode: string(),
    bulkMode: union([literal(0), literal(1)])
  }).entries
})

export type LinkAddRequest = InferOutput<typeof LinkAddRequestSchema>

export const LinkUpdateRequestSchema = object({
  ...LinkAddOrUpdateRequestSchema.entries,
  ...object({
    /**
     * Short url code matching the Domain (e.g., geni.us/{ShortLinkCode}
     * or customdoma.in/{ShortLinkCode}
     *
     * I guess this is the same as vanity code? Why are we sending this again?
     */
    shortUrlCode: string(),
    /** I guess this is trackingCode from above?? */
    trackingTag: TagSchema,
    newUrl: UrlSchema,
    baseCode: string()
  }).entries
})

export type LinkUpdateRequest = InferOutput<typeof LinkUpdateRequestSchema>

interface Alias {
  code: string
  domain: string
  baseCode: string
  lastUpdatedUtc: string
}

export interface LinkInformation {
  id: number
  destinationUrl: string
  shortUrlCode: string
  domain: string
  hasOverride: boolean
  /** The main "url" field when creating a link */
  productUrl: string
  trackingCode: string
  skipAffiliateRedirect: boolean
  createdUtc: string
  updatedUtc: string
  notes: string
  createdUserTime: string
  updatedUserTime: string
  /** Group ID */
  tsid: number
  username: string
  jsonMetadata: string
  jsonItemMetadata: string
  jsonPostProcessingRules: string
  totalClicks: number
  advertiser: string
  advertiserId: number
  retailerId: string
  percentChangePeriodOverPeriod: number
  previousPeriodTotalClicks: number
  isArchived: number
  isArchivedInt: number
  fetchMetadata: boolean
  productDisplayName1: string
  productDisplayName2: string
  productDisplayName3: string
  productArtworkThumbnailUrl: string
  productArtworkThumbnailUrl100: string
  isGenius: boolean
  applePreference: number
  affiliationDisabled: boolean
  aliases: Alias[]
  linkCreatorSetting: string
}

export interface LinksListRequest {
  errorMessage: string
  username: string
  results: LinkInformation[]
}
