import $ from 'jquery'
import { ReportBuilderTable } from './report-wizard.ts'
/** @todo remove and replace with dayjs */
import { DateTime } from 'luxon'
import { dayjs } from '~/utils'
import {
  observable,
  computed,
  type Computed,
  type Observable
} from '~/components/utils'

/** Type things for the horrible fact Date is monkey-patched */
declare const Date: globalThis.Date & {
  new (date: string | globalThis.Date): Date
  parse: (dateString: string) => Date
}

export const defaultFormat = function(date: Date | DateTime) {
  if (!(date instanceof DateTime)) {
    date = DateTime.fromJSDate(date)
  }
  return date.toFormat('MMM d, yyyy')
}

export class BasicReportingInput {
  showPendingForConversion: Computed<number>
  refreshPerformanceData: Computed<void>

  constructor(
    public retailerId: Observable<string>,
    public reportUpdating = observable(false),
    public startDate = observable(''),
    public endDate = observable(''),
    public updateTrigger = observable(true),
    public showPeriodOverPeriod = observable(0),
    public showBots = observable(false),
    public applyFilters = observable(false),
    public tagOverrideIsEnabled = observable(0),
    public tableDataTabs = new ReportBuilderTable()
  ) {
    // save this for rolling out conversion on other platforms
    this.showPendingForConversion = computed(function() {
      return 0
    })

    this.refreshPerformanceData = computed(() => {
      // wipe old data
      this.tableDataTabs.clearAllData()

      this.tableDataTabs.refreshAllTabs(
        this.retailerId(),
        this.startDate(),
        this.endDate(),
        this.showPeriodOverPeriod())
    })

    /** Must be done after mounting */
    // this.setupDatePicker()
    this.getAccountSettings()

    /** Make sure Knockout doesn't take over binding when calling */
    this.setupDatePicker = this.setupDatePicker.bind(this)
  }

  toggleFilters() {
    this.applyFilters(!this.applyFilters())
  }

  forceUpdateTrigger() {
    if (this.applyFilters()) {
      this.updateTrigger(!this.updateTrigger())
    }
  }

  getAccountSettings() {
    void $.getJSON('/api/v1/reports/account-status?', { format: 'json' })
      .fail((xhr, errorType, exception) => {
        // keep on truckin'
      })
      .done((data) => {
        this.tagOverrideIsEnabled(data.hasEnabledTagOverride)
      })
  }

  setupDatePicker() {
    const today = DateTime.now()
    $('#daterange-picker')
      .val('')
      .daterangepicker({
        ranges: {
          Today: ['today', 'today'],
          Yesterday: ['yesterday', 'yesterday'],
          'Past 7 Days': [
            today.plus({ days: -6 }).toJSDate(),
            'today'
          ],
          'Past 30 Days': [
            today.plus({ days: -29 }).toJSDate(),
            'today'
          ],
          'This Month': [
            today.startOf('month').toJSDate(),
            today.endOf('month').toJSDate()
          ],
          'Last Month': [
            today.startOf('month').plus({ months: -1 }).toJSDate(),
            today.startOf('month').plus({ days: -1 }).toJSDate()
          ],
          'This Year': [
            Date.parse('January'), 'today'
          ],
          'Last Year': [
            DateTime.fromJSDate(Date.parse('January')).minus({ years: 1 }).toJSDate(),
            DateTime.fromJSDate(Date.parse('January')).minus({ seconds: 1 }).toJSDate()
          ]
        },
        opens: 'right',
        format: 'MM/dd/yyyy',
        separator: ' to ',
        startDate: today.minus({ days: 29 }).toJSDate(),
        endDate: today.toJSDate(),
        minDate: '01/01/2010',
        maxDate: today.toJSDate(),
        locale: {
          applyLabel: 'Submit Dates',
          fromLabel: 'From',
          toLabel: 'To',
          customRangeLabel: 'Custom Range',
          daysOfWeek: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
          monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
          firstDay: 1
        },
        showWeekNumbers: true,
        buttonClasses: ['btn'],
        dateLimit: false
      },
      (start: Date, end: Date) => {
        $('#daterange-picker-text').text(defaultFormat(start) + ' - ' + defaultFormat(end))
        $('#daterange-picker-startdate').html(defaultFormat(start))
        $('#daterange-picker-enddate').html(defaultFormat(end))

        // TODO should be a way to set this from the UI and avoid doing this
        this.startDate(defaultFormat(start))
        this.endDate(defaultFormat(end))
        // Basically this toggle itself from boolean false to boolean true so that
        // we can figure out when this method has ended
        this.updateTrigger(!this.updateTrigger())
      })

    $('#daterange-picker-text').text('Past 30 Days')
    $('#daterange-picker-startdate').html(defaultFormat(today.minus({ days: 29 })))
    $('#daterange-picker-enddate').html(defaultFormat(today))
  }
}

export function calculateReturnByResolutionString(startDateCopy: string | Date, endDateCopy: string | Date): 'day' | 'hour' | 'month' {
  let returnBy: 'day' | 'hour' | 'month' = 'day'

  const start: Date = new Date(startDateCopy)
  const end: Date = new Date(endDateCopy)

  /** @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/Date#return_value */
  if (!isNaN(start.valueOf()) && !isNaN(end.valueOf())) {
    const startDate = dayjs(start)
    const endDate = dayjs(end)
    if (endDate.toDate() < startDate.add(6, 'day').toDate()) {
      returnBy = 'hour'
    } else if (endDate.toDate() > startDate.add(6, 'month').toDate()) {
      returnBy = 'month'
    }
  }
  return returnBy
}
