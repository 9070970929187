/** Auto-generated by build-languages.mjs */
export default [
  "ab:Abkhazian",
  "ace:Achinese",
  "ach:Acoli",
  "ada:Adangme",
  "ady:Adyghe",
  "aa:Afar",
  "af:Afrikaans",
  "agq:Aghem",
  "ain:Ainu (Japan)",
  "ak:Akan",
  "bss:Akoose",
  "akz:Alabama",
  "sq:Albanian",
  "ale:Aleut",
  "arq:Algerian Arabic",
  "ase:American Sign Language",
  "am:Amharic",
  "anp:Angika",
  "blo:Anii",
  "njo:Ao Naga",
  "ar:Arabic",
  "an:Aragonese",
  "aro:Araona",
  "arp:Arapaho",
  "arw:Arawak",
  "hy:Armenian",
  "frp:Arpitan",
  "as:Assamese",
  "ast:Asturian",
  "asa:Asu (Tanzania)",
  "atj:Atikamekw",
  "cch:Atsam",
  "av:Avaric",
  "awa:Awadhi",
  "ay:Aymara",
  "az:Azerbaijani",
  "bfq:Badaga",
  "ksf:Bafia",
  "bfd:Bafut",
  "bqi:Bakhtiari",
  "blg:Balau",
  "ban:Balinese",
  "bal:Baluchi",
  "bm:Bambara",
  "bax:Bamun",
  "bjn:Banjar",
  "bas:Basa (Cameroon)",
  "ba:Bashkir",
  "eu:Basque",
  "bbc:Batak Toba",
  "bar:Bavarian",
  "bej:Beja",
  "be:Belarusian",
  "bem:Bemba (Zambia)",
  "bez:Bena (Tanzania)",
  "bn:Bengali",
  "bew:Betawi",
  "bho:Bhojpuri",
  "bic:Bikaru",
  "bik:Bikol",
  "byn:Bilin",
  "bin:Bini",
  "bpy:Bishnupriya",
  "bi:Bislama",
  "brx:Bodo (India)",
  "bs:Bosnian",
  "brh:Brahui",
  "bra:Braj",
  "br:Breton",
  "bug:Buginese",
  "bxk:Bukusu",
  "bg:Bulgarian",
  "bum:Bulu (Cameroon)",
  "bua:Buriat",
  "my:Burmese",
  "cad:Caddo",
  "frc:Cajun French",
  "cps:Capiznon",
  "ca:Catalan",
  "cay:Cayuga",
  "ceb:Cebuano",
  "tzm:Central Atlas Tamazight",
  "ayr:Central Aymara",
  "bcl:Central Bikol",
  "lbk:Central Bontok",
  "knc:Central Kanuri",
  "ckb:Central Kurdish",
  "ojc:Central Ojibwa",
  "esu:Central Yupik",
  "shu:Chadian Arabic",
  "ccp:Chakma",
  "cld:Chaldean Neo-Aramaic",
  "ch:Chamorro",
  "ce:Chechen",
  "chr:Cherokee",
  "chy:Cheyenne",
  "cic:Chickasaw",
  "cgg:Chiga",
  "clc:Chilcotin",
  "qug:Chimborazo Highland Quichua",
  "zh:Chinese",
  "chn:Chinook jargon",
  "chp:Chipewyan",
  "cho:Choctaw",
  "chk:Chuukese",
  "cv:Chuvash",
  "swc:Congo Swahili",
  "kw:Cornish",
  "co:Corsican",
  "cr:Cree",
  "mus:Creek",
  "crh:Crimean Tatar",
  "hr:Croatian",
  "quz:Cusco Quechua",
  "cs:Czech",
  "dak:Dakota",
  "da:Danish",
  "dar:Dargwa",
  "prs:Dari",
  "dzg:Dazaga",
  "del:Delaware",
  "dv:Dhivehi",
  "dhd:Dhundari",
  "diq:Dimli (individual language)",
  "din:Dinka",
  "dgo:Dogri (individual language)",
  "doi:Dogri (macrolanguage)",
  "dgr:Dogrib",
  "dua:Duala",
  "nl:Dutch",
  "dyu:Dyula",
  "dz:Dzongkha",
  "ike:Eastern Canadian Inuktitut",
  "frs:Eastern Frisian",
  "emk:Eastern Maninkakan",
  "mhr:Eastern Mari",
  "ojg:Eastern Ojibwa",
  "ydd:Eastern Yiddish",
  "efi:Efik",
  "arz:Egyptian Arabic",
  "eka:Ekajuk",
  "ebu:Embu",
  "egl:Emilian",
  "en:English",
  "myv:Erzya",
  "et:Estonian",
  "ee:Ewe",
  "ewo:Ewondo",
  "ext:Extremaduran",
  "fan:Fang (Equatorial Guinea)",
  "fat:Fanti",
  "gur:Farefare",
  "fo:Faroese",
  "hif:Fiji Hindi",
  "fj:Fijian",
  "fil:Filipino",
  "fi:Finnish",
  "fon:Fon",
  "fr:French",
  "fur:Friulian",
  "ff:Fulah",
  "gaa:Ga",
  "gag:Gagauz",
  "car:Galibi Carib",
  "gl:Galician",
  "gan:Gan Chinese",
  "lg:Ganda",
  "gay:Gayo",
  "gba:Gbaya (Central African Republic)",
  "ka:Georgian",
  "de:German",
  "aln:Gheg Albanian",
  "bbj:Ghomálá'",
  "glk:Gilaki",
  "gil:Gilbertese",
  "gom:Goan Konkani",
  "gon:Gondi",
  "gor:Gorontalo",
  "grb:Grebo",
  "gn:Guarani",
  "gu:Gujarati",
  "guz:Gusii",
  "gwi:Gwichʼin",
  "hai:Haida",
  "ht:Haitian",
  "hak:Hakka Chinese",
  "khk:Halh Mongolian",
  "hur:Halkomelem",
  "bgc:Haryanvi",
  "ha:Hausa",
  "haw:Hawaiian",
  "he:Hebrew",
  "hz:Herero",
  "hil:Hiligaynon",
  "hi:Hindi",
  "ho:Hiri Motu",
  "hmn:Hmong",
  "hnj:Hmong Njua",
  "hu:Hungarian",
  "hup:Hupa",
  "iba:Iban",
  "ibb:Ibibio",
  "is:Icelandic",
  "ig:Igbo",
  "ilo:Iloko",
  "smn:Inari Sami",
  "id:Indonesian",
  "izh:Ingrian",
  "inh:Ingush",
  "moe:Innu",
  "ikt:Inuinnaqtun",
  "iu:Inuktitut",
  "ik:Inupiaq",
  "pes:Iranian Persian",
  "ga:Irish",
  "zai:Isthmus Zapotec",
  "it:Italian",
  "jam:Jamaican Creole English",
  "ja:Japanese",
  "jv:Javanese",
  "kaj:Jju",
  "dyo:Jola-Fonyi",
  "jrb:Judeo-Arabic",
  "aju:Judeo-Moroccan Arabic",
  "jpr:Judeo-Persian",
  "ajt:Judeo-Tunisian Arabic",
  "quc:K'iche'",
  "kbd:Kabardian",
  "kea:Kabuverdianu",
  "kab:Kabyle",
  "kac:Kachin",
  "dtp:Kadazan Dusun",
  "kgp:Kaingang",
  "kkj:Kako",
  "kl:Kalaallisut",
  "kln:Kalenjin",
  "xal:Kalmyk",
  "kam:Kamba (Kenya)",
  "kbl:Kanembu",
  "xnr:Kangri",
  "kn:Kannada",
  "kr:Kanuri",
  "kaa:Kara-Kalpak",
  "krc:Karachay-Balkar",
  "krl:Karelian",
  "ks:Kashmiri",
  "csb:Kashubian",
  "kk:Kazakh",
  "ken:Kenyang",
  "kha:Khasi",
  "km:Khmer",
  "naq:Khoekhoe",
  "khw:Khowar",
  "ki:Kikuyu",
  "kmb:Kimbundu",
  "krj:Kinaray-A",
  "rw:Kinyarwanda",
  "ky:Kirghiz",
  "kiu:Kirmanjki (individual language)",
  "ksh:Kölsch",
  "bkm:Kom (Cameroon)",
  "kv:Komi",
  "koi:Komi-Permyak",
  "kpv:Komi-Zyrian",
  "kg:Kongo",
  "knn:Konkani (individual language)",
  "kok:Konkani (macrolanguage)",
  "kng:Koongo",
  "ko:Korean",
  "kfo:Koro (Côte d'Ivoire)",
  "kos:Kosraean",
  "khq:Koyra Chiini Songhay",
  "ses:Koyraboro Senni Songhai",
  "kpe:Kpelle",
  "kri:Krio",
  "kj:Kuanyama",
  "kum:Kumyk",
  "ku:Kurdish",
  "kru:Kurukh",
  "kut:Kutenai",
  "kxv:Kuvi",
  "kwk:Kwakiutl",
  "nmg:Kwasio",
  "lad:Ladino",
  "lah:Lahnda",
  "lak:Laka (Nigeria)",
  "lkt:Lakota",
  "lam:Lamba",
  "lag:Langi",
  "lo:Lao",
  "ltg:Latgalian",
  "lv:Latvian",
  "lzz:Laz",
  "lez:Lezghian",
  "xpe:Liberia Kpelle",
  "lij:Ligurian",
  "lil:Lillooet",
  "li:Limburgan",
  "ln:Lingala",
  "lt:Lithuanian",
  "liv:Liv",
  "src:Logudorese Sardinian",
  "lmo:Lombard",
  "lou:Louisiana Creole",
  "nds:Low German",
  "sli:Lower Silesian",
  "dsb:Lower Sorbian",
  "loz:Lozi",
  "lu:Luba-Katanga",
  "lua:Luba-Lulua",
  "smj:Lule Sami",
  "lun:Lunda",
  "luo:Luo (Kenya and Tanzania)",
  "lus:Lushai",
  "lb:Luxembourgish",
  "luy:Luyia",
  "mde:Maba (Chad)",
  "rup:Macedo-Romanian",
  "mk:Macedonian",
  "jmc:Machame",
  "mad:Madurese",
  "maf:Mafa",
  "mag:Magahi",
  "vmf:Mainfränkisch",
  "mai:Maithili",
  "mak:Makasar",
  "vmw:Makhuwa",
  "mgh:Makhuwa-Meetto",
  "kde:Makonde",
  "mg:Malagasy",
  "ms:Malay (macrolanguage)",
  "ml:Malayalam",
  "pqm:Malecite-Passamaquoddy",
  "mt:Maltese",
  "mup:Malvi",
  "mnc:Manchu",
  "mdr:Mandar",
  "cmn:Mandarin Chinese",
  "man:Mandingo",
  "mnk:Mandinka",
  "mni:Manipuri",
  "gv:Manx",
  "swb:Maore Comorian",
  "mi:Maori",
  "arn:Mapudungun",
  "mr:Marathi",
  "chm:Mari (Russia)",
  "mh:Marshallese",
  "mwr:Marwari",
  "mas:Masai",
  "mzn:Mazanderani",
  "byv:Medumba",
  "men:Mende (Sierra Leone)",
  "mwv:Mentawai",
  "mer:Meru",
  "mgo:Meta'",
  "mic:Mi'kmaq",
  "crg:Michif",
  "nan:Min Nan Chinese",
  "min:Minangkabau",
  "xmf:Mingrelian",
  "mwl:Mirandese",
  "el:Modern Greek (1453-)",
  "moh:Mohawk",
  "mdf:Moksha",
  "lol:Mongo",
  "mn:Mongolian",
  "cnr:Montenegrin",
  "crm:Moose Cree",
  "mfe:Morisyen",
  "ary:Moroccan Arabic",
  "mos:Mossi",
  "mua:Mundang",
  "umu:Munsee",
  "ttt:Muslim Tat",
  "mye:Myene",
  "nqo:N'Ko",
  "ars:Najdi Arabic",
  "na:Nauru",
  "nv:Navajo",
  "ng:Ndonga",
  "nap:Neapolitan",
  "npi:Nepali (individual language)",
  "ne:Nepali (macrolanguage)",
  "new:Newari",
  "sba:Ngambay",
  "nnh:Ngiemboon",
  "jgo:Ngomba",
  "yrl:Nhengatu",
  "nia:Nias",
  "pcm:Nigerian Pidgin",
  "niu:Niuean",
  "nog:Nogai",
  "azj:North Azerbaijani",
  "nd:North Ndebele",
  "crl:Northern East Cree",
  "frr:Northern Frisian",
  "gno:Northern Gondi",
  "gbo:Northern Grebo",
  "hdn:Northern Haida",
  "kmr:Northern Kurdish",
  "lrc:Northern Luri",
  "pbu:Northern Pashto",
  "hea:Northern Qiandong Miao",
  "se:Northern Sami",
  "ttm:Northern Tutchone",
  "uzn:Northern Uzbek",
  "esk:Northwest Alaska Inupiatun",
  "gya:Northwest Gbaya",
  "ojb:Northwestern Ojibwa",
  "tmk:Northwestern Tamang",
  "no:Norwegian",
  "nb:Norwegian Bokmål",
  "nn:Norwegian Nynorsk",
  "nus:Nuer",
  "nym:Nyamwezi",
  "ny:Nyanja",
  "nyn:Nyankole",
  "nyo:Nyoro",
  "nzi:Nzima",
  "ann:Obolo",
  "oc:Occitan (post 1500)",
  "ory:Odia",
  "oj:Ojibwa",
  "oka:Okanagan",
  "or:Oriya (macrolanguage)",
  "om:Oromo",
  "osa:Osage",
  "os:Ossetian",
  "pau:Palauan",
  "pam:Pampanga",
  "pag:Pangasinan",
  "pa:Panjabi",
  "pap:Papiamento",
  "pat:Papitalai",
  "gug:Paraguayan Guaraní",
  "prp:Parsi",
  "nso:Pedi",
  "pdc:Pennsylvania German",
  "fa:Persian",
  "pfl:Pfaelzisch",
  "pcd:Picard",
  "pms:Piemontese",
  "pis:Pijin",
  "crk:Plains Cree",
  "plt:Plateau Malagasy",
  "pdt:Plautdietsch",
  "pon:Pohnpeian",
  "pl:Polish",
  "pnt:Pontic",
  "pt:Portuguese",
  "prg:Prussian",
  "fuc:Pulaar",
  "ps:Pushto",
  "qu:Quechua",
  "raj:Rajasthani",
  "rap:Rapanui",
  "rar:Rarotongan",
  "rhg:Rohingya",
  "rgn:Romagnol",
  "ro:Romanian",
  "rm:Romansh",
  "rom:Romany",
  "rof:Rombo",
  "rtm:Rotuman",
  "rug:Roviana",
  "rn:Rundi",
  "bxr:Russia Buriat",
  "ru:Russian",
  "rue:Rusyn",
  "rwk:Rwa",
  "lsm:Saamia",
  "spy:Sabaot",
  "ssy:Saho",
  "smd:Sama",
  "saq:Samburu",
  "sm:Samoan",
  "sgs:Samogitian",
  "sad:Sandawe",
  "sg:Sango",
  "sbp:Sangu (Tanzania)",
  "sat:Santali",
  "sc:Sardinian",
  "sas:Sasak",
  "sdc:Sassarese Sardinian",
  "stq:Saterfriesisch",
  "saz:Saurashtra",
  "sco:Scots",
  "gd:Scottish Gaelic",
  "snb:Sebuyau",
  "sly:Selayar",
  "sel:Selkup",
  "seh:Sena",
  "see:Seneca",
  "sr:Serbian",
  "sh:Serbo-Croatian",
  "srr:Serer",
  "sei:Seri",
  "crs:Seselwa Creole French",
  "ojs:Severn Ojibwa",
  "ksb:Shambala",
  "shn:Shan",
  "sn:Shona",
  "ii:Sichuan Yi",
  "scn:Sicilian",
  "sid:Sidamo",
  "bla:Siksika",
  "szl:Silesian",
  "sd:Sindhi",
  "si:Sinhala",
  "sms:Skolt Sami",
  "den:Slave (Athapascan)",
  "sk:Slovak",
  "sl:Slovenian",
  "xog:Soga",
  "so:Somali",
  "snk:Soninke",
  "ajp:South Levantine Arabic",
  "nr:South Ndebele",
  "xsl:South Slavey",
  "alt:Southern Altai",
  "bcc:Southern Balochi",
  "crj:Southern East Cree",
  "hax:Southern Haida",
  "sdh:Southern Kurdish",
  "slh:Southern Puget Sound Salish",
  "sma:Southern Sami",
  "st:Southern Sotho",
  "tce:Southern Tutchone",
  "dik:Southwestern Dinka",
  "es:Spanish",
  "srn:Sranan Tongo",
  "arb:Standard Arabic",
  "ekk:Standard Estonian",
  "lvs:Standard Latvian",
  "zsm:Standard Malay",
  "zgh:Standard Moroccan Tamazight",
  "str:Straits Salish",
  "xsj:Subi",
  "suk:Sukuma",
  "su:Sundanese",
  "sus:Susu",
  "swh:Swahili (individual language)",
  "sw:Swahili (macrolanguage)",
  "csw:Swampy Cree",
  "ss:Swati",
  "sv:Swedish",
  "gsw:Swiss German",
  "syr:Syriac",
  "shi:Tachelhit",
  "tl:Tagalog",
  "tgx:Tagish",
  "ty:Tahitian",
  "tht:Tahltan",
  "blt:Tai Dam",
  "dav:Taita",
  "tg:Tajik",
  "tly:Talysh",
  "tmh:Tamashek",
  "ta:Tamil",
  "rif:Tarifit",
  "trv:Taroko",
  "twq:Tasawaq",
  "tt:Tatar",
  "ttq:Tawallammat Tamajaq",
  "te:Telugu",
  "ter:Tereno",
  "teo:Teso",
  "tet:Tetum",
  "th:Thai",
  "bo:Tibetan",
  "tig:Tigre",
  "ti:Tigrinya",
  "tem:Timne",
  "tiv:Tiv",
  "tli:Tlingit",
  "tpi:Tok Pisin",
  "tkl:Tokelau",
  "tog:Tonga (Nyasa)",
  "to:Tonga (Tonga Islands)",
  "fit:Tornedalen Finnish",
  "trw:Torwali",
  "als:Tosk Albanian",
  "tkr:Tsakhur",
  "tsd:Tsakonian",
  "tsi:Tsimshian",
  "ts:Tsonga",
  "tn:Tswana",
  "tcy:Tulu",
  "tum:Tumbuka",
  "aeb:Tunisian Arabic",
  "tr:Turkish",
  "tk:Turkmen",
  "tru:Turoyo",
  "tvl:Tuvalu",
  "tyv:Tuvinian",
  "tw:Twi",
  "kcg:Tyap",
  "udm:Udmurt",
  "ug:Uighur",
  "uk:Ukrainian",
  "umb:Umbundu",
  "hsb:Upper Sorbian",
  "ur:Urdu",
  "uz:Uzbek",
  "vai:Vai",
  "ve:Venda",
  "vec:Venetian",
  "vep:Veps",
  "vi:Vietnamese",
  "vls:Vlaams",
  "rmy:Vlax Romani",
  "vro:Võro",
  "vot:Votic",
  "vun:Vunjo",
  "wa:Walloon",
  "wae:Walser",
  "war:Waray (Philippines)",
  "wbp:Warlpiri",
  "was:Washo",
  "guc:Wayuu",
  "cy:Welsh",
  "gaz:West Central Oromo",
  "bgn:Western Balochi",
  "fy:Western Frisian",
  "mrj:Western Mari",
  "ojw:Western Ojibwa",
  "pnb:Western Panjabi",
  "wal:Wolaytta",
  "wo:Wolof",
  "cwd:Woods Cree",
  "wuu:Wu Chinese",
  "xh:Xhosa",
  "hsn:Xiang Chinese",
  "sah:Yakut",
  "yav:Yangben",
  "yao:Yao",
  "yap:Yapese",
  "ybb:Yemba",
  "yi:Yiddish",
  "zyb:Yongbei Zhuang",
  "yo:Yoruba",
  "yue:Yue Chinese",
  "zap:Zapotec",
  "dje:Zarma",
  "zza:Zaza",
  "zea:Zeeuws",
  "zen:Zenaga",
  "za:Zhuang",
  "gbz:Zoroastrian Dari",
  "zu:Zulu",
  "zun:Zuni"
]