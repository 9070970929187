<script setup lang="ts">
/* global
  cloudinary,
  CloudinaryUploadWidget,
  AbortEvent,
  DisplayChangedEvent
*/
import { ref, watchEffect, computed } from 'vue'
import { defaultCloudinaryOptions, buttonImageAspectRatio } from '~/scripts/Constants'
import { useScript } from '~/components/utils'
import { type SuccessEventInfo } from './helpers'
import { Session } from '~/state'

const props = withDefaults(defineProps<{
  options?: Partial<CloudinaryUploadWidgetOptions>
  preset?: 'button' | 'product' | 'logo'
}>(), {
  preset: 'button',
  options: () => ({})
})

const user = Session.UserName

const presetSettings = computed<Partial<CloudinaryUploadWidgetOptions>>(() => {
  switch (props.preset) {
    case 'button':
      return {
        folder: `${user}/Button Images/`,
        croppingAspectRatio: buttonImageAspectRatio
      }
    case 'product':
      return {
        folder: `${user}/Product Images/`
      }
    case 'logo':
      return {
        folder: `${user}/Logo Images/`
      }
  }
})

const open = defineModel<boolean>({ default: false })

const emit = defineEmits<{
  /** Widget has been created */
  ready: []
  error: [error: string]
  success: [info: SuccessEventInfo]
  abort: [info: AbortEvent['info']]
  close: []
  'display-changed': [info: DisplayChangedEvent['info']]
}>()

const cloudinaryReady = ref(false)
const widget = ref<CloudinaryUploadWidget>()

watchEffect(() => {
  const ready = cloudinaryReady.value
  const cWidget = widget.value
  const isOpen = open.value

  if (ready && cWidget) {
    if (isOpen) {
      cWidget.open()
    } else {
      cWidget.close()
    }
  }
})

useScript({
  src: 'https://widget.cloudinary.com/v2.0/global/all.js',
  key: 'cloudinary',
  async: true,
  defer: true,
  onload() {
    const options = {
      ...defaultCloudinaryOptions,
      ...presetSettings.value,
      ...props.options as CloudinaryUploadWidgetOptions
    }
    widget.value = cloudinary.createUploadWidget(options, (err, result) => {
      if (err) {
        emit('error', err)
        return
      }
      if (!cloudinaryReady.value) {
        cloudinaryReady.value = true
        emit('ready')
      }
      switch (result.event) {
        case 'success':
          emit('success', result.info as SuccessEventInfo)
          break
        case 'abort':
          emit('abort', result.info)
          break
        case 'close':
          open.value = false
          emit('close')
          break
        case 'display-changed':
          emit('display-changed', result.info)
          break
      }
    })
  }
})

defineSlots<{
  default(props: {
    disabled: boolean
    click: () => void
  }): any
}>()
</script>

<template>
  <slot
    :disabled="!cloudinaryReady"
    :click="() => open = !open"
  ></slot>
</template>
