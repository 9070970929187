import { type Link } from '~/models/links-v1'
import { type Product } from '~/models/products'
import { type DestinationDetails } from '~/models/links-v4'
import { useClientPromise } from '~/utils'

/**
 * Get just the protocol + domain for retailers so that we can efficiently
 * cache requests.
 */
export const getRetailerUrl = (url: string) => {
  const parsed = new URL(url)
  return `${parsed.protocol}//${parsed.hostname}`
}

/**
 * Gets the v3.5 URL details for a product, given a product
 * and optionally a resolved link.
 *
 * @param product - Product suggestion
 * @param link - v1 Link details
 * @param includeLogo - Whether to fetch the retailer logo if not present
 */
export const getUrlDetails =
  async (product: Product, link?: Link, includeLogo?: boolean): Promise<DestinationDetails> => {
    if (!link) {
    /** We don't have product link details, so let's get it */
      const [err, result] = await useClientPromise('/api/v1/shorturl/preview-product-details', {
        query: {
          url: product.productUrl
        }
      })
      if (err) {
        throw err
      }
      link = result
    }

    /** Retailer logo url */
    let url = product.retailerImages?.Logo ?? product.retailerImages?.Banner ?? ''

    if (!url && includeLogo) {
      const [, result] = await useClientPromise('/api/v1/default-button-image', {
        query: {
          Url: getRetailerUrl(url)
        }
      })
      if (result) {
        url = result.image
      }
    }

    return {
      url: product.productUrl,
      title: link.productDisplayName1,
      subtitle: link.productDisplayName2,
      images: {
        product: {
          url: product.productImages?.Large ?? product.productImages?.Medium ?? product.productImages?.Small ?? ''
        }
      },
      retailer: {
        name: product.retailer,
        images: {
          logo: {
            url
          }
        }
      }

    } satisfies DestinationDetails
  }
