import {
  observable,
  observableArray
} from '~/components/utils'
import { ReportingDataTab } from './report-data-tab'

const displayTabNameOptions = ['table', 'download']
// ["table", "pie", "map", "download"];
const attributeTabNameOptions =
    ['Links', 'Groups', 'Referrers', 'Countries',
      'Stores', 'Destinations', 'Tags', 'Devices',
      'OS', 'Browsers', 'Product Types', 'Language']

export class ReportBuilderTable {
  selectedDisplayTabName = observable(displayTabNameOptions[0])
  selectedAttributeTabName = observable(attributeTabNameOptions[0])
  // array of tab data objects
  attributeDataTabs = observableArray<ReportingDataTab>([])

  constructor() {
    const tempAttributeDataTabs: ReportingDataTab[] = []
    attributeTabNameOptions.forEach(function(attributeTabName) {
      tempAttributeDataTabs.push(new ReportingDataTab(attributeTabName, [], [], [], []))
    })
    // set this after array is built so we don't fire a ton of stuff for each tab added
    this.attributeDataTabs(tempAttributeDataTabs)
  }

  // set data for the tab with the same name
  setAttributeDataForTab(
    attributeTabName: string, clicks: any[], comm: any, epc: any, conv: any
  ) {
    // find the tab with the attribute name and set the data
    this.attributeDataTabs().forEach(function(attributeTabObject) {
      if (attributeTabObject.name() === attributeTabName) {
        attributeTabObject.clearAllData()
        attributeTabObject.clicks(clicks)
        attributeTabObject.comms(comm)
        attributeTabObject.epc(epc)
        attributeTabObject.conv(conv)
      }
    })
  }

  // call for each tab to be wiped
  clearAllData() {
    this.attributeDataTabs().forEach(function(attributeTabObject) {
      attributeTabObject.clearAllData()
    })
  }

  /** ??? - no-op for now */
  refreshAllTabs(...args: any[]) {}
  // refreshAllTabs(advertiserId, startDate, endDate, doPreviousPeriod) {
  //   const returnBy = calculateReturnByResolutionString(startDate, endDate)
  //   // foreach tab, call refresh
  //   /* ko.utils.arrayForEach(self.attributeDataTabs(), function (attributeTabObject) {
  //       attributeTabObject.clearAllData();
  //       attributeTabObject.pullUpdate(
  //       advertiserId, startDate, endDate,
  //       doPreviousPeriod, returnBy, "user", "", 0);
  //       }); */
  // }
}
