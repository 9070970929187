import {
  observable,
  computed
} from '~/components/utils'
import $ from 'jquery'

/**
 * This looks like a duplicate of report-data-row.js with some changes
 *
 * @todo - Document how this is different from report-data-row and
 * when / why it's used.
 */
export class BasicReportingRow {
  constructor(name, values, countryName, urlForMoreInfo, productDetail) {
    const self = this

    self.rowNamesThatAreGroupings = ['None', 'Other', 'Unknown']

    self.name = observable(name)
    self.clicksUnformatted = observable(values.clicks)
    self.earnings = observable(values.commissionForConsolidated)

    self.clicksMinusSharedAndBot = observable(values.clicksMinusSharedAndBot)
    self.conv = observable(values.convForConsolidated)
    self.activeShare = observable(values.activeShare)
    self.passiveShare = observable(values.passiveShare)
    self.botClicks = observable(values.spider)
    self.affiliatedClicks = observable(values.clientAffiliated + values.activeShare + values.passiveShare)
    self.clicksMinusShared = observable(values.clicks - (values.activeShare + values.passiveShare))
    self.clicksMinusBots = observable(values.clicks - values.junkTraffic)

    self.advancedTargeting = observable(values.advancedTargeting)
    self.trackingPixel = observable(values.trackingPixel)
    self.translated = observable(values.translated)

    self.isNameAGrouping = observable(false)
    self.nameExplainationTitle = observable('What is "' + name + '"?')
    self.nameExplaination = observable('')

    self.updateVarsBasedOnName = function(aName) {
      $(self.rowNamesThatAreGroupings).each(function(index, aString) {
        if (aString === aName) {
          self.isNameAGrouping(true)
        }
      })
      self.nameExplaination(chooseReportDataRowNameExplaination(aName))
    }
    self.updateVarsBasedOnName(name)

    self.clicks = computed(function() {
      return vm.basicReportingInput.showBots()
        ? self.clicksMinusBots()
        : self.clicksUnformatted()
    })

    self.clicksForBotStatus = function(showBots) {
      return showBots
        ? self.clicksMinusBots()
        : self.clicksUnformatted()
    }

    self.affiliatedClicks = function(hideBot) {
      return hideBot ? self.clicksMinusSharedAndBot() : self.clicksMinusShared()
    }

    const tempConvPercNoBot = self.clicksMinusSharedAndBot() > 0
      ? (self.conv() / self.clicksMinusSharedAndBot()) * 100
      : 0

    self.convPercentNoBot = observable(tempConvPercNoBot)

    const tempConvPercBots = self.clicksMinusShared() > 0
      ? (self.conv() / self.clicksMinusShared()) * 100
      : 0

    self.convPercentBots = observable(tempConvPercBots)

    self.convPercent = computed(function() {
      return vm.basicReportingInput.showBots()
        ? self.convPercentNoBot()
        : self.convPercentBots()
    })

    self.convPercentForBotStatus = function(showBots) {
      return showBots
        ? self.convPercentNoBot()
        : self.convPercentBots()
    }

    self.epcWithBots = observable(values.epcWithBots)

    self.epcNoBots = observable(values.epcNoBots)

    self.epcForBotStatus = function(showBots) {
      return showBots ? self.epcNoBots() : self.epcWithBots()
    }

    self.epc = computed(function() {
      return self.epcForBotStatus(vm.basicReportingInput.showBots())
    })

    // TODO need this?  can't think right now....
    self.affiliatedClicksForBotStatus = function(hideBot) {
      return hideBot ? self.clicksMinusSharedAndBot() : self.clicksMinusShared()
    }

    self.urlForMoreInfo = observable(urlForMoreInfo)

    if (!values.previousPeriod) {
      values.previousPeriod = {
        clicks: 0,
        spider: 0,
        epcWithBots: 0,
        epcNoBots: 0,
        commissionForConsolidated: 0
      }
    }
    self.previousPeriod = {
      clicksUnformatted: observable(values.previousPeriod.clicks),
      clicksMinusBots: observable(values.previousPeriod.clicks - values.previousPeriod.spider),
      epcWithBots: observable(values.previousPeriod.epcWithBots),
      epcNoBots: observable(values.previousPeriod.epcNoBots),
      earnings: observable(values.previousPeriod.commissionForConsolidated)
    }

    self.clicksPP = computed(function() {
      return vm.basicReportingInput.showBots()
        ? self.previousPeriod.clicksMinusBots()
        : self.previousPeriod.clicksUnformatted()
    })

    // TODO
    // self.commTrendUp = computed(function () {
    //    return self.earnings() >= self.previousPeriod.earnings();
    // });
    // self.commTrendDown = computed(function () {
    //    return !self.clickTrendUp();
    // });
    self.clickTrendUp = computed(function() {
      return self.clicks() >= self.clicksPP()
    })
    self.clickTrendDown = computed(function() {
      return !self.clickTrendUp()
    })

    self.epcForBotStatusPP = function(showBots) {
      return showBots
        ? self.previousPeriod.epcNoBots()
        : self.previousPeriod.epcWithBots()
    }

    self.productDetail = productDetail

    self.countryName = observable(countryName)

    self.cssForEarnings = observable('')
    self.cssForEpc = observable('')
    self.cssForConv = observable('')

    self.showEarnings = observable(false)
    self.showEpc = observable(false)
    self.showConv = observable(false)

    self.updateCss = function() {
      let cssEarnings = self.cssForEarnings()
      let cssEpc = self.cssForEpc()
      let cssConv = self.cssForConv()

      if (vm.basicReportingInput.tagOverrideIsEnabled() === 0) {
        cssEarnings += ' nodata'
        cssEpc += ' nodata'
      } else {
        if (self.epc() < 0) { cssEpc += ' nodata' }
        if (self.earnings() < 0) { cssEarnings += ' nodata' }
      }

      if (vm.basicReportingInput.tagOverrideIsEnabled() === 0) {
        cssConv += ' nodata'
      } else {
        if (self.conv() < 0) { cssConv += ' nodata' }
      }

      if (vm.basicReportingInput.showPendingForConversion() === 1) {
        cssConv += ' pending'
      }

      self.cssForEarnings(cssEarnings)
      self.cssForEpc(cssEpc)
      self.cssForConv(cssConv)
    }

    self.updateCss()

    self.updateVisibility = computed(function() {
      if (vm.basicReportingInput.tagOverrideIsEnabled() === 1) {
        if (self.epc() >= 0) {
          self.showEpc(true)
        }
        if (self.earnings() >= 0) {
          self.showEarnings(true)
        }
        if (self.conv() >= 0) {
          self.showConv(true)
        }
      }
    })

    self.updateVisibility()
  }
}

const NONE_DATA_EXPLANATION = 'This is a collection of clicks without source data. '
const UNKNOWN_DATA_EXPLANATION = 'This is a collection of clicks with source data that is not recognized.'
const OTHER_DATA_EXPLANATION = "This is a collection of all the sources that didn't make the top 10, or just didn't represent enough data to warrant being listed."

function chooseReportDataRowNameExplaination(name) {
  if (name === 'None') {
    return NONE_DATA_EXPLANATION
  } else if (name === 'Other') {
    return OTHER_DATA_EXPLANATION
  } else if (name === 'Unknown') {
    return UNKNOWN_DATA_EXPLANATION
  }
  return ''
}
